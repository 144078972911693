
import Modal from '../../../components/Modal';

const PaymentMessageModal = ({ setPaymentMessageModal, paymentMessageModal, message = "Please contact your agent." }) => {

	return (
		<Modal show={paymentMessageModal}>
			<div className='modal__container'>
				<div className='modal_heading__container'>
					<h1 className='modal_heading__text'></h1>
					<button className='modal__heading--button' onClick={() => setPaymentMessageModal(false)}>
						<svg className='modal_heading__icon'>
							<use xlinkHref='/sprite.svg#icon-close' />
						</svg>
					</button>
				</div>
				<div className='modal__form'>
					<label className='alert-message'>
						<h1 style={{ textAlign: 'center' }}>{message}</h1>
					</label>
				</div>
				<button className='modal__button' onClick={() => setPaymentMessageModal(false)}>Close</button>
			</div>
		</Modal>
	);
};

export default PaymentMessageModal;
