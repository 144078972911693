import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import LinkOption from "./Forms/LinkOption";
import PdfOption from "./Forms/PdfOption";

import styles from "./PointAddForm.module.css";
// import PdfOption from "./Forms/PdfOption";
import ImageOption from "./Forms/ImageOption";

const PointAddForm = ({
  step,
  currentSection,
  setCurrentSection,
  step1Selection,
  onPointSave
}) => {
  const [title, setTitle] = useState({ value: '', error: '' });
  const [addSection, setAddSection] = useState(false);
  const [formStatus, setFormStatus] = useState('');

  const onSectionChange = useCallback((e) => {
    e.preventDefault();
    setAddSection(true);
    setFormStatus(e.target.value)
  }, [])


  const getForm = useCallback(() => {
    switch (formStatus) {
      case 'pdf':
        return (
          <PdfOption sections={step1Selection} onSave={onPointSave} />
        )
      case 'image':
        return (
          <ImageOption sections={step1Selection} onSave={onPointSave} />
        )

      default:
        return (
          <LinkOption sections={step1Selection} onSave={onPointSave} />
        )
    }
  }, [formStatus, step])


  return (
    <div className={styles.mainContainer}>
      <div className="leftConatiner" style={{ width: '100%' }}>
        <div className="select_state">
          <label style={{ fontSize: "2rem" }} className="label_heading_quote">
            Select Option type: {currentSection?.title}  <span className="red_star">&#42;</span>
          </label>
          <br />
          <select
            id="selectstate"
            name="selectstate"
            className="landing_selectbox select__state__textbox"
            style={{ marginBottom: '3rem', width: '34rem!important' }}
            onChange={onSectionChange}
            value={formStatus}
          >
            <option
              value={'link'}
              id="">
              Link
            </option>
            <option
              value={'pdf'}
              id="">
              PDF
            </option>
            <option
              value={'image'}
              id="">
              Image
            </option>
          </select>{" "}
          <br />
          {/* <p className="form_input__error">{'selectAgentError'}</p> */}
        </div>
        {getForm()}
      </div>
    </div>
  );
};

export default PointAddForm;
