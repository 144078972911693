import React, { useState, useCallback, useMemo } from "react";
import styles from './PdfUploader.module.css';
import { useDropzone } from "react-dropzone";
import { propTypes } from "react-recaptcha";

const PdfUploader = ({ uploadFilePreview, setUploadFilePreview, pdfFormat, fileSize, pdfFileError, setPdfFileError, uploadFile, setUploadFile }) => {
    // const [uploadFilePreview, setUploadFilePreview] = useState("");
    // const [uploadFile, setUploadFile] = useState(null);
    const [formatError, setFormatError] = useState(null);

    const encodeFileAsURL = useCallback((files) => {
        var file = files[0];
        setUploadFile(file);
        var reader = new FileReader();
        reader.onloadend = function () {
            if (reader.result) {
                setUploadFilePreview(reader.result);
            }
        };
        reader.readAsDataURL(file);
    }, [setUploadFilePreview, setUploadFile]);

    const onDrop = useCallback((acceptedFiles) => {
        encodeFileAsURL(acceptedFiles);
    }, [encodeFileAsURL]);

    const byteConverter = useCallback((number) => Number((number / 1024 ** 2).toFixed(2)), []);

    const fileValidator = useCallback((file) => {
        if (file.type !== pdfFormat) {
            setPdfFileError({ error: 'Please select a valid pdf file' });
            setUploadFile(null);
            return;
        }
        if (byteConverter(file.size) > fileSize) {
            setPdfFileError({ error: 'Please choose a pdf file smaller than 2mb' });
            setUploadFile(null);
            return;
        }
        else setPdfFileError(null);
    }, [byteConverter, fileSize, pdfFormat, setPdfFileError, setUploadFile]);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: pdfFormat,
        onDrop,
        maxFiles: 1,
        validator: fileValidator
    });

    return (
        <div>
            <div
                className="upload_border"
                {...getRootProps()}
                style={{ marginTop: "-1rem", width: "304px", height: '62px'}}
            >
                <input {...getInputProps()}/>
                {uploadFilePreview === "" ? (
                    <>
                        <svg className="modal_upload__icon-dark">
                            <use xlinkHref="/sprite.svg#icon-cloud" />
                        </svg>
                        <label>Please choose a file to upload</label>
                    </>
                ) : (
                    <>{!acceptedFiles[0] && <label>Please choose a file to upload</label>}
                    {acceptedFiles.map((file) => (
                        <li className={styles.fileInfo} style={{fontSize:'1.2rem'}} key={file.path}>
                            {file.path} <br /> {byteConverter(file.size)} mb
                        </li>
                    ))}
                        </>
                )}
            </div>
            <p className={styles.supportedFormat}>Supported Files: pdf</p>
            {pdfFileError && pdfFileError.error && (
                <p className="form_input__error">{pdfFileError.error}</p>
            )}
        </div>
    )
}
export default PdfUploader;

PdfUploader.defaultProps = {
    pdfFormat: 'application/pdf',
    fileSize: 2
}
