import React from 'react'
import Header from '../Headers/MainHeader'
// import MwfbiHeader from '../Headers/MwfbiHeader';
import GolfHeader from '../Headers/GolfHeader'
import SalesHeader from '../Headers/SalesHeader'
import StoreHeader from '../Headers/StoreHeader'

import Footer from '../Footers/Footer'
import MwfbiFooter from '../Footers/MwfbiFooter'

import './Content.css'
import NewMenu from '../Headers/NewMenu'
import NewMenu360 from '../Headers/NewMenu360'
import MeetingHeader from '../Headers/MeetingHeader'

const Content = ({ headerFooterMenu, children }) => {
  return (
    <main className='container'>
      {/* <div className='top__space' /> */}
      {headerFooterMenu === 'mountain-west' ? (
        <NewMenu />
      ) : headerFooterMenu === 'golf' ? (
        <GolfHeader />
      ) : headerFooterMenu === 'sales' ? (
        <SalesHeader />
      ) : headerFooterMenu === 'store' ? (
        <StoreHeader />
      ) : headerFooterMenu === '360-insurance' ? (
        <NewMenu360 />
      ) : headerFooterMenu === 'meeting' ? (
        <MeetingHeader />
      ) : (
        <Header />
      )}
      <div className='content'>{children}</div>
      {headerFooterMenu === 'mountain-west' ? <MwfbiFooter /> : <Footer />}
    </main>
  )
}

export default Content
