import React, { useCallback, useEffect, useState } from 'react'
import Content from '../../../components/Content/Content'
import Styles from './blogSettings.module.css'
import EditFieldTypeModal from './EditFieldTypeModal';
import TagListModal from './TagListModal';
import { createTopic, editBlogById, getAllBlogs, getAllMainTopicsAPI, getAllTopics, getBlogSetting, saveBlogSetting, searchBlogList, statusChangeBlogById, updateTopicById } from '../../../redux/actions/blogListAction';
import { useDispatch, useSelector } from 'react-redux';
import AddNewBlogModal from './AddNewBlogModal';
import { Link } from 'react-router-dom';
import Pagination from '../../../components/Pagination';
import { getTimeZoneLocalDate } from '../../../constants';

const pageName = "BlogSetting";

const BlogSettings = () => {

  const topicList = useSelector((state) => state.blogsTopicsList);
  const blogListData = useSelector((state) => state.blogsList);

  const dispatch = useDispatch();
  const [blogTitle, setBlogTitle] = useState("The Learning Center");
  const [blogSettingData, setBlogSettingData] = useState();
  const [blogSubTitle, setBlogSubTitle] = useState("The more interesting side of insurance information!");
  const [showFieldTypeModal, setShowFieldTypeModal] = useState(false);
  const [showTopicModal, setShowTopicModal] = useState(false);
  const [showAddBlogModal, setShowAddBlogModal] = useState(false);
  const [editFormData, setEditFormData] = useState([]);
  const [blogList, setBlogList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalBlogs, setTotalBlogs] = useState(0);
  const [rowNumberData, setRowNumberData] = useState(0);
  const [rowNumberLoading, setRowNumbeLoading] = useState(true);

  const [topicsData, setTopicsData] = useState([])
  useEffect(() => {
    if (blogListData && blogListData.blogsList && blogListData.blogsList.data) {
      setBlogList(blogListData.blogsList.data);
      setTotalPages(Math.ceil(blogListData.blogsList.count / pageSize));
      setTotalBlogs(blogListData.blogsList.count);
    }
  }, [blogListData, pageSize]);

  const getAllTopicsList = () => dispatch(getAllTopics());
  const getAllMTopics = () => {
    getAllMainTopicsAPI('active')
      .then(res => {
        const data = res.data;
        if (data.status) {
          setTopicsData(data.data)
        }
      })
      .catch(error => { })
  }
  useEffect(() => {
    getBlogSetting(pageName)
      .then(result => {
        if (result.data.status) {
          setBlogTitle(result.data.result.title.value);
          setBlogSubTitle(result.data.result.subtitle.value);
          setBlogSettingData(result.data.result);
        }
      });
    getAllMTopics()
  }, []);

  useEffect(() => {
    getAllTopicsList();
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllBlogs(currentPage, pageSize));
  }, [dispatch, currentPage, pageSize]);

  const handlePageChange = ({ selected }) => {
    setRowNumbeLoading(true)
    setCurrentPage(selected + 1);
  };

  const creatTag = (data) => {
    createTopic(data).then((result) => {
      if (result.data.status) {
        alert("Content Saved");
        dispatch(getAllTopics());
      }
    });
  }

  const updateTag = (data) => {
    updateTopicById(data).then((result) => {
      if (result.data.status) {
        alert("Content Saved");
        dispatch(getAllTopics());
      }
    });
  }

  const onBlogSettingSave = (e) => {
    e.preventDefault();
    saveBlogSetting({ pageName, title: blogTitle, subtitle: blogSubTitle }).then((result) => {
      if (result.data.status) {
        alert("Content Saved");
      }
    });
  }

  const blogStatusChange = (e, item) => {
    statusChangeBlogById({
      status: e.target.checked ? 'Active' : 'Inactive',
    }, item.blogId).then((res) => {
      if (res.data.status) {
        dispatch(getAllBlogs(currentPage, pageSize));
      }
    })
  }

  const convertToStandardDate = (isoString) => {
    const date = new Date(isoString);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
    // return date.toLocaleDateString('en-US', options);
    // console.log(date.getTimezoneOffset())
    return date.toLocaleDateString('en-US');
  };

  const publishBlog = (e, item) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to publish the blog ?")) {
      statusChangeBlogById({
        status: 'Published',
      }, item.blogId).then((res) => {
        if (res.data.status) {
          alert("Content Saved");
          dispatch(getAllBlogs(currentPage, pageSize));
        }
      })
    }
  }

  const searchBlog = useCallback(async (query) => {
    if (query.length > 0) {
      const data = await dispatch(searchBlogList(query));
      setBlogList(data?.data?.data);
    } else {
      dispatch(getAllBlogs(currentPage, pageSize));
    }
  }, [dispatch, currentPage, pageSize]);

  useEffect(() => {
    if (blogList && blogList.length > 0) {
      const lastRowNumber = (currentPage - 1) * pageSize + blogList.length;
      setRowNumberData(lastRowNumber);
      setTimeout(() => setRowNumbeLoading(false), 1000)
    }
  }, [blogList, currentPage, pageSize]);

  const firstRowNumber = (currentPage - 1) * pageSize + 1;

  return (
    <Content headerFooterMenu='mountain-west'>
      <div className={Styles.container}>
        <div className={Styles.mainForm}>
          <span className={Styles.formTitle}>Blog Settings</span>
          <div className={Styles.subform}>
            <div className={Styles.inputContainer}>
              <div className={Styles.inputBox}>
                <span className={Styles.inputTitle}>Title</span>
                <input
                  type="text"
                  value={blogTitle}
                  onChange={(e) => setBlogTitle(e.target.value)}
                  placeholder='Enter the title'
                  className={Styles.input} />
              </div>
              <div className={Styles.inputBox}>
                <span className={Styles.inputTitle}>Sub Title</span>
                <input type="text"
                  value={blogSubTitle}
                  onChange={(e) => setBlogSubTitle(e.target.value)}
                  placeholder='Enter the sub title'
                  className={Styles.input} />
              </div>
            </div>
            <div className={Styles.buttonContainer}>
              <button onClick={onBlogSettingSave}>Save</button>
              <button onClick={(e) => {
                e.preventDefault();
                if (blogSettingData) {
                  setBlogTitle(blogSettingData.title.value);
                  setBlogSubTitle(blogSettingData.subtitle.value);
                }
              }}>Cancel</button>
            </div>
          </div>
        </div>
        <div className={Styles.searchContainer}>
          <span className={Styles.formTitle}>Blog List</span>
          <div className={Styles.searchSubform}>
            <div className={Styles.inputContainer}>
              <div className={Styles.searchBox}>
                <svg className={Styles.searchIcon}>
                  <use xlinkHref='/sprite.svg#icon-search-blog-button' />
                </svg>
                <input onChange={(e) => searchBlog(e.target.value)} type="text" placeholder='Search' className={Styles.input} style={{ borderBottom: 'none', marginLeft: '-1.4rem' }} />
              </div>
            </div>
            <div className={Styles.buttonContainer}>
              <button onClick={() => setShowFieldTypeModal(true)}>Manage Topic & Tags</button>
              <Link to={`/mwfbi/blog-editor`}><button>Add new blog</button></Link>
            </div>
          </div>
        </div>
        <div>
          <div className="modal_inner_content" style={{ marginTop: '4rem' }}>
            <div className="modal_form_container" style={{ height: 'auto' }}>
              <table
                className="modal__form-table"
                cellSpacing="0"
                cellPadding="0"
              >
                <thead className="modal__form-sticky" style={{ zIndex: 1 }}>
                  <tr className="modal__form-tr">
                    <th style={{ fontWeight: '600', padding: '2.2rem' }}>No.</th>
                    <th style={{ fontWeight: '600' }}>Title</th>
                    <th style={{ fontWeight: '600' }}>Tags</th>
                    <th style={{ fontWeight: '600' }}>Status</th>
                    <th style={{ fontWeight: '600' }}>Created Date</th>
                    <th style={{ fontWeight: '600' }}>Published Date</th>
                    <th style={{ fontWeight: '600' }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {blogList?.length > 0 ? (
                    blogList?.map((item, i) => {
                      const rowNumber = (currentPage - 1) * pageSize + i + 1;
                      return (
                        <tr
                          style={{ height: "60px" }}
                          className="modal__form-tr2"
                          key={i}
                        >
                          <td>{rowNumber}</td>
                          <td style={{ width: "220px" }}>{item.title ? item.title : '-'}</td>
                          <td style={{ width: "220px" }}>{item.tagList ? item.tagList : '-'}</td>
                          <td style={{ width: "220px" }}>{item.status}</td>
                          <td style={{ width: "220px" }}>{item.createdDate ? getTimeZoneLocalDate(item.createdDate).format("MMM DD YYYY") : '-'}</td>
                          <td style={{ width: "180px" }}>{item.publishedDate ? getTimeZoneLocalDate(item.publishedDate).format("MMM DD YYYY") : '-'}</td>
                          <td
                            className="modal__form-buttons"
                            style={{
                              justifyContent: "center",
                              paddingTop: "1.1rem",
                            }}
                          >
                            <label className={Styles.switch}>
                              <input
                                checked={item.status === 'Active' || item.status === 'Published'}
                                onChange={(e) => blogStatusChange(e, item)}
                                type='checkbox'
                                className={Styles.checkbox}
                                name='active'
                              />
                              <span className={`${Styles.slider} ${Styles.round}`} />
                            </label>
                            <div className={Styles.tooltip}>
                              <button
                                className={Styles.publish_icon_button}
                                onClick={(e) => publishBlog(e, item)}
                              >
                                <svg className="modal_heading__icon-dark"
                                  style={{
                                    height: '3.3rem',
                                    width: '3.3rem',
                                    paddingTop: '0.6rem'
                                  }}>
                                  <use xlinkHref="/sprite.svg#icon-publish" />
                                </svg>
                              </button>
                              <span className={Styles.tooltiptext}>Publish Blog</span>
                            </div>
                            <div className={Styles.tooltip}>
                              <Link to={`/mwfbi/blog-editor/${item.blogId}`}>
                                <button className={Styles.publish_icon_button}>
                                  <svg className="modal_heading__icon-dark" style={{
                                    height: '3.3rem',
                                    width: '3.3rem',
                                    paddingTop: '0.6rem'
                                  }}>
                                    <use xlinkHref="/sprite.svg#icon-edit-blog" />
                                  </svg>
                                </button>
                                <span className={Styles.tooltiptext1}>Edit Blog</span>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <div>
                      <span className='no_data_message'>No data found</span>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ display: 'flex', marginTop: '1rem', gap: 5, alignItems: 'center', justifyContent: 'end' }}>
            {blogList && rowNumberData && totalBlogs && !rowNumberLoading ? (
              <p style={{ fontSize: '1.3rem' }}>
                {`${firstRowNumber}-${rowNumberData} of ${totalBlogs} blogs`}
              </p>)
              : (
                <p style={{ fontSize: '1.3rem' }}>
                  Loading...
                </p>)
            }
            <Pagination
              onPageChange={handlePageChange}
              rowsPerPage={pageSize}
              totalPageSize={totalPages}
              pageNumber={currentPage}
            />
          </div>
        </div>
        <EditFieldTypeModal
          topicList={topicList}
          handleYes1={updateTag}
          handleYes={creatTag}
          getAllBlogs={getAllBlogs}
          showModal={showFieldTypeModal}
          editFormData={editFormData}
          topicsData={topicsData}
          addNewTopic={(e) => {
            e.preventDefault()
            setShowFieldTypeModal(false)
            setShowTopicModal(true)
          }}
          setEditFormData={setEditFormData}
          setShowModal={setShowFieldTypeModal}
        />
        <TagListModal
          topicList={topicList}
          handleYes1={updateTag}
          handleYes={creatTag}
          topicsData={topicsData}
          getAllBlogs={getAllBlogs}
          closeTopic={() => {
            setShowTopicModal(false)
            setShowFieldTypeModal(true)
            getAllMTopics()
          }}
          showModal={showTopicModal}
          editFormData={editFormData}
          setEditFormData={setEditFormData}
          setShowModal={setShowTopicModal}
        />
        <AddNewBlogModal
          showModal={showAddBlogModal}
          setShowModal={setShowAddBlogModal}
        />
      </div>
    </Content>
  );
}

export default BlogSettings;
