import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import { imageUpload } from '../../../../redux/actions/contentAction'

const EditPageDetail = ({ setShowModal, showModal, handleYes, section3, setSection3, loading }) => {

    const [uploadImagePreview, setUploadImagePreview] = useState(null)
    const [uploadImage, setUploadImage] = useState(null)
    const [editItem, setEditItem] = useState(null)
    const [editItemIndex, setEditItemIndex] = useState(null)
    const [title, setTitle] = useState(null)
    const [error, setError] = useState(null)
    const [link, setLink] = useState(null)

    const onDrop = useCallback((acceptedFiles) => {
        encodeImageFileAsURL(acceptedFiles)
    }, [])
    const encodeImageFileAsURL = (files) => {
        var file = files[0]
        setUploadImage(file)
        var reader = new FileReader()
        reader.onloadend = function () {
            if (reader.result) {
                setUploadImagePreview(reader.result)
            }
        }
        reader.readAsDataURL(file)
    }
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg,image/png',
        onDrop,
    })

    useEffect(() => {
        if (!loading)
            clear()
    }, [loading]);

    const submit = async () => {
        let temp = { ...section3 };
        if (section3.title.value === '') {
            temp.title.error = 'Please enter title!'
            setSection3(temp)
            return
        } else {
            temp.title.error = ''
            setSection3(temp)
        }
        let path = uploadImagePreview;
        if (uploadImage) {
            const res = await imageUpload(uploadImage)
            if (res.data.status) {
                path = res.data.path;
            }
        }
        if (editItem) {
            temp.images.value[editItemIndex].link = link;
            temp.images.value[editItemIndex].image = path;
        } else {
            temp.images.value.push({ link, image: path })
        }
        handleYes(temp)
    }
    const onEdit = (item, index) => {
        setEditItem(item);
        setTitle(section3.title.value)
        setLink(item.link)
        setUploadImagePreview(item.image)
        setEditItemIndex(index)
    }
    const onDelete = (index) => {
        if (window.confirm("Are you sure to delete?")) {
            let temp = { ...section3 };
            temp.images.value.splice(index, 1);
            setSection3(temp)
            handleYes(temp)
        }
    }
    const clear = () => {
        setEditItem(null)
        setTitle(null)
        setLink(null)
        setUploadImagePreview(null)
        setEditItemIndex(null)
        setError(null)
    }
    return (
        <Modal show={showModal}>
            <form >
                <div className='modal__container-dark'>
                    <div className='modal_heading__container-dark'>
                        <h1 className='modal_heading__text-dark'>Edit Info</h1>
                        <button
                        type='button'
                            className='modal__heading--button-dark'
                            onClick={() => setShowModal(false)}>
                            <svg className='modal_heading__icon-dark'>
                                <use xlinkHref='/sprite.svg#icon-cross' />
                            </svg>
                        </button>
                    </div>
                    <div className='modal__form-dark'>
                        <div className='modal_inner_content'>
                            <div className='modal_inner_content--left'>
                                <div className='pre_heading'>
                                    <FormInputComponent
                                        heading='Common Title:'
                                        style={{ width: '90%' }}
                                        required={true}
                                        value={section3.title.value}
                                        onChange={(e) => {
                                            let temp = { ...section3 }
                                            temp.title.value = e.target.value
                                            setSection3(temp)
                                        }}
                                        errorMessage={section3.title.error}
                                    />
                                </div>
                                <div className='pre_heading'>
                                    <FormInputComponent
                                        heading='Link:'
                                        style={{ width: '90%' }}
                                        required={true}
                                        value={link}
                                        onChange={(e) => {
                                            setLink(e.target.value)
                                        }}
                                        errorMessage={error}
                                    />
                                </div>
                            </div>
                            <div className='modal_inner_content--right'>
                                <label>Please choose image to upload</label>
                                <div className='upload_border' {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <label>Click or Drag a image to this area to upload</label>
                                    <svg className='modal_upload__icon-dark'>
                                        <use xlinkHref='/sprite.svg#icon-cloud' />
                                    </svg>
                                </div>
                                {uploadImagePreview && <div className='upload_preview_modal'>
                                    <img width={200} height={100} src={uploadImagePreview} alt="img"></img>
                                </div>}
                            </div>
                        </div>
                        <div  style={{marginTop:'6rem'}} className='modal_dark_buttons'>
                            <button
                                type='button'
                                className='modal__button-reset-dark'
                                onClick={() => setShowModal(false)}>
                                Cancel
                            </button>
                            {loading ? (
                                <button className='modal__button-dark'>Please wait...</button>
                            ) : (
                                <button type='button' className='modal__button-dark' onClick={submit}>
                                    Submit
                                </button>
                            )}
                        </div>
                        <div className='modal_inner_content'>
                            <div className='modal_form_container'>
                                <table
                                    className='modal__form-table'
                                    cellSpacing='0'
                                    cellPadding='0'>
                                    <thead className='modal__form-sticky'>
                                        <tr className='modal__form-tr'>
                                            <th>No.</th>
                                            <th>Link</th>
                                            <th>Image</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {section3 &&
                                            section3.images &&
                                            section3.images.value.map((item, i) => {
                                                return (
                                                    <tr className='modal__form-tr2' key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{item.link}</td>
                                                        <td><img height={10} width={10} src={item.image}></img></td>
                                                        <td className='modal__form-buttons'>
                                                            <button
                                                                type='button'
                                                                className='modal__button-edit'
                                                                onClick={(e) => onEdit(item, i)}>
                                                                edit
                                                            </button>
                                                            <button
                                                                type='button'
                                                                className='modal__button-delete'
                                                                onClick={(item) => onDelete(item, i)}>
                                                                delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </Modal>
    )
}

export default EditPageDetail

