export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';

export const AGENT_LIST_REQUEST = 'AGENT_LIST_REQUEST';
export const AGENT_LIST_SUCCESS = 'AGENT_LIST_SUCCESS';
export const AGENT_LIST_FAIL = 'AGENT_LIST_FAIL';

export const DISCOUNT_LIST_REQUEST = 'DISCOUNT_LIST_REQUEST';
export const DISCOUNT_LIST_SUCCESS = 'DISCOUNT_LIST_SUCCESS';
export const DISCOUNT_LIST_FAIL = 'DISCOUNT_LIST_FAIL';

export const USER_AZURE_REQUEST = 'USER_AZURE_REQUEST';
export const USER_AZURE_SUCCESS = 'USER_AZURE_SUCCESS';
export const USER_AZURE_FAIL = 'USER_AZURE_FAIL';
export const USER_AZURE_LOGOUT = 'USER_AZURE_LOGOUT';

