import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import ReactPaginate from 'react-paginate'
import {
  deleteContentItem,
  imageUpload,
  addContentItem,
} from '../../../../redux/actions/contentAction'

const itemsPerPage = 10

const EditInsurancePoints = ({
  setShowModal,
  showModal,
  handleYes,
  section2,
  setSection2,
  heading,
}) => {
  const [accountNumber, setAccountNumber] = useState({ value: '', error: '' })

  const [loading, setLoading] = useState(false)
  const [currentStatus, setCurrentStatus] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [currentItems, setCurrentItems] = useState(null)

  const [title, setTitle] = useState({ value: '', error: '' })
  const [description, setDescription] = useState({ value: '', error: '' })
  const [example, setExample] = useState({ value: '', error: '' })

  const [editIndex, setEditIndex] = useState(-1)
  const [editData, setEditData] = useState({
    title: { value: '', error: '' },
    description: { value: '', error: '' },
    example: { value: '', error: '' },
  })

  const handleOnSubmit = () => {
    if (title.value == '') {
      setTitle({ ...title, error: 'Please enter title!' })
      return
    }
    if (description.value == '') {
      setDescription({ ...description, error: 'Please enter description!' })
      return
    }
    // if (example.value == '') {
    //   setExample({ ...example, error: 'Please enter example!' })
    //   return
    // }
    clearErrors()
    let content = {
      title: title.value,
      description: description.value,
      example: example.value,
    }
    setLoading(true)

    if (editIndex > -1) {
      const temp = [...section2]
      temp[editIndex] = content
      setSection2(temp)
      handleYes(temp)

      clear()
      // setShowModal(false)
    } else {
      setSection2([...section2, content])
      handleYes([...section2, content])
      clear()
      // setShowModal(false)
    }
    setLoading(false)
  }

  const onEdit = (item, index) => {
    // e.preventDefault();
    // setLoading(true);
    setEditData(item)
    setEditIndex(index)
    setTitle({ ...title, value: item.title })
    setDescription({ ...description, value: item.description })
    setExample({ ...example, value: item.example })
  }
  const clear = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      title: { value: '', error: '' },
      description: { value: '', error: '' },
      example: { value: '', error: '' },
    })
    setTitle({ value: '', error: '' })
    setDescription({ value: '', error: '' })
    setExample({ value: '', error: '' })
    setEditIndex(-1)
    // setShowModal(false)
    // handleOnSubmit()
  }

  const closeModal = () => {
    resetAll()
    setShowModal(false)
  }
  const resetAll = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      title: { value: '', error: '' },
      description: { value: '', error: '' },
      example: { value: '', error: '' },
    })
    setEditIndex(-1)
    setTitle({ ...title, value: '', error: '' })
    setDescription({ ...description, value: '', error: '' })
    setExample({ ...example, value: '', error: '' })
  }
  const clearErrors = () => {
    setTitle({ ...title, error: '' })
    setExample({ ...example, error: '' })
  }

  const onItemDelete = (item, index) => {
    if (window.confirm('Are You Sure to Delete?')) {
      const temp = [...section2]
      temp.splice(index, 1)
      setSection2(temp)
      handleYes(temp)
    }
  }
  return (
    <Modal show={showModal}>
      <form onSubmit={handleOnSubmit}>
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>{heading}</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => closeModal()}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '50%' }}
                    required={true}
                    value={title.value}
                    onChange={(e) => {
                      setTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={title.error}
                  />
                  <FormInputComponent
                    heading='Description:'
                    type={'textarea'}
                    style={{ width: '50%' }}
                    required={true}
                    value={description.value}
                    onChange={(e) => {
                      setDescription({ error: '', value: e.target.value })
                    }}
                    errorMessage={description.error}
                  />
                </div>
                <FormInputComponent
                  heading='Example:'
                  style={{ width: '100%' }}
                  value={example.value}
                  onChange={(e) => {
                    setExample({ error: '', value: e.target.value })
                  }}
                  errorMessage={example.error}
                />
                <div
                  className='modal_dark_buttons'
                  style={{ padding: '0.5rem' }}>
                  <button
                    type='button'
                    className='modal__button-reset-dark'
                    onClick={resetAll}>
                    Reset
                  </button>
                  <button
                    type='button'
                    className='modal__button-dark'
                    onClick={handleOnSubmit}>
                    {loading ? 'Saving..' : editIndex > -1 ? 'Save' : 'Add'}
                  </button>
                </div>
              </div>
            </div>
            <div className='modal_inner_content'>
              <div className='modal_form_container'>
                <table
                  className='modal__form-table'
                  cellspacing='0'
                  cellpadding='0'>
                  <thead className='modal__form-sticky'>
                    <tr className='modal__form-tr'>
                      <th>No.</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Example</th>
                      <th className='modal__form-buttons'>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {section2 &&
                      section2.length &&
                      section2.map((item, i) => {
                        return (
                          <tr className='modal__form-tr2' key={i}>
                            <td>{i + 1}</td>
                            <td>{item.title}</td>
                            <td>{item.description}</td>
                            <td>{item.example}</td>
                            <td className='modal__form-buttons'>
                              <button
                                type='button'
                                className='modal__button-edit'
                                onClick={(e) => onEdit(item, i)}>
                                edit
                              </button>
                              <button
                                type='button'
                                className='modal__button-delete'
                                onClick={(e) => onItemDelete(item, i)}>
                                delete
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditInsurancePoints
