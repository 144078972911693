import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { getCustomLocalDate, getTimeZoneLocalDate } from '../../constants';

const TopicCard = ({ id, date, name, image }) => {


    return (
        <Link
            key={id}
            to={`/mwfbi/about/blog/${id}`}
            className="learning_content__card"
            style={{
                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.4)), url("${image?.replace(/ /g, "%20")}") `
            }}
        >
            <div className="learning_content__card--date heading_4">
                <svg className="learning_content__card--date_icon">
                    <use xlinkHref="/sprite.svg#icon-access_time" />
                </svg>
                {getTimeZoneLocalDate(date).format("MMM DD YYYY")}
            </div>
            <div className="learning_content__card--title heading_3">{name}</div>
        </Link>
    );
};

export default TopicCard;
