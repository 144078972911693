import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import styles from './ImageOption.module.css'
import { useCallback } from "react";
import { imageUpload } from "../../../../../redux/actions/contentAction";
import FormInputComponent from "../../../../FormInputComponent/FormInputComponent";

const ImageOption = ({ edit, onSave }) => {
  const [uploadImagePreview, setUploadImagePreview] = useState(edit ? edit.imagePath : "");
  const [uploadImage, setUploadImage] = useState(null);
  const [title, setTitle] = useState({ value: edit ? edit.title : '', error: '' })
  const [link, setLink] = useState({ value: edit ? edit.link : '', error: '' })
  const [token, setToken] = useState({ value: edit && edit.hasOwnProperty('hasToken') ? edit.hasToken : false, error: '' })
  const onDrop = useCallback((acceptedFiles) => {
    encodeImageFileAsURL(acceptedFiles);
  }, []);

  const encodeImageFileAsURL = (files) => {
    var file = files[0];
    setUploadImage(file);
    var reader = new FileReader();
    reader.onloadend = function () {
      if (reader.result) {
        setUploadImagePreview(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg,image/png",
    onDrop,
  });

  const handleSubmit = async () => {
    if (title.value === '') {
      setTitle({ ...title, error: 'Please Enter the title!' });
      return
    }
    if (!uploadImagePreview) {
      setUploadImage({ error: 'Please select image!' })
      return
    }
    if (uploadImage) {
      const uploadedFile = await imageUpload(uploadImage);
      // console.log("Image", { title: title.value, imageTemplatePath: uploadedFile.data.path, withImageTemplate: true, points: [] });
      onSave({ title: title.value, imagePath: uploadedFile.data.path, type: "Image", points: [], link: link.value, hasToken: token.value })
    } else onSave({ title: title.value, imagePath: edit.imagePath, type: "Image", points: [], link: link.value, hasToken: token.value })
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '4rem' }}>
      <div style={{ width: '50%' }}>
        <FormInputComponent
          headingStyle={styles.inputHeading}
          height={"5rem"}
          style={{ width: "50rem" }}
          required
          value={title.value}
          heading={"Title"}
          onChange={(e) => {
            setTitle({ error: '', value: e.target.value })
          }}
          errorMessage={title.error}
        // value={title.value}
        />
        <FormInputComponent
          headingStyle={styles.inputHeading}
          height={"5rem"}
          style={{ width: "50rem" }}
          required
          value={link.value}
          heading={"Link"}
          onChange={(e) => {
            setLink({ error: '', value: e.target.value })
          }}
          errorMessage={link.error}
        />
        <FormInputComponent
          style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}
          headingStyle={styles.inputHeading}
          height={"2rem"}
          type={'checkbox'}
          value={edit?.hasToken}
          checked={edit?.hasToken}
          heading={"Want to send token?"}
          onChange={(e) => {
            setToken({ error: '', value: !token.value })
          }}
          errorMessage={token.error}
        />

        <div className="modal_inner_content--right3" style={{ padding: '0rem', alignItems: 'inherit' }}>
          <label
            style={{
              fontSize: "1.6rem",
              marginTop: "1rem",
              color: "#808080",
            }}
          >
            Image
          </label>
          {/* <PdfUploader uploadImage={uploadImage} setUploadImage={setUploadImage} /> */}
          <div
            className="upload_border"
            {...getRootProps()}
            style={{ marginTop: "-1rem", width: "40rem" }}
          >
            <input {...getInputProps()} />
            {uploadImagePreview === "" ? (
              <>
                <svg className="modal_upload__icon-dark">
                  <use xlinkHref="/sprite.svg#icon-cloud" />
                </svg>
                <label>Please choose image to upload</label>
              </>
            ) : (
              <img
                alt="upload icon"
                height={90}
                width={140}
                src={uploadImagePreview}
                style={{ marginTop: "0rem", alignSelf: "center" }}
              />
            )}
            {uploadImage && uploadImage.error && (
              <p className="form_input__error">{uploadImage.error}</p>
            )}
          </div>
        </div>
      </div>
      <button
        style={{
          alignSelf: 'end',
          marginTop: '-4px',
        }}
        type="button"
        className="modal__button-reset-dark"
        onClick={handleSubmit}
      >
        Save
      </button>
    </div>
  )
}

export default ImageOption;