import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'

const EditSection4 = ({ setShowModal, showModal, handleYes, section4, setSection4, loading }) => {

    const [uploadImagePreview, setUploadImagePreview] = useState(section4.image.value)
    const [uploadImage, setUploadImage] = useState(null)

    const onDrop = useCallback((acceptedFiles) => {
        encodeImageFileAsURL(acceptedFiles)
    }, [])
    const encodeImageFileAsURL = (files) => {
        var file = files[0]
        setUploadImage(file)
        var reader = new FileReader()
        reader.onloadend = function () {
            if (reader.result) {
                setUploadImagePreview(reader.result)
            }
        }
        reader.readAsDataURL(file)
    }
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg,image/png',
        onDrop,
    })

    useEffect(() => {
        if (section4.image.value)
            setUploadImagePreview(section4.image.value)
    }, [])
    const submit = () => {
        let temp = { ...section4 };
        if (section4.title.value === '') {
            temp.title.error = 'Please enter title!'
            setSection4(temp)
            return
        } else {
            temp.title.error = ''
            setSection4(temp)
        }
        if (section4.description.value === '') {
            temp.description.error = 'Please enter description!'
            setSection4(temp)
            return
        } else {
            temp.description.error = ''
            setSection4(temp)
        }
        if (section4.description2.value === '') {
            temp.description2.error = 'Please enter description!'
            setSection4(temp)
            return
        } else {
            temp.description2.error = ''
            setSection4(temp)
        }

        handleYes(temp, uploadImage)
    }
    return (
        <Modal show={showModal}>
            <form >
                <div className='modal__container-dark'>
                    <div className='modal_heading__container-dark'>
                        <h1 className='modal_heading__text-dark'>Edit Info</h1>
                        <button
                        type='button'
                            className='modal__heading--button-dark'
                            onClick={() => setShowModal(false)}>
                            <svg className='modal_heading__icon-dark'>
                                <use xlinkHref='/sprite.svg#icon-cross' />
                            </svg>
                        </button>
                    </div>
                    <div className='modal__form-dark'>
                        <div className='modal_inner_content'>
                            <div className='modal_inner_content--left'>
                                <div className='pre_heading'>
                                    <FormInputComponent
                                        heading='Title:'
                                        style={{ width: '90%' }}
                                        required={true}
                                        value={section4.title.value}
                                        onChange={(e) => {
                                            let temp = { ...section4 }
                                            temp.title.value = e.target.value
                                            setSection4(temp)
                                        }}
                                        errorMessage={section4.title.error}
                                    />
                                </div>
                                <div className='pre_heading'>
                                    <FormInputComponent
                                        heading='Description:'
                                        style={{ width: '90%' }}
                                        type={'textarea'}
                                        required={true}
                                        height={'8rem'}
                                        value={section4.description.value}
                                        onChange={(e) => {
                                            let temp = { ...section4 }
                                            temp.description.value = e.target.value
                                            setSection4(temp)
                                        }}
                                        errorMessage={section4.description.error}
                                    />
                                </div>
                                <div className='pre_heading'>
                                    <FormInputComponent
                                        heading='Description 2:'
                                        style={{ width: '90%' }}
                                        type={'textarea'}
                                        required={true}
                                        height={'8rem'}
                                        value={section4.description2.value}
                                        onChange={(e) => {
                                            let temp = { ...section4 }
                                            temp.description2.value = e.target.value
                                            setSection4(temp)
                                        }}
                                        errorMessage={section4.description2.error}
                                    />
                                </div>
                                <div className='pre_heading'>
                                    <FormInputComponent
                                        heading='Image Tag:'
                                        style={{ width: '90%' }}
                                        type={'textarea'}
                                        required={true}
                                        height={'8rem'}
                                        value={section4.imageTag.value}
                                        onChange={(e) => {
                                            let temp = { ...section4 }
                                            temp.imageTag.value = e.target.value
                                            setSection4(temp)
                                        }}
                                        errorMessage={section4.imageTag.error}
                                    />
                                </div>
                                <div className='pre_heading'>
                                    <FormInputComponent
                                        heading='Image Heading:'
                                        style={{ width: '90%' }}
                                        type={'textarea'}
                                        required={true}
                                        height={'8rem'}
                                        value={section4.imageHead.value}
                                        onChange={(e) => {
                                            let temp = { ...section4 }
                                            temp.imageHead.value = e.target.value
                                            setSection4(temp)
                                        }}
                                        errorMessage={section4.imageHead.error}
                                    />
                                </div>

                            </div>
                            <div className='modal_inner_content--right'>
                                <label>Please choose image to upload</label>
                                <div className='upload_border' {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <label>Click or Drag a image to this area to upload</label>
                                    <svg className='modal_upload__icon-dark'>
                                        <use xlinkHref='/sprite.svg#icon-cloud' />
                                    </svg>
                                </div>
                                <div className='upload_preview_modal'>
                                    <img style={{marginTop:'6rem'}} width={400} height={140} src={uploadImagePreview ? uploadImagePreview : section4.image.value}></img>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{marginTop:'6rem'}} className='modal_dark_buttons'>
                        <button
                            type='button'
                            className='modal__button-reset-dark'
                            onClick={() => setShowModal(false)}>
                            Cancel
                        </button>
                        {loading ? (
                            <button type='button' className='modal__button-dark'>Please wait...</button>
                        ) : (
                            <button type='button' className='modal__button-dark' onClick={submit}>
                                Submit
                            </button>
                        )}
                    </div>
                </div>
            </form>
        </Modal>
    )
}

export default EditSection4

