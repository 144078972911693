/* eslint-disable default-case */
import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { Link, Switch } from "react-router-dom";
import Content from "../../../components/Content/Content";
import "./StartAQuote.css";
import Banner from "../../../components/Banner/Banner";
import TextInputPrefix from "../../../components/Inputs/TextInputPrefix";
import FormInputComponent from "../../../components/FormInputComponent/FormInputComponent";
import {
  validateEmail,
  validateOnlyChacters,
  validateOnlyNumber,
  validatePhone,
  validateZipCode,
} from "../utils/validators";
import { sendStartAQuoteAction } from "../../../redux/actions/mailActions";
import { useDispatch, useSelector } from "react-redux";
import { SEND_START_A_QUOTE_RESET } from "../../../redux/constants/mailConstants";
import { inputPhoneMasking } from "../utils/phonemask";
import { useDropzone } from "react-dropzone";
import {
  addContent,
  addContentItem,
  addFormContent,
  deleteFormField,
  getFormContent,
  getPageContent,
  updateFormContent,
} from "../../../redux/actions/contentAction";
import EditRequestInfo from "./edit/EditRequestInfo";
import EditFieldTypeModal from "./edit/EditFieldTypeModal";
import EditEmailAddressesModal from "./edit/EditEmailAddresses";
import CheckBox from "../../../components/Inputs/Checkbox";
import MultiSelectDropdown from "../../../components/MultiSeclectDropdown/MultiSelectDropdown";
import { getAgentList } from "../../../redux/actions/agentListActions";
import { ADMIN_USERS } from "../../../redux/actions/ip";
import { commaSeprator, splitArrayTo2D } from "../../../redux/constants/utils";


let fileIndex = [-1, -1];

const StartAQuote = ({ match }) => {
  const dispatch = useDispatch();
  const azureLogin = useSelector(state => state.azureLogin)
  const { agentList } = useSelector(state => state.agentList)
  const [showEdit, setShowEdit] = useState(false);
  const [showEmailEdit, setShowEmailEdit] = useState(false);
  const [showAgentRequestModal, setShowAgentRequestModal] = useState(false);
  const [bannerData, setBannerData] = useState({
    title: { value: "Start a Quote", error: "" },
    subTitle: {
      value:
        "Fill out the form below to facilitate a quote review with your local agent.",
      error: "",
    },
    tagline: {
      value: "WE TAKE PRIDE IN OUR CLIENT RELATIONSHIPS",
      error: "",
    },
    button1: { value: "Find Your Agent", error: "" },
    button1Link: { value: "/mwfbi/find-an-agent", error: "" },
    button1Status: { value: true, error: "" },
    button2: { value: "", error: "" },
    button2Link: { value: "", error: "" },
    button2Status: { value: false, error: "" },
    imageUrl: { value: "/images/start-a-quote.png", error: "" },
  });

  const typeArray = [
    {
      id: 1,
      checked: false,
      title: "Auto",
    },
    {
      id: 2,
      checked: false,
      title: "Home",
    },
    {
      id: 3,
      checked: false,
      title: "Farm & Ranch",
    },
    {
      id: 4,
      checked: false,
      title: "Renters",
    },
    {
      id: 5,
      checked: false,
      title: "Life Insurance",
    },
    {
      id: 6,
      checked: false,
      title: "Annuities",
    },
    {
      id: 7,
      checked: false,
      title: "Commercial/Business",
    },
    {
      id: 8,
      checked: false,
      title: "Other",
    },
  ];

  const preferredArray = [
    {
      id: 1,
      checked: false,
      title: "Phone",
    },
    {
      id: 2,
      checked: false,
      title: "Email",
    },
    {
      id: 3,
      checked: false,
      title: "No preference",
    },
  ];

  const [editFormData, setEditFormData] = useState([]);

  const [typeList, setTypeList] = useState(typeArray);
  const [preferredList, setPreferredList] = useState(preferredArray);
  const [preferenceList, setPreferenceList] = useState([]);

  const [selectAgent, setSelectAgent] = useState("");
  const [selectAgentError, setSelectAgentError] = useState("");
  const [currentInsuranceError, setCurrentInsuranceError] = useState("");
  const [selectPreference, setSelectPreference] = useState("nopreference");
  const [selectPreferenceError, setSelectPreferenceError] = useState("");

  const [state, setState] = useState("");

  const [showFieldTypeModal, setShowFieldTypeModal] = useState(false);
  const [fieldTypeArr, setFieldTypeArr] = useState([]);

  const agentId = match.params.id;
  const [agentData, setAgentData] = useState({});
  const sendStartAQuote = useSelector((state) => state.sendStartAQuote);
  const [fieldArr, setFieldArr] = useState([]);
  const [resultArr, setResultArr] = useState([]);


  const onDrop = (files) => {
    // Do something with the files
    var file = files[0];
    if (file) {
      if (fileIndex.length === 2) {
        if (fileIndex[0] > -1 && fileIndex[1] > -1 && resultArr.length) {
          const temp = [...resultArr];
          temp[fileIndex[0]][fileIndex[1]].selectedValue = file;
          setResultArr(temp)
        }
      }
    }
  }
  const onDropRejected = (file) => {
    if (file.length && fileIndex.length === 2) {
      if (fileIndex[0] > -1 && fileIndex[1] > -1 && resultArr.length) {
        const temp = [...resultArr];
        temp[fileIndex[0]][fileIndex[1]].error = file[0].errors.map(item => item.code === 'file-too-large' ? 'File is larger than 5MB' : item.message).join(',');
        setResultArr(temp)
      }
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png,application/pdf', minSize: 0,
    maxSize: 5242880, onDrop, onDropRejected
  })
  const splitArray = (array) => {
    let newarr = [];
    let resultArr = [];
    array?.forEach((element) => {
      if (
        element?.FieldInputType == "Textbox" ||
        element?.FieldInputType == "Select"
      ) {
        newarr.push(element);
        if (newarr.length == 2) {
          resultArr.push(newarr);
          newarr = [];
        }
      } else {
        resultArr.push([element]);
      }
    });
    if (newarr.length != 0) {
      resultArr.push(newarr);
      newarr = [];
    }
    return resultArr;
  };

  const dropDownValues = (item) => {
    let arr_obj = [];
    commaSeprator(item.FieldValue).forEach((data) => {
      let obj = { label: data, value: data };
      arr_obj.push(obj);
    });
    return arr_obj;
  };



  useEffect(() => {
    const tempArr = [];
    if (fieldTypeArr.length > 0) {
      fieldTypeArr.forEach((arr) => {
        tempArr.push({ label: arr.FieldLabel, value: "", id: arr.ID });
      });
    }
    setFieldArr(tempArr);
  }, [fieldTypeArr]);

  const [section1, setSection1] = useState({
    title: {
      value: "REQUEST A QUOTE FROM YOUR LOCAL AGENT",
      action: "",
      type: "",
    },
    description: {
      value: `Our agents are located throughout Wyoming and Montana. If you are interested in receiving a quote from 		Mountain West, please fill out the form below. Your nearest Farm Bureau agent will be in touch to help you 				receive a comprehensive quote.`,
    },
  });


  /* console.log(radioValue) */

  useEffect(() => {
    debugger
    if (agentList && agentList.result && agentList.result.length > 0) {
      setPreferenceList(agentList.result.map(item => {
        if (item.city && !item.agentSearchAddress) {
          item.agentSearchAddress = `${item.city} - ${item.title}`
        }
        return item
      }))
    }
    if (agentId && agentList && agentList.result && agentList.result.length > 0) {
      setPreferenceList(agentList.result.map(item => {
        if (item.city && !item.agentSearchAddress) {
          item.agentSearchAddress = `${item.city} - ${item.title}`
        }
        return item
      }))
      let obj = agentList.result.find((o) => o.email === agentId);
      if (obj) {
        setAgentData(obj);
        if (!obj.state && obj.address) {
          const split = obj.address.split(',');
          const splitAgain = split[split.length - 1];
          if (splitAgain.length) {

            setSelectAgent(splitAgain[0].trim() === "MT" ? "Montana" : "Wyoming");
          }
        }
        else
          setSelectAgent(obj.state);
        setState(obj.state);
        setSelectPreference(obj.email);
        // setAgentEmail(obj.email);
      } else {

      }
    }
  }, [agentList, agentId]);

  useEffect(() => {
    if (
      sendStartAQuote &&
      sendStartAQuote.userInfo &&
      sendStartAQuote.userInfo.status
    ) {
      alert(sendStartAQuote.userInfo.message);
      clearData();
      dispatch({ type: SEND_START_A_QUOTE_RESET });
    } else if (
      sendStartAQuote &&
      sendStartAQuote.userInfo &&
      !sendStartAQuote.userInfo.status &&
      sendStartAQuote.userInfo.message
    ) {
      alert(sendStartAQuote.userInfo.message);
      dispatch({ type: SEND_START_A_QUOTE_RESET });
    }
  }, [sendStartAQuote]);


  useEffect(() => {
    if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
      if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
        setShowEdit(true)
      } else setShowEdit(false)
    } else setShowEdit(false)
  }, [azureLogin])

  useEffect(() => {
    getPageContent({ pageName: "StartAQuote" }).then((res) => {
      if (res.data.status) {
        if (res.data.result.Section_1) setSection1(res.data.result.Section_1);
        if (res.data.result.TopBanner)
          setBannerData({
            ...res.data.result.TopBanner,
            button1Status: {
              value: res.data.result.TopBanner.button1Status.value === "true",
            },
            button2Status: {
              value: res.data.result.TopBanner.button2Status.value === "true",
            },
          });
      }
    });
    dispatch(getAgentList())
  }, []);

  useEffect(() => {
    getFormContent("StartAQuote").then((res) => {
      setFieldTypeArr(
        res.data.result.map((item) => ({
          ...item,
          selectedValue: item.FieldType === "Multi-Value" ? [] : "",
          error: "",
        }))
      );
      setResultArr(
        splitArrayTo2D(
          res.data.result.map((item) => ({
            ...item,
            selectedValue: item.FieldType === "Multi-Value" ? [] : "",
            error: "",
          }))
        )
      );
    });

  }, []);

  const compareStrings = (a, b) => {
    // Assuming you want case-insensitive comparison
    if (!a)
      a = ""
    if (!b)
      b = ""
    a = a.trim().toLowerCase();
    b = b.trim().toLowerCase();

    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }
  const onSelectHandler = (e) => {
    setSelectAgentError("");
    setSelectPreference("nopreference");
    setSelectAgent(e);
    if (e.trim().length > 0 && agentList && agentList.result) {
      let value = agentList.result.map(item => {
        if (!item.agentSearchAddress && item.city) {
          item.agentSearchAddress = `${item.city} - ${item.title}`
        }
        return item;
      }).filter((obj) =>
        obj.state && obj.state.toLowerCase() === e.toLowerCase()
      );
      setPreferenceList(value);
    } else {
      setPreferenceList([]);
    }
  };

  const onHandleForm = async (e) => {
    e.preventDefault();
    let formValid = true;
    // if (selectAgent === "" && selectAgent.trim() === "") {
    //   setSelectAgentError("Please select state");
    //   formValid = false
    // } else if (selectPreference === "" && selectPreference.trim() === "") {
    //   setSelectPreference("");
    //   formValid = false
    // }
    const temp = resultArr.map((field) => {
      let fieldTemp = [...field];
      fieldTemp = fieldTemp.map((element) => {
        let item = { ...element };
        if (item.RequiredStatus) {
          if (item.selectedValue === "") {
            formValid = false;
            item.error = `Please enter the ${item.FieldLabel}`;
          } else item.error = "";
          if (item.selectedValue.length === 0) {
            formValid = false;
            item.error = `Please enter the ${item.FieldLabel}`;
          } else item.error = "";

          switch (item.ValidationType) {
            case "Only Text":
              if (
                item.selectedValue.length > 0 &&
                validateOnlyChacters(item.selectedValue) === false
              ) {
                formValid = false;
                item.error = `Please enter valid ${item.FieldLabel}`;
              }
              return item;
            case "Only Number":
              if (
                item.selectedValue.length > 0 &&
                validateOnlyNumber(item.selectedValue) === false
              ) {
                formValid = false;
                item.error = `Please enter valid ${item.FieldLabel}`;
              }
              return item;
            case "Email":
              if (
                item.selectedValue.length > 0 &&
                validateEmail(item.selectedValue) === false
              ) {
                formValid = false;
                item.error = `Please enter valid ${item.FieldLabel}`;
              }
              return item;
            case "Phone":
              if (
                item.selectedValue.length > 0 &&
                validatePhone(item.selectedValue) === false
              ) {
                formValid = false;
                item.error = `Please enter valid ${item.FieldLabel}`;
              }
              return item;
            case "Zip":
              if (
                item.selectedValue.length > 0 &&
                validateZipCode(item.selectedValue) === false
              ) {
                formValid = false;
                item.error = `Please enter valid ${item.FieldLabel}`;
              }
              return item;
          }
        }
        return item;
      });
      return fieldTemp;
    });

    setResultArr(temp);
    let formData = new FormData();
    if (formValid) {
      let body = [];
      let email = ''
      resultArr.forEach((field) => {
        field.forEach((item) => {
          // body.push({ key: item.FieldLabel });
          // body.push({ value: item.selectedValue });
          if (item.FieldLabel === 'email' || item.FieldLabel === 'Email')
            email = item.selectedValue
          if (item.FieldInputType === "FileUpload" && item.selectedValue) {
            formData.append('files', item.selectedValue);
            body = [...body, { key: item.FieldLabel, value: item.selectedValue.name, isFile: true }];
          } else
            body = [...body, { key: item.FieldLabel, value: item.selectedValue }];
        });
        // key: item.FieldLabel,
        // value: item.selectedValue,
      });
      // let formData = {
      //   to: selectPreference,
      //   name: firstName + " " + lastName,
      //   from_email: "getaquote@mwfbi.com",
      //   subject: "Quote request confirmation",
      //   body: body,
      // };

      if (selectPreference !== "nopreference") {
        const ag = preferenceList.find(item => item.email === selectPreference)
        if (ag) {
          formData.append("agentName", ag.agentSearchAddress);
        }
      }
      formData.append("name", temp.length && temp[0].length ? temp[0][0].selectedValue : "");
      formData.append("from_email", "getaquote@mwfbi.com");
      formData.append("email", email);
      formData.append("to", selectPreference);
      formData.append("state", selectAgent);
      formData.append("subject", "Quote request confirmation");
      formData.append("body", JSON.stringify(body));
      sendStartAQuoteAction(formData)
        .then(res => {
          if (res.data.status) {
            resetForm()
            alert("Your quote request has been sent.")
          }
        })
    }

    // console.log(validForm, "validForm");
  };
  const resetForm = () => {
    setSelectAgent("");
    setSelectPreference("");
    const temp = resultArr.map((field) => {
      let fieldTemp = [...field];
      fieldTemp = fieldTemp.map((element) => {
        let item = { ...element };
        item.selectedValue = item.FieldType === "Multi-Value" ? [] : "";
        return item;
      });
      return fieldTemp;
    });

    setResultArr(temp);
  }
  const clearData = () => {
    setSelectAgent("");
    setSelectPreference("");
    setTypeList(typeArray);
    setPreferredList(preferredArray);
  };

  const handleHomeInsuranceInfoSubmit = () => {
    addContent({
      pageName: "StartAQuote",
      section: "Section_1",
      content: section1,
    }).then((result) => {
      if (result.data.status) {
        alert("Content Saved");
      }
      setShowAgentRequestModal(false);
    });
  };

  const handleDynamicFormSubmit = (content) => {
    addFormContent({
      fieldType: content.fieldType,
      fieldInputType: content.fieldInputType,
      fieldLabel: content.fieldLabel,
      fieldValue: content.fieldValue,
      placeHolder: content.placeHolder,
      validationType: content.validationType,
      pageName: "StartAQuote",
      requiredStatus: content.requiredStatus === "True" ? 1 : 0,
    }).then((res) => {
      if (res.data.status) {
        getFormContent("StartAQuote").then((res) => {
          setFieldTypeArr(res.data.result);
          setResultArr(splitArray(res.data.result));
        });
        alert("Field Saved")
      }
    });
  };

  const handleDynamicFormUpdate = (id, content) => {
    updateFormContent(id, {
      fieldType: content.fieldType,
      fieldInputType: content.fieldInputType,
      fieldLabel: content.fieldLabel,
      fieldValue: content.fieldValue,
      placeHolder: content.placeHolder,
      validationType: content.validationType,
      pageName: "StartAQuote",
      requiredStatus: content.requiredStatus === "True" ? 1 : 0,
    }).then((res) => {
      if (res.data.status) {
        getFormContent("StartAQuote").then((res) => {
          setFieldTypeArr(res.data.result);
          setResultArr(splitArray(res.data.result));
        });
        alert("Field Saved")
      }
    });
  };

  const onFieldDelete = (e, item, index) => {
    e.preventDefault();
    console.log(item.ID, "id");
    if (window.confirm("Are You Sure to Delete?")) {
      deleteFormField(item.ID).then((res) => {
        if (res.data.status) {
          getFormContent("StartAQuote").then((res) => {
            setResultArr(splitArray(res.data.result));
            setFieldTypeArr(res.data.result);
          });
          alert("Field Deleted")
        }
      });
    }
  };

  return (
    <Content headerFooterMenu="mountain-west">
      <Banner
        backgroundImage="linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(/images/start-a-quote.png)"
        title="Start a Quote"
        subTitle="Fill out the form below to facilitate a quote review with your local agent."
        bannerData={bannerData}
        showEdit={showEdit}
        setBannerData={setBannerData}
      />
      <div className="quote">
        <div className="flex">
          <img
            src={"/images/sponsers/sponsers.png"}
            alt="Hand Shake"
            className="quote__image"
          />
          <h3 className="quote__text">
            {bannerData && bannerData.tagline
              ? bannerData.tagline.value
              : "We take pride in our client relationships"}
          </h3>
        </div>
        <Link to={bannerData.button1Link.value} className="mw_button">
          {bannerData.button1.value}
        </Link>
      </div>
      <div
        className="quote-main-box"
        style={{ width: "100%", position: "relative" }}
      >
        {showEdit && (
          <div
            className="mwfbi_banner__edit"
            style={{ top: "1rem" }}
            id="admin-edit"
            onClick={() => setShowAgentRequestModal(true)}
          >
            <svg>
              <use xlinkHref="/sprite.svg#icon-edit" />
            </svg>
          </div>
        )}
        <EditRequestInfo
          handleYes={handleHomeInsuranceInfoSubmit}
          section1={section1}
          showModal={showAgentRequestModal}
          setSection1={setSection1}
          setShowModal={true}
        />
        <div>
          <h2 className="quote_heading">{section1.title.value}</h2>
          <div className="quote_subheading">
            <div>
              <p>{section1.description.value}</p>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={onHandleForm}>
        <div className="main_outer_component">
          <div className="Select_agent_statewise">
            <div className="select_state">
              <label className="label_heading_quote">
                Select a state
                {/* <span className="red_star">&#42;</span> */}
              </label>
              <br />
              <select
                id="selectstate"
                name="selectstate"
                value={selectAgent}
                className="select__state__textbox"
                onChange={(e) => onSelectHandler(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Wyoming" id="">
                  Wyoming
                </option>
                <option value="Montana" id="">
                  Montana
                </option>
              </select>{" "}
              <br />
              {selectAgentError && (
                <p className="form_input__error">{selectAgentError}</p>
              )}
            </div>

            <div className="select_state">
              <label className="label_heading_quote">
                Please choose an agent near you{" "}
                {/* <span className="red_star">&#42;</span> */}
              </label>
              <br />

              <select
                id="selectPreference"
                name="selectPreference"
                className="select__state__textbox1"
                value={selectPreference}
                onChange={(e) => {
                  setSelectPreference(e.target.value);
                  setSelectPreferenceError("");
                }}
              >
                <option value="nopreference">No Preference</option>
                {preferenceList && preferenceList.length > 0 ? (
                  preferenceList.sort((a, b) => compareStrings(a.city, b.city)).map((item, i) => (
                    <option value={item.email} key={i}>
                      {item.agentSearchAddress}
                    </option>
                  ))
                ) : (
                  <h1> no data </h1>
                )}
              </select>
              <br />
              {selectPreferenceError && (
                <p className="form_input__error">{selectPreferenceError}</p>
              )}
            </div>
          </div>
          <div
            className="dash_heading_2"
            style={{ width: "100%", position: "relative" }}
          >
            <label>Your Information</label>
            {showEdit && (
              <div
                className="mwfbi_banner__edit"
                style={{ top: "2rem", right: "-52rem" }}
                id="admin-edit"
                onClick={() => setShowFieldTypeModal(true)}
              >
                <svg>
                  <use xlinkHref="/sprite.svg#icon-edit" />
                </svg>
              </div>
            )}

          </div>

          {resultArr.map((field, parentIndex) => {
            return (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                {field?.map((item, index) => {
                  switch (item?.FieldInputType) {
                    case "Textbox":
                      return (
                        <div
                          key={index}
                          style={{ width: "48%", marginBottom: "3rem" }}
                        >
                          <FormInputComponent
                            heading={
                              item.FieldLabel !== "undefined"
                                ? item.FieldLabel
                                : null
                            }
                            placeholder={
                              item.PlaceHolder ? item.PlaceHolder : null
                            }
                            style={{ width: "100%" }}
                            requiredStar={
                              item.FieldLabel !== "undefined"
                                ? item.RequiredStatus
                                : false
                            }
                            value={item.selectedValue}
                            onChange={(e) => {
                              let temp = [...resultArr];
                              temp[parentIndex][index].selectedValue =
                                e.target.value;
                              setResultArr(temp);
                            }}
                            errorMessage={currentInsuranceError}
                          />
                          <p className="form_input__error">{item.error}</p>
                        </div>
                      );
                    case "Date":
                      return (
                        <div
                          key={index}
                          style={{ width: "48%", marginBottom: "3rem" }}
                        >
                          <FormInputComponent
                            heading={item.FieldLabel !== "undefined" ? item.FieldLabel : null}
                            type={"date"}
                            placeholder={
                              item.PlaceHolder ? item.PlaceHolder : null
                            }
                            style={{ width: "100%" }}
                            requiredStar={
                              item.FieldLabel !== "undefined"
                                ? item.RequiredStatus
                                : false
                            }
                            value={item.selectedValue}
                            onChange={(e) => {
                              let temp = [...resultArr];
                              temp[parentIndex][index].selectedValue =
                                e.target.value;
                              setResultArr(temp);
                            }}
                            errorMessage={currentInsuranceError}
                          />
                          <p className="form_input__error">{item.error}</p>
                        </div>
                      );
                    case "Textarea":
                      return (
                        <div
                          key={index}
                          style={{ width: "100%", marginBottom: "2rem" }}
                        >
                          <FormInputComponent
                            height="140px"
                            type={"textarea"}
                            requiredStar={
                              item.FieldLabel !== "undefined"
                                ? item.RequiredStatus
                                : false
                            }
                            heading={
                              item.FieldLabel !== "undefined"
                                ? item.FieldLabel
                                : null
                            }
                            placeholder={
                              item.PlaceHolder ? item.PlaceHolder : null
                            }
                            style={{ width: "100%" }}
                            // required={
                            //   item.FieldLabel !== "undefined"
                            //     ? item.RequiredStatus
                            //     : false
                            // }
                            value={item.selectedValue}
                            onChange={(e) => {
                              let temp = [...resultArr];
                              temp[parentIndex][index].selectedValue =
                                e.target.value;
                              setResultArr(temp);
                            }}
                            errorMessage={currentInsuranceError}
                          />
                          <p className="form_input__error">{item.error}</p>
                        </div>
                      );

                    case "Select":
                      return (
                        <div key={index} style={{ width: "48%" }}>
                          <label
                            htmlFor="State"
                            className="label_heading_quote"
                          >
                            {item.FieldLabel}
                            {item.RequiredStatus ? (
                              <span className="red_star">&#42;</span>
                            ) : null}
                          </label>
                          <div>
                            <select
                              id="State"
                              name="State"
                              style={{ width: "100%", marginBottom: 0 }}
                              className="select__state__textbox1"
                              value={state}
                              onChange={(e) => {
                                setState(e.target.value);
                                let temp = [...resultArr];
                                temp[parentIndex][index].selectedValue =
                                  e.target.value;
                              }}
                            >
                              <option value="">Select</option>
                              {commaSeprator(item.FieldValue).map((value) => (
                                <option value={value}>{value}</option>
                              ))}
                            </select>
                          </div>
                          <p className="form_input__error">{item.error}</p>
                        </div>
                      );

                    case "Checkbox":
                      return (
                        <div
                          style={{ marginBottom: "2rem" }}
                          key={index}
                          className="label_heading_quote"
                        >
                          <label className="label_heading_quote">
                            {item.FieldLabel}
                            {item.RequiredStatus ? (
                              <span className="red_star">&#42;</span>
                            ) : null}
                          </label>
                          <br />
                          {commaSeprator(item.FieldValue).map((value) => (
                            <Fragment key={value}>
                              <input
                                type="checkbox"
                                id={value + "checkbox"}
                                // name={item.title}
                                style={{ marginRight: "2rem" }}
                                // checked={item.selectedValue.indexOf(value) > 0}
                                onChange={(e) => {
                                  let temp = [...resultArr];
                                  if (e.target.checked)
                                    temp[parentIndex][index].selectedValue.push(
                                      value
                                    );
                                  else {
                                    temp[parentIndex][
                                      index
                                    ].selectedValue.splice(
                                      temp[parentIndex][
                                        index
                                      ].selectedValue.indexOf(value),
                                      1
                                    );
                                  }
                                  setResultArr(temp);
                                }}
                              />
                              <label
                                className="label_heading_quote"
                                htmlFor="phone"
                                style={{ marginRight: "2rem" }}
                              >
                                {value}
                              </label>
                            </Fragment>
                          ))}
                          <p className="form_input__error">{item.error}</p>
                        </div>
                      );

                    case "Chips":
                      return (
                        <div
                          key={index}
                          style={{ marginBottom: "2rem" }}
                          className="Insurance__list"
                        >
                          <label className="label_heading_quote">
                            {item.FieldLabel}
                            {item.RequiredStatus ? (
                              <span className="red_star">&#42;</span>
                            ) : null}
                          </label>
                          <div className="list__of__contents">
                            <div className="list__of__contents2">
                              <ul>
                                {commaSeprator(item.FieldValue).map(
                                  (value) => (
                                    <li
                                      /* key={value} */
                                      className={`list__of__contents1 ${item.selectedValue?.includes(value)
                                        ? "list__of__contents1_active"
                                        : ""
                                        }`}
                                      onClick={() => {
                                        let temp = [...resultArr];

                                        if (
                                          item.selectedValue?.includes(value)
                                        ) {
                                          temp[parentIndex][
                                            index
                                          ].selectedValue.splice(
                                            item.selectedValue.indexOf(value),
                                            1
                                          );
                                        } else {
                                          if (!temp[parentIndex][index].selectedValue)
                                            temp[parentIndex][index].selectedValue = []
                                          temp[parentIndex][index].selectedValue = [...temp[parentIndex][index].selectedValue, value,
                                          ];
                                        }
                                        setResultArr(temp);
                                      }}
                                    >
                                      {value}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>

                          <p className="form_input__error">{item.error}</p>
                        </div>
                      );

                    case "Switch":
                      return (
                        <div key={index} style={{ marginTop: "2.4rem" }}>
                          <label className="label_heading_quote">
                            {item.FieldLabel}
                            {item.RequiredStatus ? (
                              <span className="red_star">&#42;</span>
                            ) : null}
                          </label>
                          <CheckBox
                            text={
                              item.selectedValue ===
                                commaSeprator(item.FieldValue)[1]
                                ? commaSeprator(item.FieldValue)[1]
                                : commaSeprator(item.FieldValue)[0]
                            }
                            key="b2"
                            /* checked={button2Status.value} */
                            onChange={(e) => {
                              let temp = [...resultArr];
                              if (e.target.checked) {
                                console.log(e.target.value, "check");
                                temp[parentIndex][index].selectedValue =
                                  commaSeprator(item.FieldValue)[1];
                              } else {
                                console.log("anil");
                                temp[parentIndex][index].selectedValue =
                                  commaSeprator(item.FieldValue)[0];
                              }
                              setResultArr(temp);
                            }}
                          />
                          <p className="form_input__error">{item.error}</p>
                        </div>
                      );

                    case "Radio":
                      return (
                        <div key={index} style={{ marginTop: "2.4rem" }}>
                          <label className="label_heading_quote">
                            {item.FieldLabel}
                            {item.RequiredStatus ? (
                              <span className="red_star">&#42;</span>
                            ) : null}
                          </label>
                          <br />
                          {commaSeprator(item.FieldValue).map((item) => (
                            <>
                              <input
                                type="radio"
                                name="radioValue"
                                id={item}
                                value={item}
                                onChange={(e) => {
                                  /* setRadioValue(e.target.value) */
                                  console.log(e.target.value);
                                  let temp = [...resultArr];
                                  // eslint-disable-next-line eqeqeq
                                  if (e.target.checked == true) {
                                    console.log(e.target.checked, "check");
                                    temp[parentIndex][index].selectedValue =
                                      item;
                                  } else {
                                    console.log(
                                      temp[parentIndex][index].selectedValue
                                    );
                                    temp[parentIndex][index].selectedValue = "";
                                  }
                                  setResultArr(temp);
                                }}
                              />
                              <label
                                className="label_heading_quote"
                                htmlFor="phone"
                                style={{
                                  marginLeft: "2rem",
                                  marginRight: "2rem",
                                }}
                              >
                                {item}
                              </label>
                            </>
                          ))}
                          <p className="form_input__error">{item.error}</p>
                        </div>
                      );

                    case "MultiSelect":
                      return (
                        <div
                          key={index}
                          style={{
                            marginTop: "2.4rem",
                            marginBottom: "3.2rem",
                          }}
                        >
                          <label className="label_heading_quote">
                            {item.FieldLabel}
                            {item.RequiredStatus ? (
                              <span className="red_star">&#42;</span>
                            ) : null}
                          </label>
                          <br />

                          <MultiSelectDropdown
                            /* className="multi_dropdown_start_a_quote" */
                            options={dropDownValues(item)}
                            placeHolder={item.PlaceHolder && item.PlaceHolder}
                            onChange={(e) => {
                              let vals = [];
                              console.log(e, "anil");
                              e.map((val) => {
                                vals = [...vals, val.value];
                              });
                              console.log("985", vals);
                              let temp = [...resultArr];
                              temp[parentIndex][index].selectedValue = vals;
                              setResultArr(temp);
                            }}
                          />

                          <p className="form_input__error">{item.error}</p>
                        </div>
                      );

                    case "FileUpload":
                      return (
                        <div
                          key={'upload' + parentIndex + index + item.FieldLabel}
                          id={'upload' + parentIndex + index + item.FieldLabel}
                          style={{
                            width: "100%",
                            marginTop: "2.4rem",
                            marginBottom: "3.2rem",
                          }}
                        >
                          <label key={'uploadhead' + parentIndex + index} className="label_heading_quote">
                            {item.FieldLabel}
                            {item.RequiredStatus ? (
                              <span className="red_star">&#42;</span>
                            ) : null}
                          </label>
                          <div key={'uploadClick' + parentIndex + index} className="upload_border" {...getRootProps({
                            onClick: (e) => {
                              fileIndex = [parentIndex, index]
                            }
                          })}>
                            <input key={'uploadKey' + parentIndex + index} {...getInputProps()} />
                            <label key={'uploadplca' + parentIndex + index}>{item.PlaceHolder ? item.PlaceHolder : 'Click or Drag a file to this area to upload'}</label>
                          </div>
                          {(
                            item &&
                            item.selectedValue && <label>{item.selectedValue.name}</label>
                          )}
                          {(
                            item &&
                            item.error && <p key={'upliaderror' + parentIndex + index} className="form_input__error">{item.error}</p>
                          )}
                        </div>)
                    default:
                      return null
                  }
                })}

              </ div >

            )
          })}
          {/* <button className="main__form_button" type="submit">
            Submit
          </button> */}
          <div style={{ width: "100%", position: "relative" }}>
            <button
              className="main__form_button"
              type="submit"
            >
              Submit
            </button>
            {showEdit && (
              <div
                className="mwfbi_banner__edit"
                style={{ top: "2rem", right: "-52rem" }}
                id="admin-edit"
                onClick={() => setShowEmailEdit(true)}
              >
                <svg>
                  <use xlinkHref="/sprite.svg#icon-edit" />
                </svg>
              </div>
            )}
          </div>
        </div>
      </form>
      <EditFieldTypeModal
        handleYes={handleDynamicFormSubmit}
        handleYes1={handleDynamicFormUpdate}
        showModal={showFieldTypeModal}
        setShowModal={setShowFieldTypeModal}
        editFormData={editFormData}
        setEditFormData={setEditFormData}
        fieldTypeArr={fieldTypeArr}
        onFieldDelete={onFieldDelete}
        setFieldTypeArr={setFieldTypeArr}
      />
      <EditEmailAddressesModal
        showModal={showEmailEdit}
        setShowModal={setShowEmailEdit}
        page={"StartAQuote"}
      />
    </Content>
  );
};

export default StartAQuote;
