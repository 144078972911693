import React, { useState } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../../components/Banner/Banner";
import Content from "../../../../components/Content/Content";
import InfoCard from "../../../../components/LandingPage/InfoCard/InfoCard";
import LinkCard from "../../../../components/LandingPage/LinkCard/LinkCard";
import EditModal from "../../../../components/LandingPage/Modals/Section1/EditModal";
import "./index.css";
import { addContent, getPageContent } from "../../../../redux/actions/contentAction";
import { useEffect } from "react";
import PdfCard from "../../../../components/LandingPage/PdfCard/PdfCard";
import ImageCard from "../../../../components/LandingPage/ImageCard/ImageCard";
import EditHeaderInfo from "./EditHeaderInfo";
import { ADMIN_USERS } from "../../../../redux/actions/ip";
import { useSelector } from "react-redux";
import EditFirstSection from "../../../../components/LandingPageLatest/EditModals/Section1/EditFirstSection";
import EditSecondSection from "../../../../components/LandingPageLatest/EditModals/Section2/EditSecondSection";
import EditThirdSection from "../../../../components/LandingPageLatest/EditModals/Section2/EditThirdSection";
import EditPointsSection from "../../../../components/LandingPageLatest/EditModals/Section2/EditPoints";
import EditPointsSection3 from "../../../../components/LandingPageLatest/EditModals/Section2/EditPoints3";
import EditPointAddSection from "../../../../components/LandingPageLatest/EditModals/Section2/PointAddForm";
import { splitArray, splitArrayNew } from "../../../../redux/constants/utils";
const pageName = 'AgentLandingPageLatest1';
const AgentLanding = ({ history }) => {
	const azureLoginState = useSelector((state) => state.azureLogin);

  const [showSection1Modal, setShowSection1Modal] = useState(false);
  const azureLogin = useSelector(state => state.azureLogin)
  const [showEdit, setShowEdit] = useState(false);
  const [section1, setSection1] = useState({
    title: { value: 'Page Header', action: '', type: '' },
  })
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [showSection2Modal, setShowSection2Modal] = useState(false);
  const [showSection3Modal, setShowSection3Modal] = useState(false);
  const [showSection2PointModal, setShowSection2PointModal] = useState(false);
  const [showSection3PointModal, setShowSection3PointModal] = useState(false);
  const [showSection2AddPointModal, setShowSection2AddPointModal] = useState(false);

  const [editPoint, setEditPoint] = useState(null);
  const [editPointIndex, setEditPointIndex] = useState(-1);
  const [editPoint3, setEditPoint3] = useState(null);
  const [editPointIndex3, setEditPointIndex3] = useState(-1);
  const [bannerData, setBannerData] = useState({
    title: { value: '', error: '' },
    subTitle: { value: '', error: '', },
    tagline: { value: '', error: '' },
    button1: { value: 'START A QUOTE', error: '' },
    button1Link: { value: '/mwfbi/start-a-quote', error: '' },
    button1Status: { value: false, error: '' },
    button2: { value: 'FIND AN AGENT', error: '' },
    button2Link: { value: '/mwfbi/find-an-agent', error: '' },
    button2Status: { value: false, error: '' },
    imageUrl: { value: 'https://mwfbiportal.blob.core.windows.net/imagecontainer/images/4076772639713142-background.png', error: '' },
  })
  const [section1Array, setSection1Array] = useState([]);
  const [section2Array, setSection2Array] = useState([
    {
      title: 'Business Tools',
      type: "Link",
      points: [
        { title: 'New Policy Browser', link: '' },
        { title: 'Info web Policy Document', link: '' },
        { title: 'Payment on New Business', link: '' },
        { title: 'Policy Payment', link: '' },
        { title: 'Brokerage Commissions', link: '' }
      ]
    },
    {
      title: 'Claims Resources',
      type: "Link",
      points: [
        { title: 'New Policy Browser', link: '' },
        { title: 'Info web Policy Document', link: '' },
        { title: 'Payment on New Business', link: '' },
        { title: 'Policy Payment', link: '' },
        { title: 'Brokerage Commissions', link: '' }
      ]
    },
    {
      title: 'State Sales Directors',
      type: "Link",
      points: [
        { title: 'Auto Insurance', link: '' },
        { title: 'Home Insurance', link: '' },
        { title: 'Farm/Ranch Insurance', link: '' },
        { title: 'Business Insurance', link: '' }
      ]
    },
    {
      title: 'Licensing Information',
      type: "Link",
      points: [
        { title: 'Montana Licensing', link: '' },
        { title: 'Wyoming Licensing', link: '' }
      ]
    },
    {
      title: 'Important Links',
      type: "Link",
      points: [
        { title: 'Homefield', link: '' },
        { title: 'Lexis Nexis', link: '' },
        { title: 'AS400', link: '' },
        { title: 'ERS Incident Report', link: '' },
        { title: 'Workfront', link: '' },
        { title: 'Meetings Website', link: '' },
        { title: 'Sales and Marketing Website', link: '' },
        { title: 'Company Store Website', link: '' },
      ]
    }
  ]);
  const [section3Array, setSection3Array] = useState([
    // {
    //   title: 'Friday Forum',
    //   type: "Link",
    //   points: [
    //     { title: 'Friday Forum - 10/21/2022', link: '' },
    //     { title: 'Friday Forum - 10/14/2022', link: '' },
    //     { title: 'Friday Forum - 10/07/2022', link: '' },
    //     { title: 'Friday Forum - 10/30/2022', link: '' }
    //   ]
    // },
    // {
    //   title: 'Needs to know',
    //   type: "Link",
    //   points: [
    //     { title: 'Needs To Know - 10/21/2022', link: '' },
    //     { title: 'Needs To Know - 10/14/2022', link: '' },
    //     { title: 'Needs To Know - 10/07/2022', link: '' },
    //     { title: 'Needs To Know - 10/30/2022', link: '' }
    //   ]
    // },
    // {
    //   title: 'Scoreboard Monthly Report',
    //   type: "Link",
    //   points: [
    //     { title: 'Scoreboard Report - 10/21/2022', link: '' },
    //     { title: 'Scoreboard Report - 10/14/2022', link: '' },
    //     { title: 'Scoreboard Report - 10/07/2022', link: '' },
    //     { title: 'Scoreboard Report - 10/30/2022', link: '' }
    //   ]
    // }
  ]);

  const [sectionArray, setSectionArray] = useState([{
    section: 'Section 1',
    title: 'CLIENTCONNECT',
    imageUrl: '/images/clientconnect.png',
    subSection: [
      {
        title: 'Business Tools',
        points: [
          { title: 'New Policy Browser', link: '' },
          { title: 'Info web Policy Document', link: '' },
          { title: 'Payment on New Business', link: '' },
          { title: 'Policy Payment', link: '' },
          { title: 'Brokerage Commissions', link: '' }
        ]
      },
      {
        title: 'Licensing Information',
        points: [
          { title: 'Montana Licensing', link: '' },
          { title: 'Wyoming Licensing', link: '' }
        ]
      },
      {
        title: 'Important Links',
        points: [
          { title: 'Homefield', link: '' },
          { title: 'Lexis Nexis', link: '' },
          { title: 'AS400', link: '' },
          { title: 'ERS Incident Report', link: '' },
          { title: 'Workfront', link: '' },
          { title: 'Meetings Website', link: '' },
          { title: 'Sales and Marketing Website', link: '' },
          { title: 'Company Store Website', link: '' },
        ]
      }
    ]
  },
  {
    section: 'Section 2',
    title: 'PORTAL PAYMENT',
    imageUrl: '/images/producer_engage.png',
    subSection: [
      {
        title: 'Claims Resources',
        points: [
          { title: 'New Policy Browser', link: '' },
          { title: 'Info web Policy Document', link: '' },
          { title: 'Payment on New Business', link: '' },
          { title: 'Policy Payment', link: '' },
          { title: 'Brokerage Commissions', link: '' }
        ]
      },
    ]
  },
  {
    section: 'Section 3',
    title: 'Start A Quote',
    imageUrl: '/images/mwoc-logo.png',
    subSection: [
      {
        title: 'State Sales Directors',
        points: [
          { title: 'Auto Insurance', link: '' },
          { title: 'Home Insurance', link: '' },
          { title: 'Farm/Ranch Insurance', link: '' },
          { title: 'Business Insurance', link: '' }
        ]
      },
      // {
      //   title: 'Last Searched Results',
      //   points: [
      //     { title: 'Adjuster Codes - PDF', link: '' },
      //     { title: 'Agent Code Listing - PDF', link: '' },
      //     { title: 'Agent Directory - PDF', link: '' },
      //     { title: 'Claims Contact & Code - PDF', link: '' }
      //   ]
      // }
    ]
  }]);
  const [splittedArray, setSplittedArray] = useState([]);
  useEffect(() => {
    if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
      if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
        setShowEdit(true)
      } else { setShowEdit(false) }
    } else {
        setShowEdit(false)
        history.push('/')
    }
  }, [azureLogin, azureLoginState])



  useEffect(() => {
    if (section2Array) {
      const modifiedArray = section2Array.map((item) => {
        if (typeof item.type === 'object') {
          return {
            ...item,
            type: item.type.value || 'Link', // Use the 'value' property or fallback to 'Link'
          };
        }
        return item;
      });

      let splt = splitArray(modifiedArray, 3);
      if (splt[splt.length - 1].length === 2)
        splt[splt.length - 1].push({});
      if (splt[splt.length - 1].length === 1) {
        splt[splt.length - 1].push({});
        splt[splt.length - 1].push({});
      }

      setSplittedArray(splt);
    }
  }, [section2Array]);

  const onSectionSave = (data) => {
    addContent({ pageName: pageName, section: "Section_1", content: { data: { value: JSON.stringify(data) } } })
      .then(result => {
        if (result.data.status) {
          alert("Content Saved")
        }
      })
  }

  // const onSectioSave = (data) => {
  //   addContent({ pageName: 'AgentLandingPage2', section: "Section_1", content: { data: { value: JSON.stringify(data) } } })
  //     .then(result => {
  //       if (result.data.status) {
  //         alert("Content Saved")
  //       }
  //     })
  // }


  useEffect(() => {
    getPageContent({ pageName: pageName }).then((res) => {
      if (res.data.status) {
        if (res.data.result.Section_1) setSection1Array(JSON.parse(res.data.result.Section_1.data.value))
        if (res.data.result.Header_Section_1) setSection1(res.data.result.Header_Section_1)
        if (res.data.result.Section_2_10) setSection2Array(JSON.parse(res.data.result.Section_2_10.data.value))
        if (res.data.result.Section_3_11) setSection3Array(JSON.parse(res.data.result.Section_3_11.data.value))
        // if (res.data.result.Section_3_6) setSection3Array(JSON.parse(res.data.result.Section_3_6.data.value))
        if (res.data.result.TopBanner) {
          setBannerData({
            ...res.data.result.TopBanner,
            button1Status: {
              value: res.data.result.TopBanner.button1Status.value === "true",
            },
            button2Status: {
              value: res.data.result.TopBanner.button2Status.value === "true",
            },
          });
        }
      }
    })
  }, [])

  useEffect(() => {
    if (editPoint && editPointIndex > -1) {
      setShowSection2PointModal(true)
    } else setShowSection2PointModal(false)
  }, [editPoint, editPointIndex])

  useEffect(() => {
    if (editPoint3 && editPointIndex3 > -1) {
      setShowSection3PointModal(true)
    } else setShowSection3PointModal(false)
  }, [editPoint3, editPointIndex3])

  const handleInfoSubmit = () => {
    addContent({
      pageName,
      section: 'Header_Section_1',
      content: section1,
    }).then((result) => {
      if (result.data.status) {
        alert('Content Saved')
      }
      setShowSectionModal(false)
    })
  }
  const onPointSave = (point, close = false) => {
    if (editPointIndex > -1) {
      let temp = [...section2Array]
      temp[editPointIndex] = point;
      setSection2Array(temp)
      if (close) {
        setEditPoint(null)
        setEditPointIndex(-1)
        setShowSection2PointModal(false);
      }
      saveSection2(temp)
    }
  }

  const sectionTitleEdit = (sectionTitle) => {
    let temp = [...section2Array];
    temp[editPointIndex].title = sectionTitle;
    saveSection2(temp)
  }

  const sectionTitleEdit3 = (sectionTitle) => {
    console.log(sectionTitle, 'sectionTitle')
    let temp = [...section3Array];
    temp[editPointIndex3].title = sectionTitle;
    saveSection3(temp)
  }

  const saveSection2 = (content) => {
    addContent({
      pageName,
      section: 'Section_2_10',
      content: { data: { value: JSON.stringify(content) } },
    }).then((result) => {
      if (result.data.status) {
        alert('Content Saved')
      }
      setShowSectionModal(false)
    })
  }
  const saveSection3 = (content) => {
    //   const array = [
    //     {
    //         title: "Friday Forum",
    //         type: "Link",
    //         points: [
    //             {
    //                 title: "07/14/23 - Utilizing Sales Associates for Success",
    //                 link: "https://youtu.be/glapzE1-mb0"
    //             },
    //             {
    //                 title: "07/07/23 - Federation Membership and a Case Study",
    //                 link: "https://youtu.be/-NPB0A3-1ek"
    //             },
    //             {
    //                 title: "06/23/23 - Need to Knows and Muddy Waters",
    //                 link: "https://youtu.be/E7jKioLTKEQ"
    //             },
    //             {
    //                 title: "06/02/23 - Daniel Ricklefs and FBL Changes",
    //                 link: "https://youtu.be/4NppgljgHKU"
    //             },
    //         ]
    //     },
    //     {
    //         title: "Scoreboard Magazine",
    //         type: "Link",
    //         points: [
    //             {
    //                 title: "Scoreboard Magazine - May 2023",
    //                 link: "https://indd.adobe.com/view/30093071-7fb7-40bd-89bd-246fb35ada06"
    //             },
    //             {
    //                 title: "Scoreboard Magazine - February 2023",
    //                 link: "https://indd.adobe.com/view/5d962ad2-a06b-4959-8cef-483f28c1743c"
    //             }
    //         ]
    //     },
    //     {
    //         title: "Scoreboard Monthly Report",
    //         type: "Link",
    //         points: [
    //             {
    //                 title: "June 2023 Scoreboard Report",
    //                 link: "https://indd.adobe.com/view/cd56ed28-a93e-4ffb-bd2b-0b59a34a8679"
    //             },
    //             {
    //                 title: "May 2023 Scoreboard Report",
    //                 link: "https://indd.adobe.com/view/0fd727c5-7cb1-4903-b81b-5dadbfc23a4a"
    //             },
    //             {
    //                 title: "April 2023 Scoreboard Report",
    //                 link: "https://indd.adobe.com/view/40151152-d0a5-4f0c-be75-9691c9281278"
    //             },
    //             {
    //                 title: "March 2023 Scoreboard Report",
    //                 link: "https://indd.adobe.com/view/a89ca754-b0bc-4f6c-ab87-4dd8d5dae715"
    //             }
    //         ]
    //     },

    // ]
    addContent({
      pageName,
      section: 'Section_3_11',
      content: { data: { value: JSON.stringify(content) } },
    }).then((result) => {
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }

  return (
    <Content headerFooterMenu="mountain-west">
      <Banner
        // backgroundImage={bannerData.imageUrl.value}
        backgroundImage="linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),url(/images/older-couple-beach-sunset.jpg)"
        pageName={"AgentLandingPage2"}
        bannerData={bannerData}
        showEdit={showEdit}
        setBannerData={setBannerData}

      />
      <div className="quote3" style={{ width: '100%', position: 'relative' }}>
        <div className="flex1">
          <h3 className="quote__text3">{section1.title.value}</h3>
        </div>
        {showEdit && (
          <div
            style={{ top: '3rem', right: "3rem" }}
            className='mwfbi_banner__edit'
            id='fbf-s2-admin-edit'
            data-testid="react-select-mock"
            onClick={() => setShowSectionModal(true)}
          >
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}
      </div>
      <div className="main_view_landing">
        <div className="icon_grid">
          <InfoCard title={section1Array[0]?.title} imageUrl={section1Array[0]?.imageUrl} cardLink={section1Array[0]?.link} token={section1Array[0]?.hasToken} />
          <InfoCard title={section1Array[1]?.title} imageUrl={section1Array[1]?.imageUrl} cardLink={section1Array[1]?.link} token={section1Array[1]?.hasToken} />
          <InfoCard title={section1Array[2]?.title} imageUrl={section1Array[2]?.imageUrl} cardLink={section1Array[2]?.link} token={section1Array[2]?.hasToken} />
          {showEdit && (
            <div
              className="edit_icon mwfbi_banner__edit "
              style={{ top: "-1rem", right: "-3rem" }}
              id="admin-edit"
              onClick={() => setShowSection1Modal(true)}
            >
              <svg>
                <use xlinkHref="/sprite.svg#icon-edit" />
              </svg>
            </div>
          )}

        </div>
        <div className="vertical_options_menu" style={{ width: '100%', marginTop: '18px', position: 'relative' }}>

          {splittedArray && splittedArray.length && splittedArray.map((headerColumn, headerColumnIndex) =>
            <div key={`${headerColumn?.title}option_division ${headerColumnIndex}`} className="vertical_grid">
              {headerColumn.map((section, sectionIndex) =>
              (<div className="grid_item">
                {section.type && section.type.toLocaleLowerCase() === "link" && <LinkCard key={section.title} title={section.title} list={section.points} />}
                {section.type && section.type.toLocaleLowerCase() === "image" && <ImageCard imagePath={section.imagePath} title={section.title} link={section.link} hasToken={section.hasToken} />}
                {section.type && section.type.toLocaleLowerCase() === "pdf" && <PdfCard title={section.title} pdfUrl={section.pdfPathData} pdfPath={section.pdfPath}/>}
              </div>))}

            </div>)}
          {showEdit && (
            <div
              className="edit_icon mwfbi_banner__edit "
              style={{ top: "-1rem", right: "-3rem" }}
              id="admin-edit"
              onClick={() => setShowSection2Modal(true)}
            >
              <svg>
                <use xlinkHref="/sprite.svg#icon-edit" />
              </svg>
            </div>
          )}
        </div>
        <hr style={{ marginTop: '2rem', marginBottom: '2rem' }} />
        <div className="vertical_options_menu" style={{ width: '100%', position: 'relative' }}>

          {section3Array && [section3Array].map((headerColumn, headerColumnIndex) =>
            <div key={`${headerColumn?.title}option_division ${headerColumnIndex}`} className="vertical_grid">
              {headerColumn.map((section, sectionIndex) =>
              (<div className="grid_item">
                {section.type === "Link" && <LinkCard key={section.title} title={section.title} list={section.points} />}
              </div>))}
            </div>)}
          {showEdit && (
            <div
              className="edit_icon mwfbi_banner__edit "
              style={{ top: "-1rem", right: "-3rem" }}
              id="admin-edit"
              onClick={() => setShowSection3Modal(true)}
            >
              <svg>
                <use xlinkHref="/sprite.svg#icon-edit" />
              </svg>
            </div>
          )}
        </div>

      </div>
      <EditHeaderInfo
        handleYes={handleInfoSubmit}
        section1={section1}
        showModal={showSectionModal}
        setSection1={setSection1}
        setShowModal={setShowSectionModal}
      />
      <EditFirstSection
        sectionArray={section1Array}
        onSectionSave={onSectionSave}
        showSection1Modal={showSection1Modal}
        setShowSection1Modal={setShowSection1Modal}
      />

      <EditSecondSection
        pointList={section2Array}
        setPointList={setSection2Array}
        showSection1Modal={showSection2Modal}
        setShowSection1Modal={setShowSection2Modal}
        onEdit={(item, index) => {
          setEditPoint(item)
          setEditPointIndex(index)
        }}
        onSave={saveSection2}
        onDelete={(points) => {
          saveSection2(points)
        }}
        onAddPoint={() => {
          setShowSection2Modal(false)
          setShowSection2AddPointModal(true)
        }}
      />

      <EditPointsSection
        editPoint={editPoint}
        setEditPoint={setEditPoint}
        pointIdx={editPointIndex}
        showSection1Modal={showSection2PointModal}
        setShowSection1Modal={setShowSection2PointModal}
        onSave={onPointSave}
        sectionTitleEdit={sectionTitleEdit}
        onClose={() => {
          setEditPoint(null)
          setEditPointIndex(-1)
        }}
      />
      <EditPointAddSection
        showModal={showSection2AddPointModal}
        setShowModal={setShowSection2AddPointModal}
        points={section2Array}
        onPointSave={(point) => {
          let temp = [...section2Array]
          temp.push(point);
          setSection2Array(temp)
          setShowSection2AddPointModal(false)
          setShowSection2Modal(true)
          saveSection2(temp)
        }}
        onClose={() => {
          setShowSection2Modal(true)
          setShowSection2AddPointModal(false)
        }}
      />

      <EditThirdSection
        pointList={section3Array}
        setPointList={setSection3Array}
        showSection1Modal={showSection3Modal}
        close={() => {
          setEditPoint3(null)
          setEditPointIndex3(-1)
          setShowSection3Modal(false)
        }}
        onEdit={(item, index) => {
          setEditPoint3(item)
          setEditPointIndex3(index)
          setShowSection3Modal(false)
        }}
        onSave={saveSection3}
      />
      <EditPointsSection3
        editPoint={editPoint3}
        setEditPoint={setEditPoint3}
        showSection1Modal={showSection3PointModal}
        sectionTitleEdit={sectionTitleEdit3}
        setShowSection1Modal={setShowSection3PointModal}
        onSave={(point) => {
          if (editPointIndex3 > -1) {
            let temp = [...section3Array]
            temp[editPointIndex3] = point;
            setSection3Array(temp)
            saveSection3(temp)
          }
        }}
        onClose={() => {
          setEditPoint3(null)
          setEditPointIndex3(-1)
        }}
      />
    </Content>
  );
};

export default AgentLanding;
