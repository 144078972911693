import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../../../components/Inputs/Button";
import {
  ADMIN_USERS,
  PAYMENT_US_URL,
  PORTAL_PAYMENT_REDIRECTION,
} from "../../../redux/actions/ip";
import style from "./login.module.css";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { useDispatch, useSelector } from "react-redux";
import { azureLogin } from "../../../redux/actions/userActions";

const AgentLogin = ({ history }) => {
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const azureLoginState = useSelector((state) => state.azureLogin);
  const methods = useForm();
  const {
    handleSubmit,
    getValues,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => console.log(data);
  useEffect(() => {
    if (
      azureLoginState &&
      azureLoginState.userInfo &&
      azureLoginState.userInfo.accessToken &&
      history.location.pathname === "/login"
    ) {
      history.push("/");
    }
  }, [azureLoginState]);

  const handleAzureLoginSubmit = (e) => {
    e.preventDefault();
    instance
      .loginPopup(loginRequest)
      .then((res) => {
        if (res.account) {
          localStorage.setItem("az", JSON.stringify(res));
          dispatch(azureLogin(res));
          history.push("/mwfbi/agent-hub");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCustomerloginSubmit = () => {
    window.open("https://mwb2c.azurewebsites.net/");
  };

  return (
    <FormProvider {...methods}>
      <div
        className={style.container}
        style={{
          backgroundImage: "url(/images/header-img.jpg) ",
        }}
      >
        <div className={style.centerContainer}>
          <div className={style.topContainer}>
            {/* <div className={style.leftContainer}>
             
              <div className={style.input3}>
                <div className={style.buttons_vertical}>
                  <Button
                    label="login"
                    varient="red"
                    icon="/sprite.svg#icon-login"
                    style={{
                      height: "5rem",
                    }}
                    onClick={handleSubmit(onSubmit)}
                  />
                  <Button
                    label="sign up"
                    varient="red"
                    icon="/sprite.svg#icon-login"
                    style={{
                      height: "5rem",
                      marginTop:'4rem'
                    }}
                    onClick={() => {}}
                  />
                </div>
              </div>
            </div> */}

            <div className={style.rightContainer}>
              <div>
                <div className={style.header}>Login</div>
                <div className={style.desc}>
                  Access the MWFBI Hub with a single login access.
                </div>
                <div className={style.input1}>
                  <Button
                    label="Login"
                    varient="red"
                    icon="/sprite.svg#icon-login"
                    style={{
                      width: "100%",
                    }}
                    onClick={handleAzureLoginSubmit}
                  />
                  {/* <Button
                    label="Customer Login"
                    varient="red"
                    icon="/sprite.svg#icon-login"
                    style={{
                      width: "100%",
                      marginTop: '1rem'
                    }}
                    onClick={handleCustomerloginSubmit}
                  /> */}

                </div>
              </div>

            </div>
            <div className={style.line}></div>

            <div className={style.rightContainer}>
              <div>
                <div className={style.header}>Portal Payment</div>
                <div className={style.desc}>
                  Make a portal payment without logging into your account
                </div>
                <div className={style.input1}>
                  <Button
                    label="Quick Pay - OneInc"
                    varient="red"
                    icon="/sprite.svg#icon-login"
                    style={{
                      width: "100%",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = PORTAL_PAYMENT_REDIRECTION;
                    }}
                  />
                </div>
              </div>

            </div>

          </div>
        
          <div className={style.bottomContainer}>
            <div className={style.header}>
              If you need immediate assistance, please contact:
            </div>
            <div className={style.header3}>Customer Service Support</div>
            <div className={style.header2}>
              Phone: <span className={style.phone}>866-882-9871</span>
            </div>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default AgentLogin;
