import React, { useState, useEffect } from 'react';
import Banner from '../../../components/Banner/Banner';
import Content from '../../../components/Content/Content';
import Button from '../../../components/Inputs/Button';
import PaymentModal from '../HomeScreen/PaymentModal';

import { Link } from 'react-router-dom';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
	const [paymentModal, setPaymentModal] = useState(false);

	useEffect(() => {
		let acc = document.getElementsByClassName('accordion');

		acc[0].classList.toggle('active');
		acc[0].nextElementSibling.classList.toggle('show');

		for (let i = 0; i < acc.length; i++) {
			acc[i].onclick = function () {
				this.classList.toggle('active');
				this.nextElementSibling.classList.toggle('show');
			};
		}
	});
	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	return (
		<Content headerFooterMenu="mountain-west">
			<Banner
				backgroundImage="linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),url(/images/hat-and-rope.jpg)"
				title="Privacy Policy"
			// subTitle="Look for answers to your questions form the list below."
			/>
			<div className="quote2">
				{/* <div className="flex2">
					<h3 className="quote__text2">Start your retirement solution planning with your local agent. </h3>
				</div>
				<div className="header_buttons">
					<Link to="/mwfbi/start-a-quote" className="mw_button">
						Start a Quote
					</Link>
					<Link to="/mwfbi/find-an-agent" className="mw_button">
						find an agent
					</Link>
				</div> */}
			</div>

			<div className="faqs">
				<div className="faq">
					<div>
						<p className="accordion">
							Privacy Policy
							{/* <svg className="accordion_icon">
								<use xlinkHref="/sprite.svg#icon-plus" />
							</svg> */}
						</p>
						<div className="panel">
							<p className="phase2_subheading">
								Mountain West Farm Bureau Mutual Insurance Company (hereinafter "Mountain West") understands that privacy is an important issue for visitors to our website. We would like to inform you of our policies for securing, collecting, using, and sharing customer information. This information is contained in Mountain West's Privacy Policy below. Please read the Privacy Policy before using our website. Using this site means that you accept the Terms and Conditions found on our Home Page. Thank you for visiting our website.
							</p>
						</div>
						<PaymentModal setPaymentModal={setPaymentModal} paymentModal={paymentModal} />
						{/* <Button label="Make Payment" icon="/sprite.svg#icon-arrow_right" /> */}
					</div>
				</div>
				<div className="faq">

					<div>
						<p className="accordion">We Respect Your Privacy</p>
						<div className="panel">
							<p className="phase2_subheading">
								Our mission is to provide financial peace of mind for our customers with competitive, quality products and services. To fulfill this mission, each of you as our customers must know that your privacy is secure. We understand your concerns about guarding all personal information with which we are entrusted. This policy is our assurance to you that we have taken steps, and will continue to take steps, to safeguard that information.
							</p>
						</div>
					</div>
				</div>
				<div className="faq">

					<div>
						<p className="accordion">The Necessity of Collecting Information</p>
						<div className="panel">
							<p className="phase2_subheading">
								To adequately serve your insurance needs, it is necessary that we collect and maintain accurate personal information about you and your family. The information collected is used to determine your coverages, accurately rate your policy and complete comparison quotes to establish your premium as well as to bind coverage. In addition, it is used to continue servicing your insurance needs as well as evaluate and pay claims.
							</p>
							{/* <input type="button" value="make payment" className="FAQ_button" onSubmit={''} /> */}
						</div>
					</div>
				</div>
				<div className="faq">

					<div>
						<p className="accordion">Your Right To Review Information</p>

						<div className="panel">
							<p className="phase2_subheading">
								As a policyholder of Mountain West, and to the extent provided by law, you have the right to access and correct any information we have collected about you. You are also entitled to a record of any disclosures of health record information made by us. To exercise your rights in obtaining access to such information, please provide a written request, which must include proper identification, to the address listed below.
							</p>
						</div>
					</div>
				</div>
				<div className="faq">

					<div>
						<p className="accordion">Categories of Information We Share</p>

						<div className="panel">
							<p className="phase2_subheading">
								Mountain West limits the sharing of information with others when at all possible. However, there are circumstances in which information must be shared in order to provide you with the products and services you expect. Mountain West will share information as permitted or required by law.
							</p>
							<br />
							<p className="phase2_subheading">
								<b>.</b> To Authorized Personnel and Affiliates: to provide you with outstanding products and services;
							</p>
							<p className="phase2_subheading">
								<b>.</b> To Servicing Third-Parties: that are necessary to effect, administer or enforce your transactions with us, or in connection with serving your account; and
							</p>
							<p className="phase2_subheading">
								<b>.</b> To Others: credit bureaus, regulatory authorities, insurance support organizations and other third-parties.
							</p>
							<br />
							<p className="phase2_subheading">
								Personally identifiable health information is disclosed only as allowed by law or with your written authorization. Personally identifiable information about you will be disclosed to non-affiliated third parties upon proper notice and with your consent.
							</p>
							<p className="phase2_subheading">
								We may disclose customer information to persons or organizations inside or outside our company as permitted or required by law including companies with whom we have joint marketing agreements. These agreements allow us to provide a broader selection of insurance products for you.
							</p>
							<p className="phase2_subheading">
								Information obtained from a report prepared by an insurance-support organization may be retained by that organization and shared with others. We require any company with whom information is shared to adhere to our privacy standards and to use the information only for the limited purpose for which it was shared.
							</p>
						</div>
					</div>
				</div>
				<div className="faq">

					<div>
						<p className="accordion">Confidentiality and Security</p>

						<div className="panel">
							<p className="phase2_subheading">
								Mountain West respects your right to privacy and is committed to the protection of your personal information. All personal information is treated confidentially, with only those essential employees, agents, or third-party service providers having access. Their right to further disclose and use that information is limited by our employee conduct rules, applicable law, and non-disclosure agreements where appropriate. We maintain safeguards that comply with applicable law and regulations to guard your nonpublic personal information. Mountain West affords prospective and former customers the same protection as existing customers with respect to the use of personal information.
							</p>
							<p className="phase2_subheading">
								Mountain West does not sell your personal information. Neither do we allow outside organizations to use your information for marketing purposes.
							</p>
						</div>
					</div>
				</div>
				<div className="faq">

					<div>
						<p className="accordion">Information That May Be Collected</p>

						<div className="panel">
							<p className="phase2_subheading">
								Some of the information collected is nonpublic personal information including financial information. Information is collected from the following sources:
							</p>
							<p className="phase2_subheading">
								<b>• Applications:</b>
								<br />
								Information you provide on applications, supplemental applications, client service folders and interviews. It may include, but not be limited to, your name, address, family affiliations, social security number, former insurance history and financial history including current assets and income.
							</p>
							<p className="phase2_subheading">
								<b>• Service and Support:</b>
								<br />
								Information gathered during the life of your past and/or present policy(s) with Mountain West, including records maintained about your policy coverage, payment history or claims.
							</p>
							<p className="phase2_subheading">
								<b>• Consumer Report Agencies:</b>
								<br />
								Information gathered for underwriting purposes such as claim history, credit reports, previous carriers and related data.
							</p>
							<p className="phase2_subheading">
								<b>• Inspection Reports:</b>
								<br />
								Information gathered during inspections of your property.
							</p>
							<p className="phase2_subheading">
								<b>• Claim Investigation:</b>
								<br />
								Information gathered during the adjusting and investigation of any claims reported in which your Mountain West policy is involved.
							</p>
							<p className="phase2_subheading">
								<b>• Public Records:</b>
								<br />
								Information that we receive from public records including, but not limited to, motor vehicle and driving reports.
							</p>

						</div>
					</div>
				</div>
				<div className="faq">
					<div>
						<p className="accordion">Former Customers</p>

						<div className="panel">
							<p className="phase2_subheading">
								If you decide to terminate your customer relationship with Mountain West, we will continue to adhere to the privacy policies and practices described in this Privacy Policy.
							</p>
						</div>
					</div>
				</div>
				<div className="faq">
					<div>
						<p className="accordion">Your Files</p>

						<div className="panel">
							<p className="phase2_subheading">
								We strive to keep our records of your information completely accurate. If you see any error in our communications to you, please notify us immediately, and we will promptly address any inaccuracy that may have occurred.
							</p>
						</div>
					</div>
				</div>
				<div className="faq">
					<div>
						<p className="accordion">Mail inquiries to:</p>

						<div className="panel">
							<p className="phase2_subheading">
								Mountain West Farm Bureau Mutual Insurance Company Customer Privacy<br />%
								Corporate Counsel<br />931 Boulder Drive<br />Laramie, WY 82073<br />Telephone: 307-745-4835
							</p>
						</div>
					</div>
				</div>
			</div>
		</Content>
	);
};

export default PrivacyPolicy;
