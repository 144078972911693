import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../../../DarkModal/index'
import FormInputComponent from '../../../../../../FormInputComponent/FormInputComponent'
import styles from './EditForm.module.css'

import ImageOption from "../../../Forms/ThirdStepForm/PointAddForm/Forms/ImageOption";
import PdfOption from "../../../Forms/ThirdStepForm/PointAddForm/Forms/PdfOption";
const itemsPerPage = 10

const EditForm = ({
  setShowModal,
  step,
  showModal,
  handleYes,
  section2,
  currentSubSection,
  setSection2,
  discountListArr,
  setDiscountListArr,
  setStep2Selection,
  step1Selection,
  setStep1Selection,
  step2Selection
}) => {
  const [loading, setLoading] = useState(false)
  const [uploadImagePreview, setUploadImagePreview] = useState('')
  const [uploadImage, setUploadImage] = useState(null)

  const [title, setTitle] = useState({ value: currentSubSection.title, error: '' })
  const [pointTitle, setPointTitle] = useState({ value: '', error: '' })
  const [pointLink, setPointLink] = useState({ value: '', error: '' })
  const [pointList, setPointList] = useState([])

  const [editIndex, setEditIndex] = useState(-1)
  const [editData, setEditData] = useState({
    title: { value: '', error: '' },
    description: { value: '', error: '' },
  })


  useEffect(() => {
    setPointList(currentSubSection.points)
  }, [currentSubSection])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (title.value === '') {
      setTitle({ ...title, error: 'Please enter the title!' })
      return;
    }
    if (pointTitle.value === '') {
      setPointTitle({ ...pointTitle, error: 'Please enter the point title!' })
      return;
    }
    let temp = { ...currentSubSection }
    if (editIndex > -1) {
      temp.points[editIndex] = { title: pointTitle.value, link: pointLink.value }
    } else {
      temp.points.push({ title: pointTitle.value, link: pointLink.value })
    }
    temp.title = title.value
    setStep2Selection(temp)
    setPointTitle({ value: '', error: '' })
    setPointLink({ value: '', error: '' })
    setEditIndex(-1)
    setEditData(null)
  }


  const onEdit = (item, index) => {
    setEditData(item)
    setEditIndex(index)
    setPointTitle({ ...pointTitle, value: item.title })
    setPointLink({ ...pointLink, value: item.link })
    setUploadImagePreview(item.src)
  }


  const onDiscountDelete = (item, index) => {
    if (window.confirm('Are You Sure to Delete?')) {
      const temp = [...discountListArr]
      temp.splice(index, 1)
      setDiscountListArr(temp)
      handleYes(temp)
    }
  }
  return (
    <form style={{ width: '100%' }} onSubmit={handleYes}>
      <div className='modal__container-dark' style={{ marginBottom: '2rem' }}>
        <div style={{ width: '100%', padding: '0rem' }} className='modal__form-dark'>
          <div className='modal_inner_content'>
            <div className='modal_inner_content--left'>

              {currentSubSection.withImageTemplate && !currentSubSection.withPdfTemplate && <div>
                <ImageOption edit={true} sections={step2Selection} onSave={(obj) => {
                  let temp = { ...step2Selection };
                  temp.selectedItem = obj;
                  let temp1 = { ...step1Selection };
                  temp1.selectedItem.subSection[step2Selection.index] = obj;
                  setStep1Selection(temp1);
                  setStep2Selection(temp);
                  alert("Saved successfully")
                }}></ImageOption></div>}
              {currentSubSection.withPdfTemplate && <div>
                <PdfOption edit={true} sections={step2Selection} onSave={(obj) => {
                  let temp = { ...step2Selection };
                  temp.selectedItem = obj;
                  let temp1 = { ...step1Selection };
                  temp1.selectedItem.subSection[step2Selection.index] = obj;
                  setStep1Selection(temp1);
                  setStep2Selection(temp);
                  alert("Saved successfully")
                }}></PdfOption>
              </div>}
              {!currentSubSection.withImageTemplate && !currentSubSection.withPdfTemplate && <div>
                <div className={'inline-form-view' + " " + styles.mainTitle}>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '30%' }}
                    required={true}
                    value={title.value}
                    onChange={(e) => {
                      setTitle({ error: '', value: e.target.value });
                      let temp = { ...step2Selection };
                      temp.selectedItem.title = e.target.value;
                      setStep2Selection(temp);
                    }}
                    errorMessage={title.error}
                  />
                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Point Title:'
                    style={{ width: '30%' }}
                    required={true}
                    value={pointTitle.value}
                    onChange={(e) => {
                      setPointTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={pointTitle.error}
                  />
                </div>
                <div className='inline-form-view' style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'end' }}>
                  <FormInputComponent
                    heading='Point Link:'
                    style={{ width: '50%' }}
                    value={pointLink.value}
                    onChange={(e) => {
                      setPointLink({ error: '', value: e.target.value })
                    }}
                    errorMessage={pointLink.error}
                  />
                  <div style={{ display: 'flex', gap: '1rem' }}>
                    <button
                      className='modal__button-reset-dark'
                      type='button'
                      onClick={handleSubmit}>
                      Reset
                    </button>
                    <button
                      className='modal__button-dark'
                      type='button'
                      onClick={handleSubmit}>
                      {loading ? 'Saving..' : editIndex > -1 ? 'Save' : 'Add'}
                    </button>
                  </div>
                </div>
              </div>}
            </div>
          </div>
          <div className='modal_inner_content'>
            {!currentSubSection.withImageTemplate && !currentSubSection.withPdfTemplate && <div className='modal_form_container'>
              <table
                className='modal__form-table'
                cellSpacing='0'
                cellPadding='0'>
                <thead className='modal__form-sticky'>
                  <tr className='modal__form-tr'>
                    <th>No.</th>
                    <th>Title</th>
                    <th>Link</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {currentSubSection &&
                    currentSubSection.points &&
                    currentSubSection.points.map((item, i) => {
                      return (
                        <tr
                          style={{ height: '60px' }}
                          className='modal__form-tr2'
                          key={i}>
                          <td>{i + 1}</td>
                          <td>{item.title}</td>
                          <td>{item.link}</td>
                          <td
                            style={{ marginTop: '12px' }}
                            className='modal__form-buttons'>
                            <button
                              type='button'
                              className='modal__button-edit'
                              onClick={(e) => onEdit(item, i)}>
                              edit
                            </button>
                            <button
                              type='button'
                              className='modal__button-delete'
                              onClick={(item) => onDiscountDelete(item.point, i)}>
                              delete
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>}
          </div>
        </div>
        {/* <div className='modal_dark_buttons' style={{  paddingBottom: '1rem', marginLeft: '1.6rem' }}>
          <button
            type='button'
            className='modal__button-reset-dark'
            onClick={handleSubmit}>
            Save
          </button>
        </div> */}
      </div>
    </form>
  )
}

export default EditForm
