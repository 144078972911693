import axios from 'axios';
import { USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGIN_FAIL, USER_LOGOUT } from '../constants/userConstants';
import { BASE_URL } from './ip';

export const getContent = async (data) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		data = { ...data, client: "MWFBI" }
		return axios.post(`${BASE_URL}/cms/get-content-list`, data, config);

	} catch (err) {
		return Promise.reject(err);
	}
};
export const getPageContent = async (data) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		data = { ...data, client: "MWFBI" }
		return axios.post(`${BASE_URL}/cms/get-page-content-list`, data, config);

	} catch (err) {
		return Promise.reject(err);
	}
};
export const getPageContentItemList = async (data) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		data = { ...data, client: "MWFBI" }
		return axios.post(`${BASE_URL}/cms/get-page-content-item-list`, data, config);

	} catch (err) {
		return Promise.reject(err);
	}
};
export const imageUpload = async (image) => {
	try {
		const config = {
			headers: {
				// 'Content-Type': 'application/json'
			}
		};
		let formData = new FormData()
		formData.append("file", image);
		return axios.post(`${BASE_URL}/cms/upload-image`, formData, config);

	} catch (err) {
		return Promise.reject(err);
	}
};
export const addContent = async (data) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		data = { ...data, client: "MWFBI" }
		return axios.post(`${BASE_URL}/cms/add-new`, data, config);

	} catch (err) {
		return Promise.reject(err);
	}
};
export const addFormContent = async (data) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		data = { ...data, client: "MWFBI" }
		return axios.post(`${BASE_URL}/cms/form`, data, config);

	} catch (err) {
		return Promise.reject(err);
	}
};

export const updateFormContent = async (id, data) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		data = { ...data, client: "MWFBI" }
		return axios.patch(`${BASE_URL}/cms/form/${id}`, data, config);

	} catch (err) {
		return Promise.reject(err);
	}
};

export const getFormContent = async (data) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		return axios.get(`${BASE_URL}/cms/form?pageName=${data}&&client=${'MWFBI'}`, config);

	} catch (err) {
		return Promise.reject(err);
	}
};

export const deleteFormField = async (id) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		return axios.delete(`${BASE_URL}/cms/form/${id}`, config);

	} catch (err) {
		return Promise.reject(err);
	}
};

export const addContentItem = async (data) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		data = { ...data, client: "MWFBI" }
		return axios.post(`${BASE_URL}/cms/add-new-content-item`, data, config);

	} catch (err) {
		return Promise.reject(err);
	}
};
export const deleteContentItem = async (data) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		data = { ...data, client: "MWFBI" }
		return axios.post(`${BASE_URL}/cms/delete-content-item`, data, config);

	} catch (err) {
		return Promise.reject(err);
	}
};


export const addEmail = async (data) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		data = { ...data, client: "MWFBI" }
		return axios.post(`${BASE_URL}/cms/email`, data, config);

	} catch (err) {
		return Promise.reject(err);
	}
};

export const getEmail = async (page) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			params: {
				pageName: page,
				client:'MWFBI'
			}
		};
		
		return axios.get(`${BASE_URL}/cms/email`, config);

	} catch (err) {
		return Promise.reject(err);
	}
};

export const deleteEmail = async (id) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		return axios.delete(`${BASE_URL}/cms/email/${id}`, config);

	} catch (err) {
		return Promise.reject(err);
	}
};