import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'

const EditPageDetail = ({ setShowModal, showModal, handleYes, section2, setSection2, loading }) => {

  const [uploadImagePreview, setUploadImagePreview] = useState(null)
  const [uploadImage, setUploadImage] = useState(null)

  const onDrop = useCallback((acceptedFiles) => {
    encodeImageFileAsURL(acceptedFiles)
  }, [])
  const encodeImageFileAsURL = (files) => {
    var file = files[0]
    setUploadImage(file)
    var reader = new FileReader()
    reader.onloadend = function () {
      if (reader.result) {
        setUploadImagePreview(reader.result)
      }
    }
    reader.readAsDataURL(file)
  }
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop,
  })

  useEffect(() => {
    if (section2.image.value)
      setUploadImagePreview(section2.image.value)
  }, [])
  const submit = () => {
    let temp = { ...section2 };
    if (section2.title.value === '') {
      temp.title.error = 'Please enter title!'
      setSection2(temp)
      return
    } else {
      temp.title.error = ''
      setSection2(temp)
    }
    if (section2.description.value === '') {
      temp.description.error = 'Please enter description!'
      setSection2(temp)
      return
    } else {
      temp.description.error = ''
      setSection2(temp)
    }
    if (section2.link1.value === '') {
      temp.link1.error = 'Please enter label!'
      setSection2(temp)
      return
    } else {
      temp.link1.error = ''
      setSection2(temp)
    }
    if (section2.link1Link.value === '') {
      temp.link1Link.error = 'Please enter link!'
      setSection2(temp)
      return
    } else {
      temp.link1Link.error = ''
      setSection2(temp)
    }
    if (section2.link1.value === '') {
      temp.link2.error = 'Please enter label!'
      setSection2(temp)
      return
    } else {
      temp.link2.error = ''
      setSection2(temp)
    }
    if (section2.link2Link.value === '') {
      temp.link2Link.error = 'Please enter label!'
      setSection2(temp)
      return
    } else {
      temp.link2Link.error = ''
      setSection2(temp)
    }
    handleYes(temp, uploadImage)
  }
  return (
    <Modal show={showModal}>
      <form >
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Info</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => setShowModal(false)}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '90%' }}
                    required={true}
                    value={section2.title.value}
                    onChange={(e) => {
                      let temp = { ...section2 }
                      temp.title.value = e.target.value
                      setSection2(temp)
                    }}
                    errorMessage={section2.title.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Description:'
                    style={{ width: '90%' }}
                    type={'textarea'}
                    required={true}
                    height={'8rem'}
                    value={section2.description.value}
                    onChange={(e) => {
                      let temp = { ...section2 }
                      temp.description.value = e.target.value
                      setSection2(temp)
                    }}
                    errorMessage={section2.description.error}
                  />
                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Link 1 Label:'
                    style={{ width: '100%' }}
                    required={true}
                    value={section2.link1.value}
                    onChange={(e) => {
                      let temp = { ...section2 }
                      temp.link1.value = e.target.value
                      setSection2(temp)
                    }}
                    errorMessage={section2.link1.error}
                  />
                  <FormInputComponent
                    heading='Link 1:'
                    style={{ width: '100%' }}
                    required={true}
                    value={section2.link1Link.value}
                    onChange={(e) => {
                      let temp = { ...section2 }
                      temp.link1Link.value = e.target.value
                      setSection2(temp)
                    }}
                    errorMessage={section2.link1Link.error}
                  />

                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Link 2 Label:'
                    style={{ width: '100%' }}
                    required={true}
                    value={section2.link2.value}
                    onChange={(e) => {
                      let temp = { ...section2 }
                      temp.link2.value = e.target.value
                      setSection2(temp)
                    }}
                    errorMessage={section2.link2.error}
                  />
                  <FormInputComponent
                    heading='Link 2:'
                    style={{ width: '100%' }}
                    required={true}
                    value={section2.link2Link.value}
                    onChange={(e) => {
                      let temp = { ...section2 }
                      temp.link2Link.value = e.target.value
                      setSection2(temp)
                    }}
                    errorMessage={section2.link2Link.error}
                  />

                </div>
              </div>
              <div className='modal_inner_content--right'>
                <label>Please choose image to upload</label>
                <div className='upload_border' {...getRootProps()}>
                  <input {...getInputProps()} />
                  <label>Click or Drag a image to this area to upload</label>
                  <svg className='modal_upload__icon-dark'>
                    <use xlinkHref='/sprite.svg#icon-cloud' />
                  </svg>
                </div>
                <div className='upload_preview_modal'>
                  <img width={200} height={140} src={uploadImagePreview ? uploadImagePreview : section2.image.value}></img>
                </div>
              </div>
            </div>
          </div>

          <div className='modal_dark_buttons'>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={submit}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditPageDetail
