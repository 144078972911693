import React, { useState } from 'react';
import Modal from '../../../components/Modal';

const PaymentModal = ({ setPaymentModal, paymentModal, accountDetail, schedulePayment }) => {
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [currentStatus, setCurrentStatus] = useState([]);

	return (
		<Modal show={paymentModal}>
			<div className='modal__container'>
				<div className='modal_heading__container'>
					<h1 className='modal_heading__text'>Account Details</h1>
					<button className='modal__heading--button' onClick={() => setPaymentModal(false)}>
						<svg className='modal_heading__icon'>
							<use xlinkHref='/sprite.svg#icon-close' />
						</svg>
					</button>
				</div>
				<div className='modal__form'>
					<label htmlFor='accno' className='modal__form--label'>
						Policy Holder
					</label>
					<input
						type='text'
						id='accno'
						value={accountDetail?.policyHolderName}
						disabled
						placeholder='Policy Holder'
						className='modal__form--input'
					/>
				</div>
				<div className='modal__form'>
					<label htmlFor='accno1' className='modal__form--label'>
						Billing Address
					</label>
					<input
						type='text'
						id='accno1'
						value={accountDetail?.billingAddressStreet}
						disabled
						placeholder='Billing Address'
						className='modal__form--input'
					/>
				</div>

				<div className='modal__form'>
					<label htmlFor='accno2' className='modal__form--label'>
						Billing Zip
					</label>
					<input
						type='text'
						id='accno2'
						value={accountDetail?.billingZip}
						disabled
						placeholder='Billing Zip'
						className='modal__form--input'
					/>
				</div>

				<div className='modal__form'>
					<label htmlFor='accno3' className='modal__form--label'>
						Minimum Amount
					</label>
					<input
						type='text'
						id='accno3'
						value={accountDetail?.minAmountDue}
						disabled
						placeholder='Min Amount'
						className='modal__form--input'
					/>
				</div>

				<div className='modal__form'>
					<label htmlFor='accno4' className='modal__form--label'>
						Reference Number
					</label>
					<input
						type='text'
						id='accno4'
						value={accountDetail?.referenceNumber}
						disabled
						placeholder='Reference Number'
						className='modal__form--input'
					/>
				</div>

				{loading ? (
					<button className='modal__button'>Please wait...</button>
				) : (
					<button className='modal__button' onClick={() => {
						setLoading(true)
						schedulePayment(accountDetail).then((status) => {
							setLoading(false);
							if (!status.status) {
								// setErrorMessage('Your account is not in Billing Center.');
								setErrorMessage(status.message);
								setTimeout(() => {
									setErrorMessage('');
								}, 5000);
							}
						}, err => {
							setLoading(false);
						})
					}}>
						Schedule Payment
					</button>
				)}
				<p className="modal_custom_error">{errorMessage}</p>
				{currentStatus && currentStatus.length > 0 && currentStatus.map((status, i) => <p key={i}>{status.status}</p>)}
			</div>
		</Modal >
	);
};

export default PaymentModal;
