export const BASE_URL = process.env.REACT_APP_BASE_URL
export const PAYMENT_BASE_URL = process.env.REACT_APP_PAYMENT_BASE_URL

// export const ADMIN_USERS = process.env.REACT_APP_ADMIN_USERS
// export const BASE_URL = 'http://159.65.37.101/api'
// export const BASE_URL = 'https://mwfwebstaging.azurewebsites.net/'
// export const PAYMENT_BASE_URL = 'http://159.65.37.101'

// export const BASE_URL = 'https://mwfwebstaging.azurewebsites.net/api'
// export const PAYMENT_BASE_URL = 'https://mwfwebstaging.azurewebsites.net'

// export const BASE_URL = 'https://mwfweb.azurewebsites.net/api'
// export const PAYMENT_BASE_URL = 'https://mwfweb.azurewebsites.net'

// export const ADMIN_USERS = ['piyoosh@mwfbi.com', 'piyoosh-a@mwfbi.com', 'ewood@mwfbi.com', 'jbartholom@mwfbi.com', 'asopr@mwfbi.com', 'mflores@mwfbi.com', 'spena@mwfbi.com']
export const ADMIN_USERS = process.env.REACT_APP_ADMIN_USERS ? process.env.REACT_APP_ADMIN_USERS.split(",") : []
// export const BASE_URL = process.env.REACT_APP_BASE_URL
// export const PAYMENT_BASE_URL = process.env.REACT_APP_PAYMENT_BASE_URL
export const PAYMENT_US_URL = process.env.REACT_APP_PAYMENT_US_URL //Quick Pay
export const PORTAL_PAYMENT_REDIRECTION = process.env.REACT_APP_PORTAL_PAYMENT_REDIRECTION //OneInc Wallet, 