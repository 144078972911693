import React from "react";
import styles from "./InfoCard.module.css";
import { useSelector } from "react-redux";
import CryptoJS from 'crypto-js';

const InfoCard = ({ key, title, imageUrl, cardLink, token }) => {
  const azureLogin = useSelector(state => state.azureLogin);
  const generateLoginToken = () => {
    // Mountain West Farm Bureau
    // Frontline Cyber Solutions
    const token = CryptoJS.AES.encrypt(
      JSON.stringify({ clientName: 'Mountain West Farm Bureau', email: azureLogin && azureLogin.userInfo && azureLogin.userInfo.account ? azureLogin.userInfo.account.username : '' }),
      process.env.REACT_APP_SECURE_KEY
    ).toString();

    return token;
  };
  return (
    <div key={key} className={styles.icon_division} onClick={() => {
      cardLink && window.open(
        token ? `${cardLink}?token=${generateLoginToken()}` : cardLink
      )
    }}>
      <div className={styles.infoCard}>
        {imageUrl ? <img
          src={imageUrl}
          alt={"Loading.."}
          className={styles.icon}
        /> : <div>Loading..</div>}
        <span>{title}</span>
      </div>
    </div>
  );
};

export default InfoCard;
