import React, { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Modal from "../../../DarkModal/index";
import Stepper from "../../Stepper/Stepper";
import styles from "./EditModal.module.css";
import FirstStepForm from "./Forms/FristStepForm/FristStepForm";
import SecondStepForm from "./Forms/SecondStepForm/SecondStepForm";
import ThirdStepForm from "./Forms/ThirdStepForm/ThirdStepForm";

const EditModal = ({ showModal, setShowModal, sectionArray, setSectionArray, onSectionSave }) => {
  const methods = useForm();
  const [editSectionTitle, setEditSectionTitle] = useState("Section");
  const [section, setSection] = useState({
    title: "",
    error: ""
  });
  const [currentSection, setCurrentSection] = useState({
    title: "",
    error: ""
  });
  const [subSection, setSubSection] = useState({
    title: "",
    error: ""
  });
  const [currentSubSection, setCurrentSubSection] = useState();
  const [sectionStatus, setSectionStatus] = useState('');
  const [step, setStep] = useState(1);

  const [step1Selection, setStep1Selection] = useState(null);
  const [step2Selection, setStep2Selection] = useState(null);
  const [step3Selection, setStep3Selection] = useState(null);

  const [step1ErrorMessage, setStep1ErrorMessage] = useState('');
  const [step2ErrorMessage, setStep2ErrorMessage] = useState('');

  const closeModal = () => {
    setShowModal(false);
    setStep(1);
    setStep1Selection(null)
    setStep2Selection(null)
    setStep3Selection(null)
    setCurrentSubSection(null)
    setCurrentSection(null)
    setStep(1)
    setSectionStatus('')
  };

  const onSectionElementSave = () => {
    let temp = [...sectionArray]
    if (step1Selection && step1Selection.index > -1) {
      temp[step1Selection.index] = step1Selection.selectedItem;
    }
    setSectionArray(temp)
    onSectionSave(temp)
  }
  const onFinalSubmit = () => {
    closeModal()
    let temp = [...sectionArray]
    if (step1Selection && step1Selection.index > -1) {
      temp[step1Selection.index] = step1Selection.selectedItem;
    }
    if (step2Selection && step2Selection.index > -1) {
      temp[step1Selection.index].subSection[step2Selection.index] = step2Selection.selectedItem;
    }
    setSectionArray(temp)
    onSectionSave(temp)
    setStep1Selection(null)
    setStep2Selection(null)
  }
  const nextStep = useCallback(() => {
    if (step === 1) {
      if (step1Selection && step1Selection.selectedItem && step1Selection.index > -1) {
        setStep((count) => count + 1)
        setStep1ErrorMessage('')
      } else
        setStep1ErrorMessage('Please select section')
    } else if (step === 2) {

      // else
      if (step2Selection && step2Selection.selectedItem && step2Selection.index > -1) {
        setStep((count) => count + 1)
        setStep2ErrorMessage('')
      } else {
        setStep2ErrorMessage('Please select section')

      }
    }
  }, [step,step1Selection,step2Selection])

  const prevStep = useCallback(() => {
    if (step < 3) {
      setStep((count) => count - 1)
      setCurrentSection({ ...currentSection, title: '' })
    }
    if (step === 2) {
      setStep(step - 1)
      setSubSection({ ...subSection, error: '' })
      console.log('anil')
    }
    if (step === 3) {
      if (sectionStatus === 'add') {
        setSectionStatus('')
        setStep(step - 1)
      } else {
        setSubSection({ ...subSection, title: '', error: '' })
        setStep(step - 1)
      }
    }
  }, [currentSection, sectionStatus, step, subSection])

  const getForm = useCallback(() => {
    switch (step) {
      case 1:
        return (
          <FirstStepForm
            step={step}
            sectionArray={sectionArray}
            setSectionArray={setSectionArray}
            onSectionSave={onSectionSave}
            setStep1ErrorMessage={setStep1ErrorMessage}
          />
        )
      case 2:
        return (
          <SecondStepForm
            step2ErrorMessage={step2ErrorMessage}
            step={step}
            sectionArray={sectionArray}
            setSectionArray={setSectionArray}
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            setCurrentSubSection={setCurrentSubSection}
            subSection={subSection}
            setSectionStatus={setSectionStatus}
            setStep={setStep}
            setSubSection={setSubSection}

            step1Selection={step1Selection}
            setStep1Selection={setStep1Selection}

            step2Selection={step2Selection}
            setStep2Selection={setStep2Selection}
          />
        )
      case 3:
        return (
          <ThirdStepForm
            step={step}
            sectionArray={sectionArray}
            setSectionArray={setSectionArray}
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            sectionStatus={sectionStatus}
            currentSubSection={currentSubSection}
            setSectionStatus={setSectionStatus}

            setStep={setStep}
            step1Selection={step1Selection}
            setStep1Selection={setStep1Selection}
            step2Selection={step2Selection}
            setStep2Selection={setStep2Selection}
            step3Selection={step3Selection}
            setStep3Selection={setStep3Selection}
            onSectionSave={onFinalSubmit}
            onSectionElementSave={onSectionElementSave}
          />
        )
      default:
        break
    }
  }, [currentSection, currentSubSection, sectionArray, sectionStatus, setSectionArray, step, subSection])

  return (
    <Modal show={showModal}>
      <form>
        <div className="modal__container-dark">
          <div className="modal_heading__container-dark">
            <h1 className="modal_heading__text-dark">
              Edit {editSectionTitle}
            </h1>
            <button
              className="modal__heading--button-dark"
              onClick={() => closeModal()}
            >
              <svg className="modal_heading__icon-dark">
                <use xlinkHref="/sprite.svg#icon-cross" />
              </svg>
            </button>
          </div>
          <div className={"modal__form-dark" + " " + styles.modalContainer}>
            <Stepper step={step} />
            <div className="modal_inner_content">
              {getForm()}
            </div>
            <div className={styles.buttonContainer}>
              {step > 1 && <button
                type="button"
                className="modal__button-reset-dark"
                onClick={prevStep}
              >
                Back
              </button>}
              {step < 3 && <button
                type="button"
                className="modal__button-dark"
                onClick={nextStep}
              >
                Next
              </button>}
              {step === 3 && sectionStatus === '' && <button
                type="button"
                className="modal__button-dark"
                onClick={onFinalSubmit}
              >
                Submit
              </button>}
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EditModal;
