import React from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

// import './NewMenu360.css';

const NewMenu360 = () => {
	return (
		<div className='megaMenu'>
			<div className='menu'>
				<div className='left__menu'>
					<img src='/images/360logo.png' alt='Logo' className='logo' />
					<ul className='exo-menu'>
						<li>
							<NavLink exact to='/' activeClassName='navigation__active' className='exo-menu__head'>
								Home
							</NavLink>
						</li>

						<li className='mega-drop-down'>
							<NavLink exact to='#' activeClassName='navigation__active' className='exo-menu__head'>
								Insurance
							</NavLink>
							<div className='mega-menu'>
								<div className='row'>
									<div className='mega-menu__item'>
										<h4 className='mega-title'>Auto</h4>
										<ul className='description'>
											<li>
												<Link to='/app-stats' className='mega-menu__link'>
													Auto Coverage
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
											<li>
												<Link to='/app-version' className='mega-menu__link'>
													Roadside Assistance
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
											<li>
												<Link to='/app-notification' className='mega-menu__link'>
													Auto Discounts
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
											<li>
												<Link to='/app-notification' className='mega-menu__link'>
													Premier Auto
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
											<li>
												<Link to='/app-notification' className='mega-menu__link'>
													Special Equipments
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
											<li>
												<Link to='/app-notification' className='mega-menu__link'>
													Teen Drivers
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
										</ul>
									</div>
									<div className='mega-menu__item'>
										<h4 className='mega-title'>Home</h4>
										<ul className='description'>
											<li>
												<Link to='/user' className='mega-menu__link'>
													Home Coverage
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
											<li>
												<Link to='/add-new-content' className='mega-menu__link'>
													Country Home
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
											<li>
												<Link to='/add-new-category' className='mega-menu__link'>
													Renters Insurance
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
											<li>
												<Link to='/add-new-category' className='mega-menu__link'>
													Home Discounts
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
										</ul>
									</div>
									<div className='mega-menu__item'>
										<h4 className='mega-title'>Fram & Ranch</h4>
										<ul className='description'>
											<li>
												<Link to='/users' className='mega-menu__link'>
													F&R Coverage
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
											<li>
												<Link to='/all-categories' className='mega-menu__link'>
													Optional Coverages
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
											<li>
												<Link to='/add-new-category' className='mega-menu__link'>
													F&R Discounts
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
										</ul>
									</div>
									<div className='mega-menu__item'>
										<h4 className='mega-title'>Business</h4>
										<ul className='description'>
											<li>
												<Link to='/analysis/audio' className='mega-menu__link'>
													Business Coverage
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
											<li>
												<Link to='/analysis/video' className='mega-menu__link'>
													Optional Coverages
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
											<li>
												<Link to='/analysis/read' className='mega-menu__link'>
													Business Discounts
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
										</ul>
									</div>
									<div className='mega-menu__item'>
										<h4 className='mega-title'>Life</h4>
										<ul className='description'>
											<li>
												<Link to='/analysis/audio' className='mega-menu__link'>
													What is it?
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
											<li>
												<Link to='/analysis/video' className='mega-menu__link'>
													Coverage Options
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
											<li>
												<Link to='/analysis/read' className='mega-menu__link'>
													Getting Started
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
										</ul>
									</div>
									<div className='mega-menu__item'>
										<h4 className='mega-title'>Annuties</h4>
										<ul className='description'>
											<li>
												<Link to='/analysis/audio' className='mega-menu__link'>
													Annuity Types
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
										</ul>
									</div>
									<div className='mega-menu__item'>
										<h4 className='mega-title'>Other</h4>
										<ul className='description'>
											<li>
												<Link to='/analysis/audio' className='mega-menu__link'>
													Financial Services
													<span>Lorem ipsum dolor sit amet.</span>
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</li>

						<li>
							<NavLink exact to='#' activeClassName='navigation__active' className='exo-menu__head'>
								Find an agent
							</NavLink>
						</li>
						<li>
							<NavLink exact to='#' activeClassName='navigation__active' className='exo-menu__head'>
								Start a Quote
							</NavLink>
						</li>
						<li>
							<NavLink exact to='#' activeClassName='navigation__active' className='exo-menu__head'>
								Claims
							</NavLink>
						</li>

						<li className='drop-down'>
							<NavLink to='#' activeClassName='navigation__active'>
								About Us
							</NavLink>
							<ul className='drop-down-ul fadeInAnimation'>
								{/* <li className='flyout-right'>
									<Link to='/mwfbi/about/our-community'>Our Community</Link>
									<ul className='fadeInAnimation'>
										<li>
											<a href='#'>link 1</a>
										</li>
										<li>
											<a href='#'>link 1</a>
										</li>
										<li>
											<a href='#'>link 1</a>
										</li>
									</ul>
								</li>

								<li className='flyout-left'>
									<a href='#'>Flyout Left</a>
									<ul className='animated fadeIn'>
										<li>
											<a href='#'>link 1</a>
										</li>
										<li>
											<a href='#'>link 1</a>
										</li>
										<li>
											<a href='#'>link 1</a>
										</li>
									</ul>
								</li> */}

								<li>
									<Link to='#'>Our Community</Link>
								</li>
								<li>
									<Link to='#'>Farm Bureau Federation</Link>
								</li>
								<li>
									<Link to='#'>Careers</Link>
								</li>
								<li>
									<Link to='#'>Learning Center</Link>
								</li>
							</ul>
						</li>
						<li>
							<NavLink exact to='#' activeClassName='navigation__active' className='exo-menu__head'>
								FAQs
							</NavLink>
						</li>
					</ul>
				</div>
				<ul className='menu__right'>
					<li>
						<NavLink exact to='#' activeClassName='navigation__active' className='navigation__link2'>
							Pay Bill
						</NavLink>
					</li>
					<li>
						<NavLink exact to='/login' activeClassName='navigation__active' className='navigation__link'>
							Login
						</NavLink>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default NewMenu360;
