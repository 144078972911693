import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PaymentModal from '../../screens/MWFBI/HomeScreen/PaymentModal';
import AccountModal from '../../screens/MWFBI/HomeScreen/AccountDetail';
import BillingModal from '../../screens/MWFBI/HomeScreen/BillingDetails';
import AgentMessageAlertModal from '../../screens/MWFBI/HomeScreen/AgentMessageAlert';
import axios from 'axios';

import './NewMenu.css';
import { azureLogout } from '../../redux/actions/userActions';
import { PAYMENT_BASE_URL, PAYMENT_US_URL, PORTAL_PAYMENT_REDIRECTION } from '../../redux/actions/ip';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from "../../authConfig";
import LoginTooltip from './LoginTooltip';
import { useHistory } from 'react-router-dom';
import { getBlogSetting } from '../../redux/actions/blogListAction';

const NewMenu = ({ }) => {
	const dispatch = useDispatch();
	const history = useHistory()
	const [userName, setUserName] = useState('');
	const [showTooltip, setShowTooltip] = useState(false);
	const [checked, setChecked] = useState('checked1');
	const [paymentModal, setPaymentModal] = useState(false);

	const { instance } = useMsal();
	const userLogin = useSelector((state) => state.userLogin);
	const azureLogin = useSelector((state) => state.azureLogin);
	const [accountModal, setAccountModal] = useState(false);
	const [billingModal, setBillingModal] = useState(false);
	const [paymentMessageModal, setPaymentMessageModal] = useState(false);
	const [accountDetails, setAccountDetails] = useState(null);
	const [paymentMethod, setPaymentMethod] = useState('quick');
	const [alertMessage, setAlertMessage] = useState(null);
	const [blogTitle, setBlogTitle] = useState("Learning Center");

	const toggleButton = () => {
		setShowTooltip(false)
		const exoMenu = document.querySelector('.exo-menu');
		exoMenu.classList.toggle('display');
	};
	const toggleButton1 = () => {
		const exoMenu = document.querySelector('.exo-menu');
		exoMenu.classList.toggle('display');
	};
	const logout = (e) => {
		e.preventDefault()
		instance.logoutPopup(loginRequest)
			.then(res => {
				history.push("/")
				dispatch(azureLogout())
			})
			.catch(e => {
				history.push('/');
				console.log(e);
			});
	}
	useEffect(
		() => {
			if (
				userLogin &&
				userLogin.userInfo &&
				userLogin.userInfo.status &&
				userLogin.userInfo.result &&
				userLogin.userInfo.result.accessToken
			) {
				setUserName(userLogin.userInfo.result.username);
			}

		},
		[userLogin]
	);
	useEffect(() => {
		getBlogSetting("BlogSetting").then(res => {
			if (res.data.status) {
				setBlogTitle(res.data.result?.title?.value)
			}
		}).catch(exp => { })
	}, [])
	const handleTooltip = () => {
		setShowTooltip((prevState) => !prevState)
	}

	useEffect(
		() => {
			// console.log(azureLogin);
			if (
				azureLogin &&
				azureLogin.userInfo &&
				azureLogin.userInfo.account
			) {
				setUserName(azureLogin.userInfo.account.name);
				// if (history)
				// 	history.push('/');
			}
		},
		[azureLogin, dispatch]
	);
	useEffect(
		() => {
			// console.log('Account', accountDetails);
			if (accountDetails) {
				setAccountModal(false)
				setBillingModal(true)
			}
		},
		[accountDetails]
	);
	const schedulePayment = async (data, callback) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json'
				}
			};
			const accountDetail = await axios.post(`${PAYMENT_BASE_URL}/auth/get-token/${data.referenceNumber}/${data.policyHolderName}/${data.minAmountDue}`, { data1: "hi", data }, config);
			// console.log('==>>', accountDetail.data);
			if (accountDetail.data.status) {
				let container = window.$('#portalOneContainer');
				//instantiate modal
				container.portalOne();
				let portalOne = container.data('portalOne');
				portalOne.run({
					sessionId: accountDetail.data.sessionKey,
					accessTokenId: accountDetail.data.accessToken,
					displayMode: 'Modal',
					allowClosing: 'true'
				});
				container
					.addEventListener("portalOne.scheduledPaymentCreated", function () {
						console.log(new Date() + ' portalOne.scheduledPaymentCreated');
					});
				container
					.addEventListener("portalOne.scheduledPaymentCanceled", function () {
						console.log(new Date() + ' portalOne.scheduledPaymentCanceled');
					});
				// callback(true)
				return Promise.resolve(accountDetail.data)
			} else {
				// callback(false)
				return Promise.resolve(accountDetail.data)
			}
		} catch (error) {

			return Promise.reject(error)

		}
	}
	const quickPay = async (data) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json'
				}
			};
			const accountDetail = await axios.post(`${PAYMENT_BASE_URL}/auth/quick-pay-token/${data}`, { data1: "hi", accountNumber: data }, config);
			if (accountDetail.data.status) {
				let container = window.$('#portalOneContainer');
				container.portalOne();
				let portalOne = container.data('portalOne');
				portalOne.run({
					sessionId: accountDetail.data.sessionKey,
					accessTokenId: accountDetail.data.accessToken,
					displayMode: 'Modal',
					allowClosing: 'true'
				});
				// setAlertMessage("Please contact your agent.")
				// setPaymentMessageModal(true)
				// callback(true)
				return Promise.resolve(accountDetail.data)
			} else {
				// callback(false)
				if (accountDetail.data.redirect) {
					const win = window.open(PAYMENT_US_URL, '_blank');
					if (win != null) {
						win.focus();
					}
					return Promise.resolve({ status: true })
				} else
					return Promise.resolve(accountDetail.data)
			}
		} catch (error) {

			return Promise.reject(error)

		}
	}

	useEffect(() => {
		let subMenuLink = document.getElementsByClassName('exo-menu__head');

		if (subMenuLink) {
			for (let i = 0; i < subMenuLink.length; i++) {
				if (subMenuLink[i].parentElement.className === 'mega-drop-down') {
					subMenuLink[i].href = 'javascript:void(1)';

					subMenuLink[i].addEventListener('click', function (e) {
						e.preventDefault();
						this.classList.toggle('dropdown-visible');
					});
					// console.log(subMenuLink[i].nextSibling, document.getElementsByClassName('mega-menu')[0]);
				}
			}
		}
	}, []);

	useEffect(() => {
		let dropDown = document.getElementsByClassName('drop-down_link');

		if (dropDown) {
			for (let i = 0; i < dropDown.length; i++) {
				if (dropDown[i].parentElement.className === 'drop-down') {
					dropDown[i].href = 'javascript:void(0)';

					dropDown[i].addEventListener('click', function (e) {
						e.preventDefault();
						this.classList.toggle('dropdown-visible');
					});
					// console.log(dropDown[i].nextSibling, document.getElementsByClassName('mega-menu')[0]);
				}
			}
		}
	}, []);

	return (
		<div className="megaMenu">
			<div className="menu">
				<div className="left__menu">
					<Link to="/" className="Logo_container">
						<img src="/images/logo2.png" alt="Logo" className="logo" />
					</Link>
					<ul className="exo-menu">
						{/* <li className="mega-drop-down"> */}
						<li onClick={() => setShowTooltip(false)} onMouseOver={() => setShowTooltip(false)} className="drop-down">
							<NavLink
								to="/mwfbi/auto-insurance"
								className="drop-down_link"
								activeClassName="navigation__active"
							>
								Insurance
							</NavLink>
							<ul className="drop-down-ul fadeInAnimation">
								<li>
									<Link to="/mwfbi/auto-insurance">Auto</Link>
								</li>
								<li>
									<Link to="/mwfbi/home-owners-insurance">Home</Link>
								</li>
								<li>
									<Link to="/mwfbi/farm-&-ranch-insurance">Farm & Ranch</Link>
								</li>
								<li>
									<Link to="/mwfbi/business-insurance">Business</Link>
								</li>
								<li>
									<Link to="/mwfbi/life-insurance">Life Insurance</Link>
								</li>
								<li>
									<Link to="/mwfbi/annuities">Annuites</Link>
								</li>
								<li>
									<Link to="/mwfbi/other-offerings">Other</Link>
								</li>
							</ul>
						</li>

						<li onClick={() => setShowTooltip(false)} onMouseOver={() => setShowTooltip(false)}>
							<NavLink
								exact
								to="/mwfbi/find-an-agent"
								activeClassName="navigation__active"
								className="exo-menu__head"
							>
								Find an Agent
							</NavLink>
						</li>
						<li onMouseOver={() => setShowTooltip(false)}>
							<NavLink
								exact
								to="/mwfbi/how-to-claim-file"
								activeClassName="navigation__active"
								className="exo-menu__head"
							>
								Claims
							</NavLink>
						</li>
						<li className="drop-down" onClick={() => setShowTooltip(false)} onMouseOver={() => setShowTooltip(false)}>
							<NavLink
								to="/mwfbi/about-us"
								className="drop-down_link"
								activeClassName="navigation__active"
							>
								About Us
							</NavLink>
							<ul className="drop-down-ul fadeInAnimation">
								{/* <li className='flyout-right'>
									<Link to='/mwfbi/about/our-community'>Our Community</Link>
									<ul className='fadeInAnimation'>
										<li>
											<a href='#'>link 1</a>
										</li>
										<li>
											<a href='#'>link 1</a>
										</li>
										<li>
											<a href='#'>link 1</a>
										</li>
									</ul>
								</li>

								<li className='flyout-left'>
									<a href='#'>Flyout Left</a>
									<ul className='animated fadeIn'>
										<li>
											<a href='#'>link 1</a>
										</li>
										<li>
											<a href='#'>link 1</a>
										</li>
										<li>
											<a href='#'>link 1</a>
										</li>
									</ul>
								</li> */}
								<li>
									<Link to="/mwfbi/about-us">About Us</Link>
								</li>
								<li>
									<Link to="/mwfbi/about/our-community">Our Community</Link>
								</li>
								<li>
									<Link to="/mwfbi/about/farm-bureau-federation">Farm Bureau Federation</Link>
								</li>
								<li>
									<Link to="/mwfbi/about/careers">Careers</Link>
								</li>
								<li>
									<Link to="/mwfbi/about/blog">{blogTitle}</Link>
								</li>
							</ul>
						</li>
						<li onClick={() => setShowTooltip(false)} onMouseOver={() => setShowTooltip(false)}>
							<NavLink
								exact
								to="/mwfbi/faq"
								activeClassName="navigation__active"
								className="exo-menu__head"
							>
								FAQ
							</NavLink>
						</li>
						<li onClick={() => setShowTooltip(false)} onMouseOver={() => setShowTooltip(false)}>
							<NavLink
								exact
								to="/mwfbi/start-a-quote"
								activeClassName="navigation__active"
								className="exo-menu__head"
							>
								Start a Quote
							</NavLink>
						</li>
						{azureLogin &&
							azureLogin.userInfo &&
							azureLogin.userInfo.account ?
							<li className="drop-down">
								<NavLink
									to="#"
									className="drop-down_link"
									activeClassName="navigation__active"
								>
									Payment
								</NavLink>
								<ul className="drop-down-ul fadeInAnimation" style={{ textAlign: 'left' }}>

									<li>

										<Link to="#" onClick={() => {
											setPaymentMethod('schedule')
											setAccountModal(true)
										}}>Get Account</Link>

									</li>
									{/* <li>
										<a rel="noreferrer" href={PAYMENT_US_URL}
											target={'_blank'}>Quick Pay – Paymentus</a>
										
									</li> */}
									<li>
										<Link to="#" onClick={() => {
											// setPaymentMessageModal(true)
											// setAlertMessage("Please contact your agent")
											setPaymentMethod('quick')
											setAccountModal(true)
										}}>Quick Pay – OneInc</Link>
									</li>
									<li>
										<a rel="noreferrer" href={PORTAL_PAYMENT_REDIRECTION}
											target={'_blank'}>OneInc Wallet</a>
									</li>

								</ul>
							</li> :
							<li className="drop-down" style={{ textAlign: 'left', }} onClick={() => {
								handleTooltip();
								toggleButton1()
							}}>
								<NavLink
									to="#"
									className="drop-down_link"
									activeClassName="navigation__active"
								>
									Login/Payment
								</NavLink>

								<ul className="drop-down-ul fadeInAnimation" style={{ textAlign: 'left' }}>

									{/* <li>
										{azureLogin &&
											azureLogin.userInfo &&
											azureLogin.userInfo.account ?
											<Link to="#" onClick={() => {
												setPaymentMethod('schedule')
												setAccountModal(true)
											}}>Get Account</Link>
											:

											<Link to="/login" >Agent Login</Link>
										}
									</li> */}
									{/* <li>
										<a rel="noreferrer" href={PAYMENT_US_URL}
											target={'_blank'}>Quick Pay – Paymentus</a>
									
									</li> */}
									{/* <li>
										<Link to="#" onClick={() => {
											setPaymentMessageModal(true)
											setAlertMessage("Please contact your agent")
										}}>Quick Pay – OneInc</Link>
									</li> */}
									{/* <li>
										<a rel="noreferrer" href={PORTAL_PAYMENT_REDIRECTION}
											target={'_blank'}>OneInc Wallet</a>
									</li> */}

								</ul>
							</li>}
						{azureLogin &&
							azureLogin.userInfo &&
							azureLogin.userInfo.account && <li onClick={() => setShowTooltip(false)} onMouseOver={() => setShowTooltip(false)}>
								<NavLink
									exact
									to="/mwfbi/agent-hub"
									activeClassName="navigation__active"
									className="exo-menu__head"
								>
									MWFBI Hub
								</NavLink>
							</li>}
						<div className='tooltipViewNaviagtion'>
							{showTooltip && <LoginTooltip setShowTooltip={setShowTooltip} onClick={() => setShowTooltip(true)} />}
						</div>
						{/* <li>
							<a rel="noreferrer" activeClassName="navigation__active"
								className="navigation__link2" href={PAYMENT_US_URL}
								target={'_blank'}>Quick Pay</a>
						</li>  */}
						{azureLogin &&
							azureLogin.userInfo &&
							azureLogin.userInfo.account && <li><a href='target' className='logout_css'>{userName}</a></li>}
						{azureLogin &&
							azureLogin.userInfo &&
							azureLogin.userInfo.account && <li onClick={logout}><a href='target' className='logout_css'>Logout</a></li>}

						<div className="exo-menu__buttons_mobile">
							{/* <li>
								<a
									href={PAYMENT_US_URL}
									activeClassName="navigation__active"
									className="exo-menu__buttons_mobile--btn"
									style={{ textDecoration: 'none' }}
								>
									Quick Pay
								</a>
							</li> */}
							<PaymentModal setPaymentModal={setPaymentModal} paymentModal={paymentModal} />
							<button onClick={toggleButton} href="#!" className="toggle-menu visible-xs-block">
								<div className="toggle-menu-line" />
								<div className="toggle-menu-line" />
								<div className="toggle-menu-line" />
								<div className="toggle-menu-line" />
							</button>
						</div>
					</ul>
				</div>
				<ul className="menu__right">
					<PaymentModal setPaymentModal={setPaymentModal} paymentModal={paymentModal} />
					<AgentMessageAlertModal setPaymentMessageModal={setPaymentMessageModal} paymentMessageModal={paymentMessageModal} message={alertMessage} />

					<AccountModal
						setPaymentMessageModal={setPaymentMessageModal}
						setAlertMessage={setAlertMessage}
						onQuickPayment={quickPay}
						setPaymentModal={setAccountModal}
						paymentMethod={paymentMethod}
						setAccountDetails={setAccountDetails}
						paymentModal={accountModal}
						onAccountDetails={(data) => {
							setAccountDetails(data);
						}} />
					<BillingModal
						setPaymentModal={setBillingModal}
						paymentModal={billingModal}
						accountDetail={accountDetails}
						schedulePayment={schedulePayment} />
					<div id="portalOneContainer" key="portalOneContainer" />
				</ul>
			</div>
			<div className='tooltipViewNaviagtion1'>
				{showTooltip && <LoginTooltip setShowTooltip={setShowTooltip} onClick={() => setShowTooltip(true)} />}
			</div>
		</div>
	);
};

export default NewMenu;
