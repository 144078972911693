import React, { useEffect, useCallback, useMemo, useState } from "react";
import styles from "./SecondStepForm.module.css";

const SecondStepForm = ({ showModal,
  onSectionSave,
  sectionArray,
  setSectionArray,
  currentSection,
  setCurrentSection,
  setCurrentSubSection,
  subSection,
  setSubSection,
  setSectionStatus,
  step1Selection, setStep1Selection,
  step2Selection, setStep2Selection,
  step,
  setStep, step2ErrorMessage }) => {
  const [title, setTitle] = useState({ value: '', error: '' });
  const [editSection, setEditSection] = useState(false);
  const [subSectionTitle, setSubSectionTitle] = useState('');

  const onSectionChange = useCallback((e, index) => {
    e.preventDefault();
    // setTitle({ ...title, value: e.target.value })
    // setEditSection(true);
    // setSectionStatus('edit');
    // setSubSectionTitle(e.target.value)
    // setSubSection({ ...subSection, title: e.target.value })
    setStep2Selection({ selectedItem: step1Selection.selectedItem.subSection[parseInt(e.target.value)], index: parseInt(e.target.value) })


  }, [])
  useEffect(() => {
    if (step2Selection && step2Selection.selectedItem) {

    } else {

    }
  }, [step2Selection])
  useEffect(() => {
    if (step === 1) {
      setStep2Selection(null)
    }
  }, [step])
  // const subSectionList = useMemo(() => {
  //   let list = sectionArray[sectionArray.findIndex((item) =>
  //     item.title === currentSection.title
  //   )]
  //   return list.subSection;
  // }, [sectionArray, currentSection])

  // const currentSubSection = useMemo(() => {
  //   let list = subSectionList[subSectionList.findIndex((item) => item.title === subSectionTitle)];
  //   return list;
  // }, [subSectionList, subSectionTitle])

  // useEffect(() => {
  //   setCurrentSubSection(currentSubSection)
  // }, [currentSubSection, setCurrentSubSection])

  const onAddOption = useCallback(() => {
    setSectionStatus('add')
    setStep(3)
  }, [setSectionStatus, setStep])

  return (
    <div className={styles.mainContainer}>
      <div className="leftConatiner">
        <div className="select_state">
          <label style={{ fontSize: "2rem" }} className="label_heading_quote">
            Select a sub-section from {currentSection.title}  <span className="red_star">&#42;</span>
          </label>
          <br />
          <select
            id="selectstate"
            name="selectstate"
            className="landing_selectbox select__state__textbox"
            onChange={onSectionChange}
            value={step2Selection && step2Selection.index > -1 && step2Selection.index}
            style={{ marginBottom: '1rem' }}
          >
            <option value="">Select</option>
            {step1Selection && step1Selection.selectedItem && step1Selection.selectedItem.subSection.map((item, index) =>
              item.title.length > 0 ?
                <option
                  key={index}
                  value={index}
                  id="">
                  {item.title}
                </option> : null)
            }
          </select>{" "}
          <button onClick={onAddOption} className={styles.addOptionButton}>+ Add Option</button>
          <br />
          {/* {step2ErrorMessage && <p style={{ marginBottom: '0.8rem' }} className="form_input__error">step2ErrorMessage</p>} */}
        </div>
      </div>
    </div>
  );
};

export default SecondStepForm;
