import React, { useState, useEffect } from 'react';
import Banner from '../../../components/Banner/Banner';
import Content from '../../../components/Content/Content';
import Button from '../../../components/Inputs/Button';
import PaymentModal from '../HomeScreen/PaymentModal';

import { Link } from 'react-router-dom';
import './Faq.css';

const Faq = () => {
	const [ paymentModal, setPaymentModal ] = useState(false);

	useEffect(() => {
		let acc = document.getElementsByClassName('accordion');

		acc[0].classList.toggle('active');
		acc[0].nextElementSibling.classList.toggle('show');

		for (let i = 0; i < acc.length; i++) {
			acc[i].onclick = function() {
				this.classList.toggle('active');
				this.nextElementSibling.classList.toggle('show');
			};
		}
	});

	return (
		<Content headerFooterMenu="mountain-west">
			<Banner
				backgroundImage="linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),url(/images/hat-and-rope.jpg)"
				title="Frequently Asked Questions"
				subTitle="Look for answers to your questions form the list below."
			/>
			<div className="quote2">
				{/* <div className="flex2">
					<h3 className="quote__text2">Start your retirement solution planning with your local agent. </h3>
				</div>
				<div className="header_buttons">
					<Link to="/mwfbi/start-a-quote" className="mw_button">
						Start a Quote
					</Link>
					<Link to="/mwfbi/find-an-agent" className="mw_button">
						find an agent
					</Link>
				</div> */}
			</div>

			<div className="faqs">
				<div className="faq">
					<p className="faq__number"> 1</p>
					<div>
						<p className="accordion">
							Can I make payments online?
							{/* <svg className="accordion_icon">
								<use xlinkHref="/sprite.svg#icon-plus" />
							</svg> */}
						</p>
						<div className="panel">
							<p className="phase2_subheading">
								Yes, we can process online payments from a checking or savings account, or with a credit
								card.
							</p>
							{/* <input
								type="button"
								value="make payment"
								className="FAQ_button"
								onClick={() => setPaymentModal(true)}
							/> */}
							<a
								// exact
								// to="/pay"
								// onClick={() => setPaymentModal(true)}
								href="#/customer-login"
								className="FAQ_button"
								style={{ textDecoration: 'none' }}
							>
								make payment
							</a>
						</div>
						<PaymentModal setPaymentModal={setPaymentModal} paymentModal={paymentModal} />
						{/* <Button label="Make Payment" icon="/sprite.svg#icon-arrow_right" /> */}
					</div>
				</div>
				<div className="faq">
					<p className="faq__number">2</p>
					<div>
						<p className="accordion">What is the address for the main office?</p>
						<div className="panel">
							<p className="phase2_subheading">
								<b>Physical Address: </b>931 Boulder Drive, Laramie, WY 82070 <br />
								<b>Mailing Address:</b> PO Box 1348, Laramie, WY 82073-1348
							</p>
						</div>
					</div>
				</div>
				<div className="faq">
					<p className="faq__number">3</p>
					<div>
						<p className="accordion">Who do I make my check or money order payable to?</p>
						<div className="panel">
							<p className="phase2_subheading">
								Please make checks payable to Mountain West Farm Bureau.
							</p>
							{/* <input type="button" value="make payment" className="FAQ_button" onSubmit={''} /> */}
						</div>
					</div>
				</div>
				<div className="faq">
					<p className="faq__number">4</p>
					<div>
						<p className="accordion">Who do I contact regarding insurance needs or policy changes?</p>

						<div className="panel">
							<p className="phase2_subheading">
								Please contact your Farm Bureau agent to discuss your insurance needs or changes to your
								policy. "Find An Agent" button - going to the "Find an Agent" page.
							</p>
							<Link to="/mwfbi/find-an-agent" className="FAQ_button">
								Find your Local Agent
							</Link>
						</div>
					</div>
				</div>
				<div className="faq">
					<p className="faq__number">5</p>
					<div>
						<p className="accordion">How do I find a Farm Bureau agent?</p>

						<div className="panel">
							<p className="phase2_subheading">
								To locate an agent in your area use our agent search tool and search by address or name.
							</p>
							<Link to="/mwfbi/find-an-agent" className="FAQ_button">
								Find your Local Agent
							</Link>
						</div>
					</div>
				</div>
				<div className="faq">
					<p className="faq__number">6</p>
					<div>
						<p className="accordion">How do I file a claim?</p>

						<div className="panel">
							<p className="phase2_subheading">
								To file a claim, the first step you need to take is to call your Farm Bureau agent
								office and report the damage that has occurred.
							</p>
							<p>
								<b>CLICK HERE : </b>
								<Link to="/mwfbi/how-to-claim-file" className="link-to-claims">
									for more information regarding the claims process.
								</Link>
							</p>
						</div>
					</div>
				</div>
				<div className="faq">
					<p className="faq__number">7</p>
					<div>
						<p className="accordion">Can I get a quote online?</p>
						<div className="panel">
							<p className="phase2_subheading">
								At Mountain West, we pride ourselves on working with you personally to provide you the
								best coverage for your needs. As such, you can start a quote online, which will be sent
								to your nearest agent. there office will then contact you asap to get all the
								information needed to process a comprehensive quote.
							</p>
							<p>
								<Link to="/mwfbi/start-a-quote" className="FAQ_button">
									Start a Quote
								</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</Content>
	);
};

export default Faq;
