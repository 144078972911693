import React, { useState } from 'react'
import './CheckBox.css'

const CheckBox = ({ text, key = 'active', checked, onChange = () => { } }) => {
  const [inputType, setInputType] = useState(true)

  const handlePassword = () => {
    setInputType(!inputType)
  }

  return (
    <div className='switchContainer'>
      <label className='switch'>
        <input
          id={key}
          key={key}
          type='checkbox'
          className='checkbox'
          name='active'
          checked={checked}
          onChange={onChange}
        />
        <span className='switch-slider round' />
      </label>
      <h3 className='switch-text'>{text}</h3>
    </div>
  )
}

export default CheckBox
