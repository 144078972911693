export const SEND_START_A_QUOTE_REQUEST = 'SEND_START_A_QUOTE_REQUEST';
export const SEND_START_A_QUOTE_SUCCESS = 'SEND_START_A_QUOTE_SUCCESS';
export const SEND_START_A_QUOTE_FAIL = 'SEND_START_A_QUOTE_FAIL';
export const SEND_START_A_QUOTE_RESET = 'SEND_START_A_QUOTE_RESET';

export const SEND_MAIL_REQUEST = 'SEND_MAIL_REQUEST';
export const SEND_MAIL_SUCCESS = 'SEND_MAIL_SUCCESS';
export const SEND_MAIL_FAIL = 'SEND_MAIL_FAIL';
export const SEND_MAIL_RESET = 'SEND_MAIL_RESET';

export const CONTACT_US_REQUEST = 'CONTACT_US_REQUEST';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_FAIL = 'CONTACT_US_FAIL';
export const CONTACT_US_RESET = 'CONTACT_US_RESET';

