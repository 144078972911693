import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import DropZone from 'react-dropzone'
import { Link } from 'react-router-dom'
import { imageUpload } from '../../../../redux/actions/contentAction'

const EditServices = ({ setShowModal, showModal, handleYes, section3, setSection3 }) => {
  const [loading, setLoading] = useState(false)
  const [uploadImage, setUploadImage] = useState('')
  const [uploadImage3, setUploadImage3] = useState('')
  const [uploadImage2, setUploadImage2] = useState('')
  const [uploadImagePreview, setUploadImagePreview] = useState(section3?.serviceImage?.value)
  const [uploadImagePreview2, setUploadImagePreview2] = useState(section3?.packageImage?.value)
  const [uploadImagePreview3, setUploadImagePreview3] = useState(section3?.packageFile?.value)


  const encodeImageFileAsURL = (files, isService) => {
    var file = files[0]
    var reader = new FileReader();
    if (isService === 'service')
      setUploadImage(file)
    else if (isService === 'package')
      setUploadImage2(file)
    else
      setUploadImage3(file)
    reader.onloadend = function () {
      if (reader.result) {
        if (isService === 'service')
          setUploadImagePreview(reader.result)
        else if (isService === 'package')
          setUploadImagePreview2(reader.result)
        else
          setUploadImagePreview3(reader.result)
      }
    }
    reader.readAsDataURL(file)
  }
  const getFileName = (url) => {
    if (url.startsWith('https')) {
      const split = url.split("/");
      if (split.length)
        return split[split.length - 1]
    }
    return "View File"
  }

  const submit = async (e) => {
    e.preventDefault();

    if (section3.serviceTitle.value == '') {
      let temp = { ...section3 };
      temp.serviceTitle.error = 'Please enter service title!'
      setSection3(temp)
      return
    } else {
      let temp = { ...section3 };
      temp.serviceTitle.error = ''
      setSection3(temp)
    }

    if (section3.serviceSubTitle.value == '') {
      let temp = { ...section3 };
      temp.serviceSubTitle.error = 'Please enter sub service title!'
      setSection3(temp)
      return
    } else {
      let temp = { ...section3 };
      temp.serviceSubTitle.error = ''
      setSection3(temp)
    }
    if (section3.serviceButtonTitle.value == '') {
      let temp = { ...section3 };
      temp.serviceButtonTitle.error = 'Please enter service button title!'
      setSection3(temp)
      return
    } else {
      let temp = { ...section3 };
      temp.serviceButtonTitle.error = ''
      setSection3(temp)
    }
    if (section3.serviceButtonLink.value == '') {
      let temp = { ...section3 };
      temp.serviceButtonLink.error = 'Please enter service button link!'
      setSection3(temp)
      return
    } else {
      let temp = { ...section3 };
      temp.serviceButtonLink.error = ''
      setSection3(temp)
    }
    if (uploadImage) {
      let filePath = await imageUpload(uploadImage)
      let temp = { ...section3 };
      temp.serviceImage.value = filePath.data.path;
      setSection3(temp)
    }


    if (section3.packageTitle.value == '') {
      let temp = { ...section3 };
      temp.packageTitle.error = 'Please enter title!'
      setSection3(temp)
      return
    } else {
      let temp = { ...section3 };
      temp.packageTitle.error = ''
      setSection3(temp)
    }
    if (section3.packageSubTitle.value == '') {
      let temp = { ...section3 };
      temp.packageSubTitle.error = 'Please enter sub title!'
      setSection3(temp)
      return
    } else {
      let temp = { ...section3 };
      temp.packageSubTitle.error = ''
      setSection3(temp)
    }
    if (section3.packageButtonTitle.value == '') {
      let temp = { ...section3 };
      temp.packageButtonTitle.error = 'Please enter button title!'
      setSection3(temp)
      return
    } else {
      let temp = { ...section3 };
      temp.packageButtonTitle.error = ''
      setSection3(temp)
    }
    if (section3.packageEmail.value == '') {
      let temp = { ...section3 };
      temp.packageEmail.error = 'Please enter email!'
      setSection3(temp)
      return
    } else {
      let temp = { ...section3 };
      temp.packageEmail.error = ''
      setSection3(temp)
    }
    if (section3.packageMail.value == '') {
      let temp = { ...section3 };
      temp.packageMail.error = 'Please enter mail!'
      setSection3(temp)
      return
    } else {
      let temp = { ...section3 };
      temp.packageMail.error = ''
      setSection3(temp)
    }
    if (section3.packageAttn.value == '') {
      let temp = { ...section3 };
      temp.packageAttn.error = 'Please enter Attn!'
      setSection3(temp)
      return
    } else {
      let temp = { ...section3 };
      temp.packageAttn.error = ''
      setSection3(temp)
    }

    if (uploadImage2) {
      let filePath = await imageUpload(uploadImage2)
      let temp = { ...section3 };
      temp.packageImage.value = filePath.data.path;
      setSection3(temp)
    }
    if (uploadImage3) {
      let filePath = await imageUpload(uploadImage3)
      let temp = { ...section3 };
      temp.packageFile.value = filePath.data.path;
      setSection3(temp)
    }
    handleYes()
  }
  return (
    <Modal show={showModal}>
      <form >
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Section 3</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => {
                setShowModal(false)
              }}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section3.serviceTitle.value}
                    onChange={(e) => {
                      let temp = { ...section3 };
                      temp.serviceTitle.value = e.target.value;
                      setSection3(temp)
                    }}
                    errorMessage={section3.serviceTitle.error}
                  />
                  <FormInputComponent
                    heading='Sub Title:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section3.serviceSubTitle.value}
                    onChange={(e) => {
                      let temp = { ...section3 };
                      temp.serviceSubTitle.value = e.target.value;
                      setSection3(temp)
                    }}
                    errorMessage={section3.serviceSubTitle.error}
                  />
                </div>

                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Button Title:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section3.serviceButtonTitle.value}
                    onChange={(e) => {
                      let temp = { ...section3 };
                      temp.serviceButtonTitle.value = e.target.value;
                      setSection3(temp)
                    }}
                    errorMessage={section3.serviceButtonTitle.error}
                  />
                  <FormInputComponent
                    heading='Button Link:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section3.serviceButtonLink.value}
                    onChange={(e) => {
                      let temp = { ...section3 };
                      temp.serviceButtonLink.value = e.target.value;
                      setSection3(temp)
                    }}
                    errorMessage={section3.serviceButtonLink.error}
                  />
                </div>
                <div className='modal_inner_content--right3' style={{ alignItems: "flex-start", flexDirection: 'row' }}>
                  <div><label>Icon</label>
                    <DropZone onDrop={(acceptedFiles) => {
                      encodeImageFileAsURL(acceptedFiles, 'service')
                    }} name="heroImage" multiple={false}>
                      {({ getRootProps, getInputProps }) => (<div style={{ height: 'unset' }} className='upload_border' {...getRootProps()}>
                        <input id='service' {...getInputProps()} />
                        <label>Click or Drag a image to this area to upload</label>
                        <svg className='modal_upload__icon-dark'>
                          <use xlinkHref='/sprite.svg#icon-cloud' />
                        </svg>
                      </div>)}
                    </DropZone>
                  </div>
                  <div className='upload_preview_modal'>
                    <img height={50} src={uploadImagePreview}></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '80%' }}
                    value={section3.packageTitle.value}
                    required={true}
                    onChange={(e) => {
                      let temp = { ...section3 };
                      temp.packageTitle.value = e.target.value;
                      setSection3(temp)
                    }}
                    errorMessage={section3.packageTitle.error}
                  />
                  <FormInputComponent
                    heading='Sub Title:'
                    style={{ width: '80%' }}
                    value={section3.packageSubTitle.value}
                    required={true}
                    onChange={(e) => {
                      let temp = { ...section3 };
                      temp.packageSubTitle.value = e.target.value;
                      setSection3(temp)
                    }}
                    errorMessage={section3.packageSubTitle.error}
                  />
                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Email:'
                    style={{ width: '80%' }}
                    value={section3.packageEmail.value}
                    required={true}
                    onChange={(e) => {
                      let temp = { ...section3 };
                      temp.packageEmail.value = e.target.value;
                      setSection3(temp)
                    }}
                    errorMessage={section3.packageEmail.error}
                  />
                  <FormInputComponent
                    heading='Mail:'
                    style={{ width: '80%' }}
                    value={section3.packageMail.value}
                    required={true}
                    onChange={(e) => {
                      let temp = { ...section3 };
                      temp.packageMail.value = e.target.value;
                      setSection3(temp)
                    }}
                    errorMessage={section3.packageMail.error}
                  />

                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Attn:'
                    style={{ width: '80%' }}
                    value={section3.packageAttn.value}
                    required={true}
                    onChange={(e) => {
                      let temp = { ...section3 };
                      temp.packageAttn.value = e.target.value;
                      setSection3(temp)
                    }}
                    errorMessage={section3.packageAttn.error}
                  />
                  <FormInputComponent
                    heading='Button Title:'
                    style={{ width: '80%' }}
                    value={section3.packageButtonTitle.value}
                    required={true}
                    onChange={(e) => {
                      let temp = { ...section3 };
                      temp.packageButtonTitle.value = e.target.value;
                      setSection3(temp)
                    }}
                    errorMessage={section3.packageButtonTitle.error}
                  />
                </div>
                <div className='modal_inner_content--right3' style={{ alignItems: "flex-start", flexDirection: 'row' }}>
                  <div><label>Icon</label>
                    <DropZone onDrop={(acceptedFiles) => {
                      encodeImageFileAsURL(acceptedFiles, 'package')
                    }} name="heroImage" multiple={false}>
                      {({ getRootProps, getInputProps }) => (<div style={{ height: 'unset' }} className='upload_border' {...getRootProps()}>
                        <input id='service' {...getInputProps()} />
                        <label>Click or Drag a image to this area to upload</label>
                        <svg className='modal_upload__icon-dark'>
                          <use xlinkHref='/sprite.svg#icon-cloud' />
                        </svg>
                      </div>)}
                    </DropZone>
                  </div>
                  <div className='upload_preview_modal'>
                    <img height={50} src={uploadImagePreview2}></img>
                  </div>
                </div>
                <div className='modal_inner_content--right3' style={{ alignItems: "flex-start", flexDirection: 'row' }}>
                  <div><label>File</label>
                    <DropZone onDrop={(acceptedFiles) => {
                      encodeImageFileAsURL(acceptedFiles, 'packageFile')
                    }} name="heroImage" multiple={false}>
                      {({ getRootProps, getInputProps }) => (<div style={{ height: 'unset' }} className='upload_border' {...getRootProps()}>
                        <input id='service' {...getInputProps()} />
                        <label>Click or Drag a image to this area to upload</label>
                        <svg className='modal_upload__icon-dark'>
                          <use xlinkHref='/sprite.svg#icon-cloud' />
                        </svg>
                      </div>)}
                    </DropZone>
                  </div>
                  <div className='upload_preview_modal'>
                    {uploadImagePreview3 && <a href={uploadImagePreview3}>{getFileName(uploadImagePreview3)}</a>}
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div className='modal_dark_buttons'>
            <button
              className='modal__button-reset-dark'
              onClick={() => {
                setShowModal(false)
              }}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={submit}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditServices
