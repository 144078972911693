export const validateOnlyChacters = (name) => {
	const re = /^[a-zA-Z \s]+$/;
	return re.test(name);
};

export const validatePassword = (password) => {
	const re = /(?=.*[a-zA-Z0-9])(?=.*[a-zA-Z0-9])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}/;
	return re.test(password);
};

export const validateEmail = (email) => {
	const re = /[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
	return re.test(email);
};

export const validatePhone = (phone) => {
	// const re = /^[0-9]{10}$/;
	const re = /^[0-9-()\s]{14}$/;
	return re.test(phone);
};

export const validateUrl = (url) => {
	const re = /[(http(s)?):(www.)?a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g;
	return re.test(url);
};

export const validateOnlyNumber = (name) => {
	const re = /^[0-9 \s]+$/;
	return re.test(name);
};

export const validateOnlyNumberDecimal = (name) => {
	const re = /^[+-]?([0-9]+\.?[5]*|\.[0-9]+)$/;
	return re.test(name);
};

export const validateZipCode = (zip) => {
	const re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
	return re.test(zip);
};
   