import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import { imageUpload } from '../../../../redux/actions/contentAction'

const EditInsuranceEmergency = ({
  setShowModal,
  showModal,
  handleYes,
  section3,
  setSection3,
}) => {
  const [loading, setLoading] = useState(false)

  const [uploadImagePreview, setUploadImagePreview] = useState('')
  const [uploadImage, setUploadImage] = useState(null)

  const [editIndex, setEditIndex] = useState(-1)

  const [title, setTitle] = useState({ value: '', error: '' })
  const [description, setDescription] = useState({ value: '', error: '' })
  const [subTitle1, setSubTitle1] = useState({ value: '', error: '' })
  const [subTitle2, setSubTitle2] = useState({ value: '', error: '' })
  const [contact, setContact] = useState({ value: '', error: '' })

  useEffect(() => {}, [])

  const submit = (e) => {
    e.preventDefault()
    if (section3.title.value == '') {
      let temp = { ...section3 }
      temp.title.error = 'Please enter title!'
      setSection3(temp)
      return
    } else {
      let temp = { ...section3 }
      temp.title.error = ''
      setSection3(temp)
    }
    if (section3.description.value == '') {
      let temp = { ...section3 }
      temp.description.error = 'Please enter description!'
      setSection3(temp)
      return
    } else {
      let temp = { ...section3 }
      temp.description.error = ''
      setSection3(temp)
    }
    if (section3.subTitle1.value == '') {
      let temp = { ...section3 }
      temp.subTitle1.error = 'Please enter sub title 1!'
      setSection3(temp)
      return
    } else {
      let temp = { ...section3 }
      temp.subTitle1.error = ''
      setSection3(temp)
    }
    if (section3.subTitle2.value == '') {
      let temp = { ...section3 }
      temp.subTitle2.error = 'Please enter sub title 2!'
      setSection3(temp)
      return
    } else {
      let temp = { ...section3 }
      temp.subTitle2.error = ''
      setSection3(temp)
    }
    if (section3.contact.value == '') {
      let temp = { ...section3 }
      temp.contact.error = 'Please enter contact!'
      setSection3(temp)
      return
    } else {
      let temp = { ...section3 }
      temp.contact.error = ''
      setSection3(temp)
    }

    if (uploadImage) {
      imageUpload(uploadImage).then((res) => {
        if (res.data.status) {
          setUploadImagePreview(res.data.path)

          let content = {
            ...section3,
            imageUrl: {
              value: res.data.path,
            },
          }

          console.log('content for online', content)

          handleYes(content)
          setShowModal(false)
        }
      })
    } else {
      let content = {
        ...section3,
      }

      console.log('content', content)

      handleYes(content)
      setShowModal(false)
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    // console.log(acceptedFiles);
    encodeImageFileAsURL(acceptedFiles)
  }, [])
  const encodeImageFileAsURL = (files) => {
    var file = files[0]
    setUploadImage(file)
    var reader = new FileReader()
    reader.onloadend = function () {
      console.log('RESULT', reader.result)
      if (reader.result) {
        setUploadImagePreview(reader.result)
      }
    }
    reader.readAsDataURL(file)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop,
  })

  return (
    <Modal show={showModal}>
      <form>
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>
              Edit Auto Insurance Emergency
            </h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => setShowModal(false)}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section3.title.value}
                    onChange={(e) => {
                      let temp = { ...section3 }
                      temp.title.value = e.target.value
                      setSection3(temp)
                      setTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={section3.title.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Description:'
                    style={{ width: '80%' }}
                    type={'textarea'}
                    required={true}
                    value={section3.description.value}
                    onChange={(e) => {
                      let temp = { ...section3 }
                      temp.description.value = e.target.value
                      setSection3(temp)
                      setDescription({
                        error: '',
                        value: e.target.value,
                      })
                    }}
                    errorMessage={section3.description.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Sub Title 1:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section3.subTitle1.value}
                    onChange={(e) => {
                      let temp = { ...section3 }
                      temp.subTitle1.value = e.target.value
                      setSection3(temp)
                      setSubTitle1({
                        error: '',
                        value: e.target.value,
                      })
                    }}
                    errorMessage={section3.subTitle1.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Sub Title 2:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section3.subTitle2.value}
                    onChange={(e) => {
                      let temp = { ...section3 }
                      temp.subTitle2.value = e.target.value
                      setSection3(temp)
                      setSubTitle2({
                        error: '',
                        value: e.target.value,
                      })
                    }}
                    errorMessage={section3.subTitle2.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Contact Number:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section3.contact.value}
                    onChange={(e) => {
                      let temp = { ...section3 }
                      temp.contact.value = e.target.value
                      setSection3(temp)
                      setContact({
                        error: '',
                        value: e.target.value,
                      })
                    }}
                    errorMessage={section3.contact.error}
                  />
                </div>
              </div>
              <div className='modal_inner_content--right'>
                <label>Upload Image</label>
                <div
                  className='upload_border'
                  {...getRootProps()}
                  style={{ marginTop: '-1rem' }}>
                  <input {...getInputProps()} />
                  <label>Click or Drag a image to this area to upload</label>
                  {uploadImagePreview == '' ? (
                    <svg className='modal_upload__icon-dark'>
                      <use xlinkHref='/sprite.svg#icon-cloud' />
                    </svg>
                  ) : (
                    <img
                      height={60}
                      width={60}
                      src={uploadImagePreview}
                      style={{
                        marginTop: '0rem',
                        alignSelf: 'center',
                      }}></img>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='modal_dark_buttons'>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={submit}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditInsuranceEmergency
