import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import Content from '../../../components/Content/Content';
import TopicCard from '../../../components/Blogs/TopicCard';
import BlogCard from '../../../components/Blogs/BlogCard';
import Pagination from '../../../components/Pagination';
import './LearningCenter.css';
import { useSelector } from 'react-redux';
import { ADMIN_USERS } from '../../../redux/actions/ip';
import { getAllMainTopicsAPI, getAllTopicsAPI, getBlogSetting, getPublishedBlogAPI, getPublishedBlogByTopic, searchPublishedBlogs } from '../../../redux/actions/blogListAction';
import { TOPIC_LIST } from '../../../constants';
import './search.css';
const pageName = "BlogSetting"
const pageSize = 6
const skeletonBlogItems = [1, 2, 3, 4, 5, 6];
const skeletonTopicItems = [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16];
let timeout = null
const LearningCenter = () => {
	const [topicsData, setTopicsData] = useState([])
	const [showEdit, setShowEdit] = useState(false)
	const [totalPages, setTotalPages] = useState(0)
	const [pageNumber, setPageNumber] = useState(1)
	const [numberOfPages, setNumberOfPages] = useState(0)
	const [apiLoading, setAPILoading] = useState(false)
	const [apiTopiLoading, setAPITopicLoading] = useState(false)
	const [title, setTitle] = useState("")
	const [selectedTopic, setSelectedTopic] = useState("all")
	const [searchBlog, setSearchBlog] = useState("")
	const [items, setItems] = useState([])
	const [subTitle, setSubTitle] = useState("")
	const azureLogin = useSelector((state) => state.azureLogin)
	useEffect(() => {
		if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
			if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
				setShowEdit(true)
			} else setShowEdit(false)
		} else setShowEdit(false)
	}, [azureLogin])
	const getAllMTopics = () => {
		setAPITopicLoading(true)
		getAllMainTopicsAPI('active')
			.then(res => {
				const data = res.data;
				if (data.status) {
					setTopicsData(data.data)
				}
				setAPITopicLoading(false)
			})
			.catch(error => { setAPITopicLoading(false) })
	}
	const searchBlogCall = () => {
		setAPILoading(true)
		searchPublishedBlogs(searchBlog, 1, 100)
			.then(res => {
				if (res.data.status) {
					setItems(res.data.data);
					setTotalPages(1);
				}
				setAPILoading(false)
			})
			.catch(err => { setAPILoading(false) })
	}
	const apiCalls = () => {
		setAPILoading(true)
		if (searchBlog === "") {
			if (selectedTopic === 'all')
				getPublishedBlogAPI('active', pageNumber, pageSize).then(res => {
					if (res.data.status) {
						setItems(res.data.data);
						setTotalPages(res.data.count);
						setNumberOfPages(Math.ceil(res.data.count / pageSize))
						setAPILoading(false)
					}
				}).catch(exp => { })
			else
				getPublishedBlogByTopic(selectedTopic, pageNumber, pageSize).then(res => {
					if (res.data.status) {
						setItems(res.data.data);
						setTotalPages(res.data.count);
						setNumberOfPages(Math.ceil(res.data.count / pageSize))
						setAPILoading(false)
					}
				}).catch(exp => { })
		} else searchBlogCall()
	}
	useEffect(() => {
		apiCalls()
		return () => {
			setItems([])
		}
	}, [selectedTopic, pageNumber])
	useEffect(() => {
		getBlogSetting(pageName).then(res => {
			if (res.data.status) {
				setTitle(res.data.result?.title?.value)
				setSubTitle(res.data.result?.subtitle?.value)
			}
		}).catch(exp => { })
		getAllMTopics()
		return () => {
			setTitle("")
			setSubTitle("")
		}
	}, [])
	useEffect(() => {
		clearTimeout(timeout)
		timeout = setTimeout(() => {
			if (searchBlog !== "")
				searchBlogCall()
			else apiCalls()
		}, 1000);

	}, [searchBlog])

	return (
		<Content headerFooterMenu="mountain-west">
			<div className="mwfbi_banner2 main-content-padding">
				{showEdit &&
					<Link
						to={`/mwfbi/blog-settings`}
						className="learning_content__card"

					>
						<div
							className='mwfbi_banner__edit'
							style={{ top: '10rem', right: '10rem' }}
							id='admin-edit'
						//   onClick={() => setShowEditSearchModal(true)}
						>
							<svg>
								<use xlinkHref='/sprite.svg#icon-edit' />
							</svg>
						</div>
					</Link>
				}
				<h3 className="mwfbi_banner__heading--black heading_1">{title}</h3>
				<h1 className="mwfbi_banner__text--black">{subTitle}</h1>
			</div>
			<div className="learning_content search_blog_container" >
				<input
					type="text"
					className='search_blog'
					style={{ fontFamily: 'inherit' }}
					value={searchBlog}
					onChange={(e) => { setSearchBlog(e.target.value) }}
					placeholder='Search blog'></input>
			</div>
			<div className="learning_content">
				<div className="learning__filters">
					{!apiTopiLoading && <button onClick={(e) => setSelectedTopic('all')} className={`learning__filters--btn heading_3 ${selectedTopic === 'all' ? ' learning__filters--active' : ''}`}>All</button>}
					{!apiTopiLoading && topicsData.map(topic => <TopicCard selected={selectedTopic === topic.mainTopicId} key={topic.title} topic={topic.title} value={topic.mainTopicId}
						onClick={(tp) => {
							setSearchBlog("")
							setPageNumber(1)
							setSelectedTopic(tp)
						}}></TopicCard>)}
					{apiTopiLoading && skeletonTopicItems.map(sk => <Skeleton style={{ height: '4rem' }} />)}
				</div>

				<div className="learning_content__cards" >
					{items && !apiLoading &&
						items.map((item) => <BlogCard
							id={item.blogId}
							key={item.blogId}
							date={item.publishedDate}
							name={item.title}
							image={item.imageLink}
						></BlogCard>
						)}
					{apiLoading && skeletonBlogItems.map(sk => <Skeleton style={{ height: '25rem' }} />)}
				</div>
				{!apiLoading && !items.length && <h2 style={{ textAlign: 'center', width: "100%", color: "#ababab" }}>No result to show</h2>}
			</div>
			{!apiLoading && searchBlog === "" && items.length && numberOfPages > 0 && <div style={{ marginRight: "33rem", marginTop: "2rem", marginBottom: "2rem" }}>
				<Pagination
					onPageChange={({ selected }) => { setPageNumber(selected + 1) }}
					rowsPerPage={pageSize}
					totalPageSize={numberOfPages}
					pageNumber={pageNumber}
				/>
			</div>}
		</Content>
	);
};

export default LearningCenter;
