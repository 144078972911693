import React, { Fragment, useState, useEffect, useCallback } from 'react'

import Modal from '../../../../../../../DarkModal'
import FormInputComponent from '../../../../../../../FormInputComponent/FormInputComponent'
import styles from './../../EditForm/EditForm.module.css'

const itemsPerPage = 10

const LinkOption = ({
  onSave,
  sections
}) => {

  const [title, setTitle] = useState({ value: '', error: '' })
  const handleSubmit = useCallback((e) => {
    console.log("SE", sections);
    e.preventDefault()
    if (title.value === '') {
      setTitle({ ...title, error: 'Please enter the title!' })
      return;
    }
    if (sections.selectedItem.subSection.find(item => item.title === title.value)) {
      setTitle({ ...title, error: 'Title already present.' })
      return
    }
    onSave({ title: title.value, points: [] })
  }, [title])


  return (
    <div className='modal__container-dark' style={{ borderBottom: '1px solid rgba(162, 162, 162, 0.32)' }}>
      <div style={{ width: '100%', padding: '0rem' }} className='modal__form-dark'>
        <div className='modal_inner_content'>
          <div className='modal_inner_content--left'>
            <div className={'inline-form-view'}>
              <FormInputComponent
                heading='Title:'
                style={{ width: '30%' }}
                required={true}
                value={title.value}
                onChange={(e) => {
                  setTitle({ error: '', value: e.target.value })
                }}
                errorMessage={title.error}
              />
            </div>
            {/* <div className='inline-form-view'>
                <FormInputComponent
                  heading='Point Title:'
                  style={{ width: '30%' }}
                  required={true}
                  value={pointTitle.value}
                  onChange={(e) => {
                    setPointTitle({ error: '', value: e.target.value })
                  }}
                  errorMessage={pointTitle.error}
                />
              </div>
              <div className='inline-form-view'>
                <FormInputComponent
                  heading='Point Link:'
                  style={{ width: '50%' }}
                  value={pointLink.value}
                  onChange={(e) => {
                    setPointLink({ error: '', value: e.target.value })
                  }}
                  errorMessage={pointLink.error}
                />
              </div> */}
          </div>
        </div>
        {/* <div className='modal_inner_content'>
            <div className='modal_form_container'>
              <table
                className='modal__form-table'
                cellSpacing='0'
                cellPadding='0'>
                <thead className='modal__form-sticky'>
                  <tr className='modal__form-tr'>
                    <th>No.</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {discountListArr &&
                    discountListArr &&
                    discountListArr.map((item, i) => {
                      return (
                        <tr
                          style={{ height: '60px' }}
                          className='modal__form-tr2'
                          key={i}>
                          <td>{i + 1}</td>
                          <td>{item.title}</td>
                          <td>{item.pointTitle}</td>
                          <td
                            style={{ marginTop: '12px' }}
                            className='modal__form-buttons'>
                            <button
                              type='button'
                              className='modal__button-edit'
                              onClick={(e) => onEdit(item, i)}>
                              edit
                            </button>
                            <button
                              type='button'
                              className='modal__button-delete'
                              onClick={(item) => onDiscountDelete(item, i)}>
                              delete
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div> */}
      </div>
      <div className='modal_dark_buttons' style={{ paddingTop: '0', paddingBottom: '1rem', marginLeft: '1.6rem' }}>
        <button
          type='button'
          className='modal__button-reset-dark'
          onClick={handleSubmit}>
          Save
        </button>
      </div>
    </div>
  )
}

export default LinkOption
