import React from 'react';
import Content from '../../../components/Content/Content';
// import ReactPaginate from 'react-paginate';
import HtmlReactParser from 'html-react-parser';

import EditPageDetail from './edit/EditPageDetails';
import EditSection3Modal from './edit/EditSection3';
import EditSection4Modal from './edit/EditSection4';

import './OurCommunity.css';
import Banner from '../../../components/Banner/Banner';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { addContent, getPageContent, imageUpload } from '../../../redux/actions/contentAction';
import { ADMIN_USERS } from '../../../redux/actions/ip';
import { splitArray } from '../../../redux/constants/utils';
import { Link } from 'react-router-dom';

const pageName = "AboutUsOurCommunityPage2";
const OurCommunity = (data) => {
	const azureLogin = useSelector((state) => state.azureLogin)
	const [showEdit, setShowEdit] = useState(false)
	const [showSection2Modal, setShowSection2Modal] = useState(false);
	const [showSection3Modal, setShowSection3Modal] = useState(false);
	const [showSection4Modal, setShowSection4Modal] = useState(false);
	const [loading, setLoading] = useState(false)
	const [bannerData, setBannerData] = useState({
		title: { value: 'Proud of Our Community', error: '' },
		subTitle: { value: 'Mountain West strives to improve our local community and state through a variety of volunteering and donations.', error: '', },
		tagline: { value: '', error: '' },
		button1: { value: 'START A QUOTE', error: '' },
		button1Link: { value: '/mwfbi/start-a-quote', error: '' },
		button1Status: { value: false, error: '' },
		button2: { value: 'FIND AN AGENT', error: '' },
		button2Link: { value: '/mwfbi/find-an-agent', error: '' },
		button2Status: { value: false, error: '' },
		imageUrl: { value: '/images/community-topImage.JPG', error: '' },
	})
	const [section2, setSection2] = useState({
		title: { value: 'Giving Back', action: '', type: '' },
		image: { value: '', action: '', type: '' },
		description: {
			value: `Mountain West takes initiative in giving back to our community. Our GIV Committee (Giving Inspiration through Volunteering) is composed of Mountain West employees and community members who come together to improve our local community and state. Through volunteering opportunities, GIV raises interest and resources for the sake of organizations within Wyoming. The goal of GIV is to bridge the efforts of our fellow community members and co-workers in order to aid our community organizations.
			<br/><br/>
			<b>Mountain West Golf Tournament Fundraiser</b><br/>
			Mountain West hosts a golf tournament fundraiser each year in August. Our golf tournament committee selects two charities every year (one local and one national) and plans our golf tournament fundraising event throughout the year to benefit these organizations.The event has been very successful and we usually incorporate our charities into the running of the tournament. This is a great sponsorship opportunity for other local businesses and a great way to bring the community together for important organizations.<br/>
			`,
			action: '',
			type: '',
		},
		linkLabel: {
			value: 'Learn more about our tournament here.',
			action: '',
			type: '',
		},
		link: {
			value: 'https://www.mwfbgolf.com/',
			action: '',
			type: '',
		}
	})

	const [section3, setSection3] = useState({
		title: {
			value: `OUR CHARITIES`, action: '', type: ''
		},
		images: {
			value: [{ link: "https://www.cathedralhome.org/", image: '' },
			{ link: "https://www.cathedralhome.org/", image: '/images/StackedCHC.png' },
			{ link: "https://www.cathedralhome.org/", image: '/images/StackedCHC.png' },
			{ link: "https://www.cathedralhome.org/", image: '/images/StackedCHC.png' },
			{ link: "https://www.cathedralhome.org/", image: '/images/StackedCHC.png' },
			{ link: "https://www.cathedralhome.org/", image: '/images/StackedCHC.png' },
			{ link: "https://www.cathedralhome.org/", image: '/images/StackedCHC.png' },
			{ link: "https://www.cathedralhome.org/", image: '/images/StackedCHC.png' },
			{ link: "https://www.cathedralhome.org/", image: '/images/StackedCHC.png' },
			{ link: "https://www.cathedralhome.org/", image: '/images/StackedCHC.png' }], action: '', type: ''
		}
	})
	const [section4, setSection4] = useState({
		title: { value: 'Shred Bins and Recycling', action: '', type: '' },
		image: { value: '/images/recycle.png', action: '', type: '' },
		imageTag: { value: 'In 2020, Mountain West Farm Bureau saved:', action: '', type: '' },
		imageHead: { value: '349 Trees!', action: '', type: '' },
		description: {
			value: `When the shred bins are emptied each week, the documents are taken to Cheyenne, Wyoming to be shredded. After shredding, they are taken to a recycling plant where they are recycled by mixing with hot water and turned into a pulp. They are then turned into a Class B product such as:`,
			action: '',
			type: '',
		},
		description2: {
			value: `Paper Plates | Egg Cartons | Toilet Paper & Tissue | Paper Towels & Napkins | Cardboard | Newspapers & Magazines`,
			action: '',
			type: '',
		}
	})

	useEffect(() => {
		if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
			if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
				setShowEdit(true)
			} else setShowEdit(false)
		} else setShowEdit(false)
	}, [azureLogin]);

	const handleSection2Submit = (content, file) => {
		setLoading(true)
		if (file) {
			imageUpload(file).then(res => {
				if (res.data.status) {
					let temp = { ...content }
					if (!temp.image) temp.image = { value: "" }
					temp.image.value = res.data.path;
					setSection2(temp)
					addContent({ pageName, section: "Section_2", content: temp })
						.then(result => {
							setLoading(false)
							if (result.data.status) {
								alert("Content Saved")
							}
						}).catch(error => setLoading(false))
				}
			}).catch(error => setLoading(false))
		} else {
			addContent({ pageName, section: "Section_2", content })
				.then(result => {
					setLoading(false)
					if (result.data.status) {
						alert("Content Saved")
					}
				}).catch(error => setLoading(false))
		}
	}

	const handleSection4Submit = (content, file) => {
		setLoading(true)
		if (file) {
			imageUpload(file).then(res => {
				if (res.data.status) {
					let temp = { ...content }
					if (!temp.image) temp.image = { value: "" }
					temp.image.value = res.data.path;
					setSection4(temp)
					addContent({ pageName, section: "Section_4", content: temp })
						.then(result => {
							setLoading(false)
							if (result.data.status) {
								alert("Content Saved")
							}
						}).catch(error => setLoading(false))
				}
			}).catch(error => setLoading(false))
		} else {
			addContent({ pageName, section: "Section_4", content })
				.then(result => {
					setSection4(content)
					setLoading(false)
					if (result.data.status) {
						alert("Content Saved")
					}
				}).catch(error => setLoading(false))
		}
	}

	const handleSection3Submit = (content) => {

		setLoading(true)
		setSection3(content)
		addContent({ pageName, section: "Section_3", content: { data: { value: JSON.stringify(content) } } })
			.then(result => {
				if (result.data.status) {
					alert("Content Saved")
				}
				setLoading(false)
			})

	}

	useEffect(() => {
		getPageContent({ pageName }).then((res) => {
			if (res.data.status) {
				if (res.data.result.Section_2) setSection2(res.data.result.Section_2)
				if (res.data.result.Section_3) setSection3(JSON.parse(res.data.result.Section_3.data.value))
				if (res.data.result.Section_4) setSection4(res.data.result.Section_4)
				if (res.data.result.TopBanner)
					setBannerData({
						...res.data.result.TopBanner,
						button1Status: {
							value: res.data.result.TopBanner.button1Status.value === 'true',
						},
						button2Status: {
							value: res.data.result.TopBanner.button2Status.value === 'true',
						},
					})
			}
		})
	}, [])

	return (
		<Content headerFooterMenu="mountain-west">
			<Banner
				backgroundImage="linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(/images/community-topImage.JPG)"
				title="Proud of Our Community"
				subTitle="Mountain West strives to improve our local community and state 
				through a "
				subTitleto="variety of volunteering and donations."
				bannerData={bannerData}
				showEdit={showEdit}
				setBannerData={setBannerData}
				pageName={pageName}
			/>
			{bannerData &&
				bannerData.button1 &&
				(bannerData.button1Status.value || bannerData.button2Status.value) &&
				(<div className='quote2'>
					<div className='flex2'>
						<img
							src={"/images/sponsers/sponsers.png"}
							alt="Hand Shake"
							className="quote__image"
						/>
						<h3 className='quote__text2'>
							{bannerData && bannerData.tagline
								? bannerData.tagline.value
								: "We take pride in our client relationships"}
						</h3>
					</div>
					<div className='header_buttons'>
						{bannerData &&
							bannerData.button1 &&
							bannerData.button1.value &&
							bannerData.button1Status.value && (
								<Link to={bannerData.button1Link.value} className='mw_button'>
									{bannerData.button1.value}
								</Link>
							)}
						{bannerData &&
							bannerData.button2 &&
							bannerData.button2.value &&
							bannerData.button2Status.value && (
								<Link to={bannerData.button2Link.value} className='mw_button'>
									{bannerData.button2.value}
								</Link>
							)}
					</div>
				</div>)}
			<div className="our-community-main-section  edit-feature">
				<div className="our-community-main-section-left">
					<h1 className="our-community-heading">{section2.title.value}</h1>
					<p className="text-about__heading_2">
						{HtmlReactParser(section2.description.value)}
					</p>
					<a style={{ color: 'red', fontSize: '1.6rem', textDecoration: 'underline' }} href={section2?.link?.value}>{section2.linkLabel.value}</a>
				</div>
				<div className="clip_image">
					<img src="/images/our-community-people.jpg" alt="fbf" className="fbf__image__Community" />
				</div>
				{showEdit && (
					<div
						className='mwfbi_banner__edit'
						style={{ top: '1rem' }}
						id='oc-s2-admin-edit'
						data-testid="react-select-mock"
						onClick={() => setShowSection2Modal(true)}>
						<svg>
							<use xlinkHref='/sprite.svg#icon-edit' />
						</svg>
					</div>
				)}


			</div>

			<div className="our_community_divider">
				<hr />
			</div>

			<div className="our-community-charities-content  edit-feature" >
				<h1 className="our-community-charities-heading">{section3.title.value}</h1>
				{section3.images.value && section3.images.value.length > 0 && splitArray(section3.images.value, 5).map(item => (
					<div className="charity_images">
						{item.map(ele => (
							<a href={ele.link}>
								<img
									src={ele.image}
									className="our_community_charities_image"
									alt={ele.link}
								/>
							</a>
						))}
					</div>
				))}
				<div className="mwfbi_products">
					<h1 className="our-community-heading2">{section4.title.value}</h1>
					<div className="our-community-shred-section">
						<p className="our-community-text-about">
							{section4.description.value}
						</p>

						<h2 className="our-community-shred-footer">
							{section4.description2.value}
						</h2>

						<div className="our-community-shred-card">
							<img src={section4.image.value} alt="recycle_img" className="our-community-recycleimage" />

							<div>
								<h2 className="our-community-recycle">{section4.imageTag.value}</h2>
								<h1 className="our_community_shred_recycle">{section4.imageHead.value}</h1>
							</div>
						</div>
					</div>
				</div>

				{showEdit && (
					<div
						className='mwfbi_banner__edit'
						style={{ top: '1rem' }}
						id='admin-edit'
						onClick={() => setShowSection3Modal(true)}>
						<svg>
							<use xlinkHref='/sprite.svg#icon-edit' />
						</svg>
					</div>
				)}
				{showEdit && (
					<div
						className='mwfbi_banner__edit'
						style={{ top: '50rem' }}
						id='oc-s4-admin-edit'
						data-testid="react-select-mock"
						onClick={() => setShowSection4Modal(true)}>
						<svg>
							<use xlinkHref='/sprite.svg#icon-edit' />
						</svg>
					</div>
				)}
			</div>
			<EditPageDetail
				showModal={showSection2Modal}
				setShowModal={setShowSection2Modal}
				handleYes={handleSection2Submit}
				section2={section2}
				loading={loading}
				setLoading={setLoading}
				setSection2={setSection2}
			/>
			<EditSection3Modal
				showModal={showSection3Modal}
				setShowModal={setShowSection3Modal}
				handleYes={handleSection3Submit}
				section3={section3}
				setSection3={setSection3}
				loading={loading}
				setLoading={setLoading}
			/>
			<EditSection4Modal
				showModal={showSection4Modal}
				setShowModal={setShowSection4Modal}
				handleYes={handleSection4Submit}
				section4={section4}
				loading={loading}
				setLoading={setLoading}
				setSection4={setSection4}
			/>

		</Content>
	);
};

export default OurCommunity;
