import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'

const ShowModal = ({ setShowModal, showModal, handleYes, section1, setSection1 }) => {
  const [accountNumber, setAccountNumber] = useState({ value: '', error: '' })
  const [name, setName] = useState({ value: '', error: '' })
  const [loading, setLoading] = useState(false)
  const [sessionId, setSessionId] = useState('')
  const [accessTokenId, setAccessTokenId] = useState('')
  const [uploadImagePreview, setUploadImagePreview] = useState('')
  const [uploadImage, setUploadImage] = useState(null)
  const [currentStatus, setCurrentStatus] = useState([])

  const [title, setTitle] = useState({ value: '', error: '' })
  const [subTitle, setSubTitle] = useState({ value: '', error: '' })
  const [tagline, setTagline] = useState({ value: '', error: '' })
  const [button1, setButton1] = useState({ value: '', error: '' })
  const [button2, setButton2] = useState({ value: '', error: '' })
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    // console.log(acceptedFiles);
    encodeImageFileAsURL(acceptedFiles)
  }, [])
  const encodeImageFileAsURL = (files) => {
    var file = files[0];
    setUploadImage(file)
    var reader = new FileReader()
    reader.onloadend = function () {
      if (reader.result) {
        setUploadImagePreview(reader.result)
      }
    }
    reader.readAsDataURL(file)
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop,
  })
  const setTextData = (key, value) => {
    let temp = { ...section1 };
    temp[key].value = value
    setSection1(temp)
  }
  const submit = (e) => {

    if (section1.title.value == '') {
      let temp = { ...section1 };
      temp.title.error = 'Please enter title!'
      setSection1(temp)
      return
    } else {
      let temp = { ...section1 };
      temp.title.error = ''
      setSection1(temp)
    }

    if (section1.subTitle.value == '') {
      let temp = { ...section1 };
      temp.subTitle.error = 'Please enter Sub Title!'
      setSection1(temp)
      return
    } else {
      let temp = { ...section1 };
      temp.subTitle.error = ''
      setSection1(temp)
    }
    if (section1.tagLine.value == '') {
      let temp = { ...section1 };
      temp.tagLine.error = 'Please enter Tag Line!'
      setSection1(temp)
      return
    } else {
      let temp = { ...section1 };
      temp.tagLine.error = ''
      setSection1(temp)
    }
    if (section1.button1.value == '') {
      let temp = { ...section1 };
      temp.button1.error = 'Please enter button 1 title!'
      setSection1(temp)
      return
    } else {
      let temp = { ...section1 };
      temp.button1.error = ''
      setSection1(temp)
    }
    if (section1.button2.value == '') {
      let temp = { ...section1 };
      temp.button2.error = 'Please enter button 2 title!'
      setSection1(temp)
      return
    } else {
      let temp = { ...section1 };
      temp.button2.error = ''
      setSection1(temp)
    }

    handleYes(uploadImage);
    setUploadImage(null)
    setUploadImagePreview(null)
  }
  useEffect(() => { }, [])

  return (
    <Modal show={showModal}>
      <form >
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Header</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => setShowModal(false)}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section1.title.value}
                    onChange={(e) => {
                      let temp = { ...section1 };
                      temp.title.value = e.target.value
                      setSection1(temp)
                      setTextData('title', e.target.value)
                      setTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={section1.title.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Sub Title:'
                    style={{ width: '80%' }}
                    type={'textarea'}
                    required={true}
                    value={section1.subTitle.value}
                    onChange={(e) => {
                      let temp = { ...section1 };
                      temp.subTitle.value = e.target.value
                      setSection1(temp)
                      setSubTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={section1.subTitle.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Tag Line:'
                    style={{ width: '80%' }}
                    type={'textarea'}
                    required={true}
                    value={section1.tagLine.value}
                    onChange={(e) => {
                      let temp = { ...section1 };
                      temp.tagLine.value = e.target.value
                      setSection1(temp)
                      setTagline({ error: '', value: e.target.value })
                    }}
                    errorMessage={section1.tagLine.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Button 1:'
                    required={true}
                    style={{ width: '80%' }}
                    value={section1.button1.value}
                    onChange={(e) => {
                      let temp = { ...section1 };
                      temp.button1.value = e.target.value
                      setSection1(temp)
                      setButton1({ error: '', value: e.target.value })
                    }}
                    errorMessage={section1.button1.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Button 2:'
                    required={true}
                    style={{ width: '80%' }}
                    value={section1.button2.value}
                    onChange={(e) => {
                      let temp = { ...section1 };
                      temp.button2.value = e.target.value
                      setSection1(temp)
                      setButton2({ error: '', value: e.target.value })
                    }}
                    errorMessage={section1.button2.error}
                  />
                </div>
              </div>
              <div className='modal_inner_content--right'>
                <label>About background Image</label>
                <div className='upload_border' {...getRootProps()}>
                  <input {...getInputProps()} />
                  <label>Click or Drag a image to this area to upload</label>
                  <svg className='modal_upload__icon-dark'>
                    <use xlinkHref='/sprite.svg#icon-cloud' />
                  </svg>
                </div>
                <div className='upload_preview_modal'>
                  <img height={200} src={uploadImagePreview}></img>
                </div>
              </div>
            </div>
          </div>

          <div className='modal_dark_buttons'>
            <button
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={submit} >
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </Modal >
  )
}

export default ShowModal
