export const validateJsonString = (jsonString) => {
  return jsonString
    .replace(/“/g, '\\u201c')
    .replace(/”/g, '\\u201d')
    .replace(/”/g, '\\u201d')
    .replace(/'/g, 'U0027')
    .replace(/`/g, 'U0060')
}
export const checkIsEmpty = (str) => {
  if (!str)
    return true;
  if (str == "")
    return true;
  if (str.length == 0)
    return true;
  return false;
}
export const splitArray = (array, perChunk = 3) => {
  if (Array.isArray(array) && array.length) {
    return array.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])
  }
  return []
}

export const splitArrayTo2D = (array) => {
  let newarr = [];
  let resultArr = [];
  let tempArr = [];
  array?.forEach((element) => {
    if (
      element?.FieldInputType === "Textbox" ||
      element?.FieldInputType === "Select"
    ) {
      newarr.push(element);
      if (newarr.length === 2) {
        resultArr.push(newarr);
        newarr = [];
      }
    } else {
      tempArr.push([element]);
    }
  });
  if (newarr.length !== 0) {
    resultArr.push(newarr);
    newarr = [];
  }
  return resultArr.concat(tempArr);
};
export const commaSeprator = (string) => string?.split(",")