import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import HtmlReactParser from 'html-react-parser';
import Banner from '../../../components/Banner/Banner';
import Content from '../../../components/Content/Content';
import SimpleButton from '../../../components/Inputs/SimpleButton';
import { addContent, getPageContent, imageUpload } from '../../../redux/actions/contentAction';
import { ADMIN_USERS } from '../../../redux/actions/ip';
import EditPageDetail from './edit/EditPageDetail';
import EditSection3Modal from './edit/EditSection3';
import './FarmBureauFederation.css';
import { Link } from 'react-router-dom';

let pageName = 'AboutUsFarmBureauFederation';

const FarmBureauFederation = () => {

	const azureLogin = useSelector((state) => state.azureLogin)
	const [showEdit, setShowEdit] = useState(false)
	const [showSection2Modal, setShowSection2Modal] = useState(false);
	const [showSection3Modal, setShowSection3Modal] = useState(false);
	const [loading, setLoading] = useState(false)
	const [bannerData, setBannerData] = useState({
		title: { value: 'Farm Bureau Federation', error: '' },
		subTitle: { value: 'The voice of agriculture for over 100 years', error: '', },
		tagline: { value: '', error: '' },
		button1: { value: 'START A QUOTE', error: '' },
		button1Link: { value: '/mwfbi/start-a-quote', error: '' },
		button1Status: { value: true, error: '' },
		button2: { value: 'FIND AN AGENT', error: '' },
		button2Link: { value: '/mwfbi/find-an-agent', error: '' },
		button2Status: { value: true, error: '' },
		imageUrl: { value: '/images/Dixie-Roth.JPG', error: '' },
	})
	const [section2, setSection2] = useState({
		title: { value: 'Farm Bureau Federation', action: '', type: '' },
		image: { value: '', action: '', type: '' },
		description: {
			value: `The Wyoming and Montana Farm Bureau Federations are the largest farm and ranch organizations in the states of Wyoming and Montana. Farm Bureau is the unified voice of agriculture, working through grassroots organization to enhance and strengthen the lives of rural Americans and to build strong, prosperous agricultural communities.
			<br/><br/>
			Our company was created by the Wyoming Farm Bureau in 1948 to provide quality insurance products and risk management solutions to their members. The company partnered with Montana Farm Bureau in 1958 and exists to serve the members of Farm Bureau in Wyoming and Montana.`,
			action: '',
			type: '',
		},
		link1: { value: 'Join Montana FB Federation', action: '', type: '' },
		link1Link: { value: 'https://mfbf.org/join', action: '', type: '' },
		link2: { value: 'Join Wyoming FB Federation', action: '', type: '' },
		link2Link: { value: 'https://wy.fbiris.com/join', action: '', type: '' }
	})
	const [section3, setSection3] = useState({
		title: {
			value: `We’re the state’s largest agriculture association<br/>
		and a tireless advocate for agriculture.`, action: '', type: ''
		},
		subTitle: { value: 'Learn More', action: '', type: '' },
		image1: { value: '', action: '', type: '' },
		image2: { value: '', action: '', type: '' },
		image1Link: { value: 'https://www.wyfb.org/', action: '', type: '' },
		image2Link: { value: 'https://mfbf.org/', action: '', type: '' },
		description: {
			value: `If you would like to learn more about your local Farm Bureau Federation and what services they provide to the agriculture community, click the links below to be taken to their website.`,
			action: '',
			type: '',
		}
	})
	useEffect(() => {
		if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
			if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
				setShowEdit(true)
			} else setShowEdit(false)
		} else setShowEdit(false)
	}, [azureLogin]);
	const handleSection2Submit = (content, file) => {
		setLoading(true)
		if (file) {
			imageUpload(file).then(res => {
				if (res.data.status) {
					let temp = { ...content }
					if (!temp.image) temp.image = { value: "" }
					temp.image.value = res.data.path;
					setSection2(temp)
					addContent({ pageName, section: "Section_2", content: temp })
						.then(result => {
							setLoading(false)
							if (result.data.status) {
								alert("Content Saved")
							}
						}).catch(error => setLoading(false))
				}
			}).catch(error => setLoading(false))
		} else {
			addContent({ pageName, section: "Section_2", content })
				.then(result => {
					setLoading(false)
					if (result.data.status) {
						alert("Content Saved")
					}
				}).catch(error => setLoading(false))
		}
	}
	const handleSection3Submit = (content, file, file2) => {
		if (file || file2) {
			let arr = [];
			if (file)
				arr.push(imageUpload(file))
			if (file2)
				arr.push(imageUpload(file2))
			Promise.all(arr).then(res => {
				let temp = { ...content }
				if (res.length > 1) {
					if (!temp.image1) temp.image1 = { value: "" }
					temp.image1.value = res[0].data.path;
					if (!temp.image2) temp.image2 = { value: "" }
					temp.image2.value = res[1].data.path;
				} else if (file) {
					if (!temp.image1) temp.image1 = { value: "" }
					temp.image1.value = res[0].data.path;
				} else if (file2) {
					if (!temp.image2) temp.image2 = { value: "" }
					temp.image2.value = res[0].data.path;
				}
				setSection3(temp)
				addContent({ pageName, section: "Section_3", content: temp })
					.then(result => {
						if (result.data.status) {
							alert("Content Saved")
						}
					})
			})
		} else {
			addContent({ pageName, section: "Section_3", content })
				.then(result => {
					if (result.data.status) {
						alert("Content Saved")
					}
				}).catch(error => console.error(error))
		}
	}
	useEffect(() => {
		getPageContent({ pageName }).then((res) => {
			if (res.data.status) {
				if (res.data.result.Section_2) setSection2(res.data.result.Section_2)
				if (res.data.result.Section_3) setSection3(res.data.result.Section_3)
				if (res.data.result.TopBanner)
					setBannerData({
						...res.data.result.TopBanner,
						button1Status: {
							value: res.data.result.TopBanner.button1Status.value === 'true',
						},
						button2Status: {
							value: res.data.result.TopBanner.button2Status.value === 'true',
						},
					})
			}
		})
	}, [])

	return (
		<Content headerFooterMenu="mountain-west">
			<Banner
				backgroundImage="linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),url(/images/Dixie-Roth.JPG)"
				title="Farm Bureau Federation"
				subTitle="The voice of agriculture for over 100 years"
				bannerData={bannerData}
				showEdit={showEdit}
				setBannerData={setBannerData}
				pageName={pageName}
			/>
			      {bannerData &&
            bannerData.button1 &&
            (bannerData.button1Status.value || bannerData.button2Status.value) && 
            (<div className='quote2'>
        <div className='flex2'>
        <img
             src={"/images/sponsers/sponsers.png"}
             alt="Hand Shake"
            className="quote__image"
          />
          <h3 className='quote__text2'>
            {bannerData && bannerData.tagline
              ? bannerData.tagline?.value
              : "We take pride in our client relationships"}
          </h3>
        </div>
        <div className='header_buttons'>
          {bannerData &&
            bannerData.button1 &&
            bannerData.button1Status?.value && (
              <Link to={bannerData.button1Link?.value} className='mw_button'>
                {bannerData.button1?.value}
              </Link>
            )}
          {bannerData &&
            bannerData.button2 &&
            bannerData.button2Status?.value && (
              <Link to={bannerData.button2Link?.value} className='mw_button'>
                {bannerData.button2?.value}
              </Link>
            )}
        </div>
      </div>)}
			<div className="fbf__content edit-feature" >
				<div>
					<p className="fbf_heading">{section2.title.value}</p>
					<p className="fbf__desc">
						{HtmlReactParser(section2.description.value)}
					</p>
					<div className="mwfbi__buttons">
						<a href={section2.link1Link.value}>
							<SimpleButton varient="red">{section2.link1.value}</SimpleButton>
						</a>
						<a href={section2.link2Link.value}>
							<SimpleButton varient="black">{section2.link2.value}</SimpleButton>
						</a>
					</div>
				</div>
				<div className="clip_image">
					<img src={section2.image.value} alt="fbf" className="fbf__image" />
				</div>
				{showEdit && (
					<div
						className='mwfbi_banner__edit'
						style={{ top: '1rem' }}
						id='fbf-s2-admin-edit'
						data-testid="react-select-mock"
						onClick={() => setShowSection2Modal(true)}>
						<svg>
							<use xlinkHref='/sprite.svg#icon-edit' />
						</svg>
					</div>
				)}
			</div>
			<div className="mwfbi__quote edit-feature">
				<p className="mwfbi__quote--text">
					<span>
						{HtmlReactParser(section3.title.value)}
					</span>
				</p>
				{showEdit && (
					<div
						className='mwfbi_banner__edit'
						style={{ top: '1rem' }}
						id='admin-edit'
						onClick={() => setShowSection3Modal(true)}>
						<svg>
							<use xlinkHref='/sprite.svg#icon-edit' />
						</svg>
					</div>
				)}
			</div>
			<div className="learn_more">
				<h2 className="learn_more__heading">{section3.subTitle.value}</h2>
				<p className="learn_more__subheading">
					{section3.description.value}
				</p>
				<div className="learn_more__logos">
					<a href={section3.image1Link.value}>
						<img src={section3.image1.value} alt="fbf" className="learn_more__logo1" />
					</a>
					<a href={section3.image2Link.value}>
						<img src={section3.image2.value} alt="fbf" className="learn_more__logo" />
					</a>
				</div>
			</div>

			<EditPageDetail
				showModal={showSection2Modal}
				setShowModal={setShowSection2Modal}
				handleYes={handleSection2Submit}
				section2={section2}
				loading={loading}
				setLoading={setLoading}
				setSection2={setSection2}
			/>
			<EditSection3Modal
				showModal={showSection3Modal}
				setShowModal={setShowSection3Modal}
				handleYes={handleSection3Submit}
				section3={section3}
				loading={loading}
				setLoading={setLoading}
				setSection3={setSection3}
			/>
		</Content>
	);
};

export default FarmBureauFederation;
