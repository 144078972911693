import React from "react";
import styles from "./Stepper.module.css";

const Stepper = ({ step }) => {
  let style1 = styles.step;
  let style2 = styles.step;
  let style3 = styles.step;
  let style4 = styles.step;
  let style5 = styles.step

  if (step === 1) {
    style1 = styles.Complete + " " + styles.step;
  } else if (step === 2) {
    style1 = styles.Complete + " " + styles.step;
    style2 = styles.Complete + " " + styles.step;
  } else if (step === 3) {
    style1 = styles.Complete + " " + styles.step;
    style2 = styles.Complete + " " + styles.step;
    style3 = styles.Complete + " " + styles.step;
  } else if (step === 4) {
    style1 = styles.Complete + " " + styles.step;
    style2 = styles.Complete + " " + styles.step;
    style3 = styles.Complete + " " + styles.step;
    style4 = styles.Complete + " " + styles.step;
  } else if (step === 5) {
    style1 = styles.Complete + " " + styles.step;
    style2 = styles.Complete + " " + styles.step;
    style3 = styles.Complete + " " + styles.step;
    style4 = styles.Complete + " " + styles.step;
    style5 = styles.Complete + " " + styles.step;
  }

  return (
    <div className={styles.container}>
      <div className={style1}>
        <div className={styles.bullet}>
          <span>1</span>
        </div>
        <p>STEP-1</p>
      </div>
      <div className={style2}>
        <div className={styles.bullet}>
          <span>2</span>
        </div>
        <p>STEP-2</p>
      </div>
      <div className={style3}>
        <div className={styles.bullet}>
          <span>3</span>
        </div>
        <p>STEP-3</p>
      </div>
    </div>
  );
};

export default Stepper;