import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import { Link } from 'react-router-dom'

const EditSitemap = ({ setShowModal, showModal, handleYes, section6, setSection6 }) => {
  const [loading, setLoading] = useState(false)

  const [facebookLink, setFacebookLink] = useState({ value: '', error: '' })
  const [linkedinLink, setLinkedinLink] = useState({ value: '', error: '' })
  const [twitterLink, setTwitterLink] = useState({ value: '', error: '' })
  const [InstaLink, setInstaLink] = useState({ value: '', error: '' })
  const [copyrightDesc, setCopyrightDesc] = useState({ value: '', error: '' })
  const [termsandcondition, setTermsandcondition] = useState({
    value: '',
    error: '',
  })
  const [termsandconditionLink, setTermsandconditionLink] = useState({
    value: '',
    error: '',
  })
  const [privacy, setPrivacy] = useState({ value: '', error: '' })
  const [privacyLink, setPrivacyLink] = useState({ value: '', error: '' })
  const [footerDesc, setFooterDesc] = useState({ value: '', error: '' })

  return (
    <Modal show={showModal}>
      <form onSubmit={handleYes}>
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit SiteMap</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => setShowModal(false)}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>

          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Facebook Link:'
                    style={{ width: '80%' }}
                    value={section6.facebookLink.value}
                    onChange={(e) => {
                      let temp = { ...section6 }
                      temp.facebookLink.value = e.target.value;
                      setSection6(temp)
                      setFacebookLink({ error: '', value: e.target.value })
                    }}
                    errorMessage={section6.facebookLink.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Linkedin Link:'
                    style={{ width: '80%' }}
                    value={section6.linkedinLink.value}
                    onChange={(e) => {
                      let temp = { ...section6 }
                      temp.linkedinLink.value = e.target.value;
                      setSection6(temp)
                      setLinkedinLink({ error: '', value: e.target.value })
                    }}
                    errorMessage={section6.linkedinLink.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Twitter Link:'
                    style={{ width: '80%' }}
                    value={section6.twitterLink.value}
                    onChange={(e) => {
                      let temp = { ...section6 }
                      temp.twitterLink.value = e.target.value;
                      setSection6(temp)
                      setTwitterLink({ error: '', value: e.target.value })
                    }}
                    errorMessage={section6.twitterLink.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Instagram Link:'
                    style={{ width: '80%' }}
                    value={section6.instagramLink.value}
                    onChange={(e) => {
                      let temp = { ...section6 }
                      temp.instagramLink.value = e.target.value;
                      setSection6(temp)
                      setInstaLink({ error: '', value: e.target.value })
                    }}
                    errorMessage={section6.instagramLink.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Copyright Description:'
                    style={{ width: '80%' }}
                    value={section6.copyright.value}
                    onChange={(e) => {
                      let temp = { ...section6 }
                      temp.copyright.value = e.target.value;
                      setSection6(temp)
                      setCopyrightDesc({ error: '', value: e.target.value })
                    }}
                    errorMessage={section6.copyright.error}
                  />
                </div>
              </div>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Terms and Conditions Title:'
                    style={{ width: '80%' }}
                    value={section6.termTitle.value}
                    onChange={(e) => {
                      let temp = { ...section6 }
                      temp.termTitle.value = e.target.value;
                      setSection6(temp)
                      setTermsandcondition({
                        error: '',
                        value: e.target.value,
                      })
                    }}
                    errorMessage={section6.termTitle.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Terms and Conditions Link:'
                    style={{ width: '80%' }}
                    value={section6.termLink.value}
                    onChange={(e) => {
                      let temp = { ...section6 }
                      temp.termLink.value = e.target.value;
                      setSection6(temp)
                      setTermsandconditionLink({
                        error: '',
                        value: e.target.value,
                      })
                    }}
                    errorMessage={section6.termLink.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Privacy Policy Title:'
                    style={{ width: '80%' }}
                    value={section6.policyTitle.value}
                    onChange={(e) => {
                      let temp = { ...section6 }
                      temp.policyTitle.value = e.target.value;
                      setSection6(temp)
                      setPrivacyLink({
                        error: '',
                        value: e.target.value,
                      })
                    }}
                    errorMessage={section6.policyTitle.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Privacy Policy Link:'
                    style={{ width: '80%' }}
                    value={section6.policyLink.value}
                    onChange={(e) => {
                      let temp = { ...section6 }
                      temp.policyLink.value = e.target.value;
                      setSection6(temp)
                      setPrivacy({
                        error: '',
                        value: e.target.value,
                      })
                    }}
                    errorMessage={section6.policyLink.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Footer Description:'
                    style={{ width: '80%' }}
                    value={section6.footerDescription.value}
                    onChange={(e) => {
                      let temp = { ...section6 }
                      temp.footerDescription.value = e.target.value;
                      setSection6(temp)
                      setFooterDesc({
                        error: '',
                        value: e.target.value,
                      })
                    }}
                    errorMessage={section6.footerDescription.error}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='modal_dark_buttons'>
            <button
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' type='submit'>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditSitemap
