import React, { useState } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../../components/Banner/Banner";
import Content from "../../../../components/Content/Content";
import InfoCard from "../../../../components/LandingPage/InfoCard/InfoCard";
import LinkCard from "../../../../components/LandingPage/LinkCard/LinkCard";
import EditModal from "../../../../components/LandingPage/Modals/Section1/EditModal";
import "./index.css";
import { addContent, getPageContent } from "../../../../redux/actions/contentAction";
import { useEffect } from "react";
import PdfCard from "../../../../components/LandingPage/PdfCard/PdfCard";
import ImageCard from "../../../../components/LandingPage/ImageCard/ImageCard";
import EditHeaderInfo from "./EditHeaderInfo";
import { ADMIN_USERS } from "../../../../redux/actions/ip";
import { useSelector } from "react-redux";

const AgentLandingScreen = () => {
  const [showSection1Modal, setShowSection1Modal] = useState(false);
  const azureLogin = useSelector(state => state.azureLogin)
  const [showEdit, setShowEdit] = useState(false);
  const [section1, setSection1] = useState({
    title: { value: 'Page Header', action: '', type: '' },
  })
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [bannerData, setBannerData] = useState({
    title: { value: '', error: '' },
    subTitle: { value: '', error: '', },
    tagline: { value: '', error: '' },
    button1: { value: 'START A QUOTE', error: '' },
    button1Link: { value: '/mwfbi/start-a-quote', error: '' },
    button1Status: { value: false, error: '' },
    button2: { value: 'FIND AN AGENT', error: '' },
    button2Link: { value: '/mwfbi/find-an-agent', error: '' },
    button2Status: { value: false, error: '' },
    imageUrl: { value: 'https://mwfbiportal.blob.core.windows.net/imagecontainer/images/4076772639713142-background.png', error: '' },
  })
  const [sectionArray, setSectionArray] = useState([{
    section: 'Section 1',
    title: 'CLIENTCONNECT',
    imageUrl: '/images/clientconnect.png',
    subSection: [
      {
        title: 'Business Tools',
        points: [
          { title: 'New Policy Browser', link: '' },
          { title: 'Info web Policy Document', link: '' },
          { title: 'Payment on New Business', link: '' },
          { title: 'Policy Payment', link: '' },
          { title: 'Brokerage Commissions', link: '' }
        ]
      },
      {
        title: 'Licensing Information',
        points: [
          { title: 'Montana Licensing', link: '' },
          { title: 'Wyoming Licensing', link: '' }
        ]
      },
      {
        title: 'Important Links',
        points: [
          { title: 'Homefield', link: '' },
          { title: 'Lexis Nexis', link: '' },
          { title: 'AS400', link: '' },
          { title: 'ERS Incident Report', link: '' },
          { title: 'Workfront', link: '' },
          { title: 'Meetings Website', link: '' },
          { title: 'Sales and Marketing Website', link: '' },
          { title: 'Company Store Website', link: '' },
        ]
      }
    ]
  },
  {
    section: 'Section 2',
    title: 'PORTAL PAYMENT',
    imageUrl: '/images/producer_engage.png',
    subSection: [
      {
        title: 'Claims Resources',
        points: [
          { title: 'New Policy Browser', link: '' },
          { title: 'Info web Policy Document', link: '' },
          { title: 'Payment on New Business', link: '' },
          { title: 'Policy Payment', link: '' },
          { title: 'Brokerage Commissions', link: '' }
        ]
      },
    ]
  },
  {
    section: 'Section 3',
    title: 'Start A Quote',
    imageUrl: '/images/mwoc-logo.png',
    subSection: [
      {
        title: 'State Sales Directors',
        points: [
          { title: 'Auto Insurance', link: '' },
          { title: 'Home Insurance', link: '' },
          { title: 'Farm/Ranch Insurance', link: '' },
          { title: 'Business Insurance', link: '' }
        ]
      },
      // {
      //   title: 'Last Searched Results',
      //   points: [
      //     { title: 'Adjuster Codes - PDF', link: '' },
      //     { title: 'Agent Code Listing - PDF', link: '' },
      //     { title: 'Agent Directory - PDF', link: '' },
      //     { title: 'Claims Contact & Code - PDF', link: '' }
      //   ]
      // }
    ]
  }]);

  useEffect(() => {
    if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
      if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
        setShowEdit(true)
      } else setShowEdit(false)
    } else setShowEdit(false)
  }, [azureLogin])

  const onSectionSave = (data) => {
    addContent({ pageName: 'AgentLandingPage2', section: "Section_2", content: { data: { value: JSON.stringify(data) } } })
      .then(result => {
        if (result.data.status) {
          alert("Content Saved")
        }
      })
  }

  console.log(bannerData, 'bannerData')

  useEffect(() => {
    getPageContent({ pageName: 'AgentLandingPage2' }).then((res) => {
      if (res.data.status) {
        if (res.data.result.Section_1) setSection1(res.data.result.Section_1)
        if (res.data.result.Section_2) setSectionArray(JSON.parse(res.data.result.Section_2.data.value))
        if (res.data.result.Section_2) console.log(JSON.parse(res.data.result.Section_2.data.value))
        if (res.data.result.TopBanner) {
          console.log(res.data.result.TopBanner, 'banner')
          setBannerData({
            ...res.data.result.TopBanner,
            button1Status: {
              value: res.data.result.TopBanner.button1Status.value === "true",
            },
            button2Status: {
              value: res.data.result.TopBanner.button2Status.value === "true",
            },
          });
        }
      }
    })
  }, [])

  const handleInfoSubmit = () => {
    addContent({
      pageName: 'AgentLandingPage2',
      section: 'Section_1',
      content: section1,
    }).then((result) => {
      if (result.data.status) {
        alert('Content Saved')
      }
      setShowSectionModal(false)
    })
  }

  return (
    <Content headerFooterMenu="mountain-west">
      <Banner
        // backgroundImage={bannerData.imageUrl.value}
        backgroundImage="linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),url(/images/older-couple-beach-sunset.jpg)"
        pageName={"AgentLandingPage2"}
        bannerData={bannerData}
        showEdit={showEdit}
        setBannerData={setBannerData}

      />
      <div className="quote3" style={{ width: '100%', position: 'relative' }}>
        <div className="flex1">
          <h3 className="quote__text3">{section1.title.value}</h3>
        </div>
        {showEdit && (
          <div
            style={{ top: '3rem', right: "3rem" }}
            className='mwfbi_banner__edit'
            id='fbf-s2-admin-edit'
            data-testid="react-select-mock"
            onClick={() => setShowSectionModal(true)}
          >
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}
      </div>
      {/* <div className='quote2'>
        <div className='flex2'>
          <h3 className='quote__text2'>
            {bannerData && bannerData.tagline
              ? bannerData.tagline.value
              : 'Start a home insurance quote and begin working with your local agent.'}
          </h3>
        </div>
        <div className='header_buttons'>
          {bannerData &&
            bannerData.button1 &&
            bannerData.button1Status.value && (
              <Link to={bannerData.button1Link.value} className='mw_button'>
                {bannerData.button1.value}
              </Link>
            )}
          {bannerData &&
            bannerData.button2 &&
            bannerData.button2Status.value && (
              <Link to={bannerData.button2Link.value} className='mw_button'>
                {bannerData.button2.value}
              </Link>
            )}
        </div>
      </div> */}
      <div className="main_view_landing">
        <div className="icon_grid">
          {sectionArray && sectionArray.length && sectionArray.map((headerColumn, headerColumnIndex) =>
            <InfoCard key={`${headerColumn?.title} ${headerColumnIndex}`} title={headerColumn?.title} imageUrl={headerColumn?.imageUrl} cardLink={headerColumn?.link} />)}
          {showEdit && (
            <div
              className="edit_icon mwfbi_banner__edit "
              style={{ top: "-1rem", right: "-3rem" }}
              id="admin-edit"
              onClick={() => setShowSection1Modal(true)}
            >
              <svg>
                <use xlinkHref="/sprite.svg#icon-edit" />
              </svg>
            </div>
          )}

        </div>
        <div className="options_menu">
          {sectionArray && sectionArray.length && sectionArray.map((headerColumn, headerColumnIndex) =>
            <div key={`${headerColumn?.title}option_division ${headerColumnIndex}`} className="option_division">
              <div className="option_section">
                {headerColumn.subSection.map((section, sectionIndex) =>
                (<>
                  {section.points !== [] && !section.withImageTemplate && !section.withPdfTemplate && <LinkCard title={section.title} list={section.points} />}
                  {section.withImageTemplate && !section.withPdfTemplate && <ImageCard imagePath={section.imageTemplatePath} title={section.title} />}
                  {section.withPdfTemplate && <PdfCard title={section.title} pdfUrl={section.pdfTemplatePath} />}
                </>))}
              </div>
            </div>)}
        </div>

      </div>
      <EditModal
        showModal={showSection1Modal}
        onSectionSave={onSectionSave}
        sectionArray={sectionArray}
        setSectionArray={setSectionArray}
        setShowModal={setShowSection1Modal}
      />
      <EditHeaderInfo
        handleYes={handleInfoSubmit}
        section1={section1}
        showModal={showSectionModal}
        setSection1={setSection1}
        setShowModal={setShowSectionModal}
      />
    </Content>
  );
};

export default AgentLandingScreen;
