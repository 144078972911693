import React, { useEffect, useState } from 'react';
import Banner from '../../../components/Banner/Banner';
import Content from '../../../components/Content/Content';
import { Link } from 'react-router-dom';
import { searchListArr } from './data';
import ContactModal from './ContactMe';
import { useSelector } from 'react-redux';
import htmlParse from 'html-react-parser';

const AgentDescription = ({ match }) => {
	const [contactModal, setContactModal] = useState(false);
	const [agentState, setAgentState] = useState('');
	const [agentSearchAddress, setAgentSearchAddress] = useState('');

	const { agentList } = useSelector(state => state.agentList)
	const agentId = match.params.id;
	const [agentData, setAgentData] = useState({
		id: '',
		image: '',
		title: '',
		address: '',
		address2: '',
		agentSearchAddress: '',
		mobile: '',
		email: '',
		aboutMe: '',
		lng: '',
		lat: '',
		map: '',
		phone: '',
		website2: '',
		table: [],
	});

	useEffect(
		() => {
			if (agentList && agentList.status && agentList.result.length > 0) {
				let obj = agentList.result.find((o) => o.email === agentId);
				setAgentData(obj);
			}
		},
		[agentList, agentId]
	);

	const handleClose = () => {
		setContactModal(false);
	};

	return (
		<Content headerFooterMenu="mountain-west">
			<Banner backgroundImage="linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(/images/Trust.jpg)" />
			<div className="quote">
				<div className="flex">
					<img src="/images/sponsers/sponsers.png" alt="Hand Shake" className="quote__image" />
					<h3 className="quote__text">We take pride in our client relationships.</h3>
				</div>
				<div className="header_buttons">
					{agentData && agentData.email !== '' && <Link to={`/mwfbi/start-a-quote/${agentData.email}`} className="mw_button">
						Start a Quote
					</Link>}
				</div>
			</div>

			<div className="agent_details">
				<img src={agentData.image} alt={agentData.title} className="agent_image" />
				<div className="agent_details_section">
					<div className="agent_contact_details">
						<h1 className="agent_name">{agentData.title}</h1>
						<div className="agent-content-details">
							<label className="subtitle1">
								<svg className="agent--icon">
									<use xlinkHref="/sprite.svg#icon-phone" />
								</svg>
								Office: {agentData.mobile}
							</label>

							<label className="subtitle1">
								<svg className="agent--icon">
									<use xlinkHref="/sprite.svg#icon-fax" />
								</svg>
								{agentData.phone}
							</label>
							<label className="subtitle1">
								<svg className="agent--icon">
									<use xlinkHref="/sprite.svg#icon-email" />
								</svg>
								<a className="agentemail" href={'mailto:' + agentData.email}>
									{agentData.email}
								</a>
							</label>
							<label className="subtitle1">
								<svg className="agent--icon">
									<use xlinkHref="/sprite.svg#icon-pin" />
								</svg>
								{agentData.address}
							</label>
							{agentData.address2 &&
								<span>
									<label className="subtitle1">
										<svg className="agent--icon">
											<use xlinkHref="/sprite.svg#icon-pin" />
										</svg>
										{agentData.address2}
									</label>
								</span>
							}
							{/* {agentData.website2} */}
						</div>
					</div>
					<div className="quote_minibox">
						<h1 className="quote_mini_heading">Starte a Quote</h1>
						<h2 className="quote_mini_subheading">
							Get statrted with our online quote form if you are interested in receiving a comprehensive
							insurance analysis from our office
						</h2>
						<Link to={`/mwfbi/start-a-quote/${agentData.email}`} className="quote_mini_button">
							Start a Quote
						</Link>
					</div>
				</div>
			</div>

			<div className="quote_minibox1">
				<h1 className="quote_mini_heading">Starte a Quote</h1>
				<h2 className="quote_mini_subheading">
					Get statrted with our online quote form if you are interested in receiving a comprehensive insurance
					analysis from our office
				</h2>
				<Link to={`/mwfbi/start-a-quote/${agentData.email}`} className="quote_mini_button">
					Start a Quote
				</Link>
			</div>
			<hr className="agent_horizontal_line" className="hz_line" />
			<div className="about_me_outer">
				<div className="about_me_section">
					<h1 className="about_me_heading">About Me</h1>
					<h2 className="about_me_subheading">
						<h1 className="about_me_subheading">{htmlParse(agentData.aboutMe)}</h1>
						<br />

						<br />
						<ContactModal
							setContactModal={setContactModal}
							contactModal={contactModal}
							handleClose={handleClose}
							agentId={agentId}
						/>
						<button onClick={() => setContactModal(true)} className="contact_me_button">
							Contact Me
						</button>
					</h2>
				</div>
				<div className="office_hours_section">
					<h1 className="">Office hours</h1>
					<div className="hours_section">


						<table className="ofiice_houres_table">
							{agentData.table && agentData.table.length && agentData.table.map(tbl => (<tr key={tbl.column1}>
								<td>{tbl.column1}</td>
								<td>{tbl.column2}</td>
							</tr>))}

							<tr key={'Appointment'} >
								<td colSpan="2">Also By Appointment</td>
							</tr>
						</table>`
					</div>
				</div>
			</div>
			<div className="mw_products">
				<div className="mw_products__left">
					<h3 className="mw_products__heading">
						Choose a product <span> that's right for you.</span>
					</h3>
					<br />
				</div>
				<div className="mw_products__right">
					<Link to="/mwfbi/farm-&-ranch-insurance" className="mw_products__item">
						<img src="/images/product-1.png" alt="Product 1" className="mw_products__item--image" />
						<p className="mw_products__item--name">Farm & Ranch</p>
					</Link>
					<Link to="/mwfbi/auto-insurance" className="mw_products__item">
						<img src="/images/product-4.png" alt="Product 2" className="mw_products__item--image" />
						<p className="mw_products__item--name">Auto</p>
					</Link>
					<Link to="/mwfbi/home-owners-insurance" className="mw_products__item">
						<img src="/images/product-3.png" alt="Product 3" className="mw_products__item--image" />
						<p className="mw_products__item--name">Home</p>
					</Link>
					<Link to="/mwfbi/business-insurance" className="mw_products__item">
						<img src="/images/product-2.png" alt="Product 4" className="mw_products__item--image" />
						<p className="mw_products__item--name">Business</p>
					</Link>
				</div>
			</div>

			<div className="agent_location">
				<iframe src={agentData.map} />
			</div>
		</Content >
	);
};
export default AgentDescription;
