import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import styles from './ImageOption.module.css'
import FormInputComponent from "../../../../../../../FormInputComponent/FormInputComponent";
import { useCallback } from "react";
import { imageUpload } from "../../../../../../../../redux/actions/contentAction";

const ImageOption = ({ edit, onSave, sections }) => {
  const [uploadImagePreview, setUploadImagePreview] = useState(edit ? sections.selectedItem.imageTemplatePath : "");
  const [uploadImage, setUploadImage] = useState(null);
  const [title, setTitle] = useState({ value: edit ? sections.selectedItem.title : '', error: '' })

  const onDrop = useCallback((acceptedFiles) => {
    encodeImageFileAsURL(acceptedFiles);
  }, []);

  const encodeImageFileAsURL = (files) => {
    var file = files[0];
    setUploadImage(file);
    var reader = new FileReader();
    reader.onloadend = function () {
      if (reader.result) {
        setUploadImagePreview(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg,image/png",
    onDrop,
  });

  const handleSubmit = async () => {
    if (title.value === '') {
      setTitle({ ...title, error: 'Please Enter the title!' });
      return
    }
    if (!uploadImagePreview) {
      setUploadImage({ error: 'Please select image!' })
      return
    }
    if (uploadImage) {
      const uploadedFile = await imageUpload(uploadImage);
      // console.log("Image", { title: title.value, imageTemplatePath: uploadedFile.data.path, withImageTemplate: true, points: [] });
      onSave({ title: title.value, imageTemplatePath: uploadedFile.data.path, withImageTemplate: true, points: [] })
    } else onSave({ title: title.value, imageTemplatePath: sections.selectedItem.imageTemplatePath, withImageTemplate: true, points: [] })
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ width: '50%' }}>
        <FormInputComponent
          headingStyle={styles.inputHeading}
          height={"5rem"}
          style={{ width: "30rem" }}
          required
          value={title.value}
          heading={"Title"}
          onChange={(e) => {
            setTitle({ error: '', value: e.target.value })
          }}
          errorMessage={title.error}
        // value={title.value}
        />
        <div className="modal_inner_content--right3" style={{ padding: '0rem', alignItems: 'inherit' }}>
          <label
            style={{
              fontSize: "1.6rem",
              marginTop: "1rem",
              color: "#808080",
            }}
          >
            Image
          </label>
          {/* <PdfUploader uploadImage={uploadImage} setUploadImage={setUploadImage} /> */}
          <div
            className="upload_border"
            {...getRootProps()}
            style={{ marginTop: "-1rem", width: "302px" }}
          >
            <input {...getInputProps()} />
            {uploadImagePreview === "" ? (
              <>
                <svg className="modal_upload__icon-dark">
                  <use xlinkHref="/sprite.svg#icon-cloud" />
                </svg>
                <label>Please choose image to upload</label>
              </>
            ) : (
              <img
                alt="upload icon"
                height={90}
                width={140}
                src={uploadImagePreview}
                style={{ marginTop: "0rem", alignSelf: "center" }}
              />
            )}
            {uploadImage && uploadImage.error && (
              <p className="form_input__error">{uploadImage.error}</p>
            )}
          </div>
        </div>
      </div>
      <button
        style={{
          alignSelf: 'end',
          marginTop: '-4px',
        }}
        type="button"
        className="modal__button-reset-dark"
        onClick={handleSubmit}
      >
        Save
      </button>
    </div>
  )
}

export default ImageOption;