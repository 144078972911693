import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import ReactPaginate from 'react-paginate'
import { deleteContentItem, imageUpload, addContentItem } from '../../../../redux/actions/contentAction'

const itemsPerPage = 10

const EditFindAgent = ({ setShowModal, showModal, handleYes, section3, setSection3, searchListArr, setSearchListArr }) => {
  const [accountNumber, setAccountNumber] = useState({ value: '', error: '' })

  const [loading, setLoading] = useState(false)
  const [sessionId, setSessionId] = useState('')
  const [accessTokenId, setAccessTokenId] = useState('')
  const [uploadImagePreview, setUploadImagePreview] = useState('')
  const [uploadImage, setUploadImage] = useState(null)
  const [currentStatus, setCurrentStatus] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [currentItems, setCurrentItems] = useState(null)

  console.log('currentItems', section3)

  const [name, setName] = useState({ value: '', error: '' })
  const [email, setEmail] = useState({ value: '', error: '' })
  const [phone, setPhone] = useState({ value: '', error: '' })
  const [mobile, setMobile] = useState({ value: '', error: '' })
  const [address, setAddress] = useState({ value: '', error: '' })
  const [address2, setAddress2] = useState({ value: '', error: '' })
  const [lat, setLat] = useState({ value: '', error: '' })
  const [long, setLong] = useState({ value: '', error: '' })
  const [map, setMap] = useState({ value: '', error: '' })
  const [city, setCity] = useState({ value: '', error: '' })
  const [state, setState] = useState({ value: '', error: '' })
  const [website, setWebsite] = useState({ value: '', error: '' })
  const [aboutMe, setAboutMe] = useState({ value: '', error: '' })
  const [webLink, setLink] = useState({ value: '', error: '' })

  const [editIndex, setEditIndex] = useState(-1)
  const [editData, setEditData] = useState({
    name: { value: "", error: "" },
    email: { value: "", error: "" },
    phone: { value: "", error: "" },
    city: { value: "", error: "" },
    state: { value: "", error: "" },
    address: { value: "", error: "" },
    image: { value: "", error: "" },
    lat: { value: "", error: "" },
    long: { value: "", error: "" },
    about: { value: "", error: "" },
    map: { value: "", error: "" }
  })

  const [officeHoursList, setOfficeHoursList] = useState([])
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    // console.log(acceptedFiles);
    encodeImageFileAsURL(acceptedFiles)
  }, [])
  const encodeImageFileAsURL = (files) => {
    var file = files[0]
    setUploadImage(file)
    var reader = new FileReader()
    reader.onloadend = function () {
      console.log('RESULT', reader.result)
      if (reader.result) {
        setUploadImagePreview(reader.result)
      }
    }
    reader.readAsDataURL(file)
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop,
  })

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage
    console.log(`Loading items from ${itemOffset} to ${endOffset}`)
    setCurrentItems(searchListArr.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(searchListArr.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, searchListArr])
  // useEffect(() => {
  //   // Filter the state wise agent

  //   const array = searchListArr.filter(item => !item.city)
  //     .map(item => {
  //       let data = item.address.split(",");

  //       if (data.length > 2)
  //         item.city = data[data.length - 2];
  //       else if (data.length === 2) {
  //         const sp1 = data[0].replace('\n',' ').split(' ');
  //         item.city = sp1[sp1.length - 1];
  //       }
  //       console.log(data.address);
  //       return item
  //     })
  //   .map(content => addContentItem({ key: "Agent", primaryKey: content.email, content }))
  //   console.log('Without State', array)

  // }, [searchListArr])

  const onPageChange = ({ selected }) => {
    const newOffset = (selected * itemsPerPage) % searchListArr.length

    setItemOffset(newOffset)
  }
  const handleAgentSubmit = () => {
    if (name.value === '') {
      setName({ ...name, error: 'Please enter Name!' });
      return
    }
    if (email.value === '') {
      setEmail({ ...email, error: 'Please enter Email!' });
      return
    }
    if (address.value === '') {
      setAddress({ ...address, error: 'Please enter Address!' });
      return
    }
    if (city.value === '') {
      setCity({ ...state, error: 'Please enter city!' });
      return
    }
    if (state.value === '') {
      setState({ ...state, error: 'Please select state!' });
      return
    }
    if (aboutMe.value === '') {
      setAboutMe({ ...aboutMe, error: 'Please enter about me!' });
      return
    }
    clearErrors()
    let content = {
      image: uploadImagePreview,
      title: name.value,
      address2: address2.value,
      address: address.value,
      mobile: mobile.value,
      email: email.value,
      aboutMe: aboutMe.value,
      lng: long.value,
      lat: lat.value,
      city: city.value,
      map: map.value,
      phone: phone.value,
      website2: website.value,
      table: officeHoursList,
      state: state.value
    }
    if (!content.state) {
      if (content.address.match('MT'))
        content.state = "Montana";
      else if (content.address.match('WY'))
        content.state = "Wyoming";
    }
    setLoading(true)
    if (uploadImage) {
      imageUpload(uploadImage).then(res => {
        if (res.data.status) {
          setUploadImagePreview(res.data.path);
          content.image = res.data.path;
          addContentItem({ key: "Agent", primaryKey: content.email, content })
            .then(result => {
              setLoading(false)
              clear();
              resetAll()
              if (result.data.status) {
                alert("Content Saved")
              }
            }).catch(err => setLoading(false))
        }
      })
    } else {
      addContentItem({ key: "Agent", primaryKey: content.email, content })
        .then(result => {
          setLoading(false)
          clear();
          resetAll()
          if (result.data.status) {
            alert("Content Saved")
          }
        }).catch(err => setLoading(false))
    }
  }

  const onEdit = (item, index) => {
    // e.preventDefault();
    // setLoading(true);
    resetAll()
    setTimeout(() => {
      setEditData(item)
      setEditIndex(index)
      setName({ ...name, value: item.title })
      setEmail({ ...email, value: item.email })
      setPhone({ ...phone, value: item.phone })
      setMobile({ ...mobile, value: item.mobile })
      setAddress({ ...address, value: item.address })
      setAddress2({ ...address2, value: item.address2 })
      setLat({ ...lat, value: item.lat })
      setLong({ ...long, value: item.lng })
      setMap({ ...map, value: item.map })
      setState({ ...map, value: item.state })
      setCity({ ...map, value: item.city })
      setWebsite({ ...website, value: item.website2 })
      setAboutMe({ ...website, value: item.aboutMe })
      setUploadImagePreview(item.image)
      setOfficeHoursList(item.table)
    }, 500)
  }
  const clear = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      name: { value: "", error: "" },
      email: { value: "", error: "" },
      phone: { value: "", error: "" },
      city: { value: "", error: "" },
      state: { value: "", error: "" },
      address: { value: "", error: "" },
      image: { value: "", error: "" },
      lat: { value: "", error: "" },
      long: { value: "", error: "" },
      about: { value: "", error: "" },
      map: { value: "", error: "" }
    })
    setEditIndex(-1)
    setShowModal(false)
    // handleAgentSubmit()
  }
  const onHoursAdd = () => {
    setOfficeHoursList([...officeHoursList, {
      column1: "",
      column2: ""
    }])
  }
  const closeModal = () => {
    resetAll();
    setShowModal(false)
  }
  const resetAll = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      name: { value: "", error: "" },
      email: { value: "", error: "" },
      phone: { value: "", error: "" },
      city: { value: "", error: "" },
      state: { value: "", error: "" },
      address: { value: "", error: "" },
      image: { value: "", error: "" },
      lat: { value: "", error: "" },
      long: { value: "", error: "" },
      about: { value: "", error: "" },
      map: { value: "", error: "" }
    })
    setEditIndex(-1)
    setName({ ...name, value: "", error: "" })
    setEmail({ ...email, value: "", error: "" })
    setPhone({ ...phone, value: "", error: "" })
    setMobile({ ...mobile, value: "", error: "" })
    setAddress({ ...address, value: "", error: "" })
    setAddress2({ ...address2, value: "", error: "" })
    setLat({ ...lat, value: "", error: "" })
    setLong({ ...long, value: "", error: "" })
    setMap({ ...map, value: "", error: "" })
    setAboutMe({ ...map, value: "", error: "" })
    setState({ ...state, value: "", error: "" })
    setCity({ ...city, value: "", error: "" })
    setWebsite({ ...website, value: "", error: "" })
    setUploadImagePreview('')
    setUploadImage(null)
    setOfficeHoursList([])
  }
  const clearErrors = () => {

    setName({ ...name, error: "" })
    setEmail({ ...email, error: "" })
    setPhone({ ...phone, error: "" })
    setMobile({ ...mobile, error: "" })
    setAddress({ ...address, error: "" })
    setAddress2({ ...address2, error: "" })
    setLat({ ...lat, error: "" })
    setLong({ ...long, error: "" })
    setMap({ ...map, error: "" })
    setState({ ...state, error: "" })
    setCity({ ...city, error: "" })
    setAboutMe({ ...map, error: "" })
    setWebsite({ ...website, error: "" })
    setOfficeHoursList([])
  }
  const onOfcRemove = (ofcIndex) => {
    if (window.confirm('Are You Sure to Delete?')) {
      let temp = [...officeHoursList];
      temp.splice(ofcIndex, 1);
      setOfficeHoursList(temp)
    }
  }
  const onAgentDelete = (item, index) => {
    if (window.confirm('Are You Sure to Delete?')) {
      deleteContentItem({ key: "Agent", primaryKey: item.email }).then(res => {
        let temp = [...searchListArr];
        temp.splice(index, 1);
        setSearchListArr(temp)
        if (res.data.status) {
          alert("Agent has been deleted!");
        }
      })
    }
  }
  return (
    <Modal show={showModal}>
      <form onSubmit={handleYes}>
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Find an Agent</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => closeModal()}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Name:'
                    style={{ width: '50%' }}
                    required={true}
                    value={name.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.name.value = e.target.value
                      // setEditData(temp)
                      setName({ error: '', value: e.target.value })
                    }}
                    errorMessage={name.error}
                  />
                  <FormInputComponent
                    heading='Email:'
                    style={{ width: '50%' }}
                    required={true}
                    value={email.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.email.value = e.target.value
                      // setEditData(temp)
                      setEmail({ error: '', value: e.target.value })
                    }}
                    errorMessage={email.error}
                  />
                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Phone:'
                    style={{ width: '80%' }}
                    value={phone.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.phone.value = e.target.value
                      // setEditData(temp)
                      setPhone({ error: '', value: e.target.value })
                    }}
                    errorMessage={phone.error}
                  />
                  <FormInputComponent
                    heading='Mobile:'
                    style={{ width: '80%' }}
                    value={mobile.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.phone.value = e.target.value
                      // setEditData(temp)
                      setMobile({ error: '', value: e.target.value })
                    }}
                    errorMessage={mobile.error}
                  />
                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Address:'
                    style={{ width: '50%' }}
                    type={'textarea'}
                    required={true}
                    value={address.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.address.value = e.target.value
                      // setEditData(temp)
                      setAddress({ error: '', value: e.target.value })
                    }}
                    errorMessage={address.error}
                  />
                  <FormInputComponent
                    heading='Address 2:'
                    style={{ width: '50%' }}
                    type={'textarea'}
                    value={address2.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.address.value = e.target.value
                      // setEditData(temp)
                      setAddress2({ error: '', value: e.target.value })
                    }}
                    errorMessage={address2.error}
                  />

                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='City'
                    style={{ width: '50%' }}
                    required={true}
                    value={city.value}
                    onChange={(e) => {
                      setCity({ error: '', value: e.target.value })
                    }}
                    errorMessage={city.error}
                  />
                  <div className="select_state" style={{ width: '50%' }}>
                    <label className="label_heading_quote">
                      Select a state <span className="red_star">&#42;</span>
                    </label>
                    <br />
                    <select
                      style={{ marginBottom: '0', width: '100%' }}
                      id="selectstate"
                      name="selectstate"
                      value={state.value}
                      className="select__state__textbox"
                      onChange={(e) => setState({ error: '', value: e.target.value })}
                    >
                      <option value="">Select</option>
                      <option value="Wyoming" id="Wyoming">
                        Wyoming
                      </option>
                      <option value="Montana" id="Montana">
                        Montana
                      </option>
                    </select>{" "}
                    <br />
                    {state.error && (
                      <p className="form_input__error">{state.error}</p>
                    )}
                  </div>
                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Lat:'
                    style={{ width: '50%' }}
                    value={lat.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.email.value = e.target.value
                      // setEditData(temp)
                      setLat({ error: '', value: e.target.value })
                    }}
                    errorMessage={lat.error}
                  />
                  <FormInputComponent
                    heading='Long:'
                    style={{ width: '50%' }}
                    value={long.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.email.value = e.target.value
                      // setEditData(temp)
                      setLong({ error: '', value: e.target.value })
                    }}
                    errorMessage={long.error}
                  />
                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Map Url:'
                    style={{ width: '100%' }}
                    value={map.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.phone.value = e.target.value
                      // setEditData(temp)
                      setMap({ error: '', value: e.target.value })
                    }}
                    errorMessage={map.error}
                  />
                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Website:'
                    style={{ width: '100%' }}
                    type={'text'}
                    value={website.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.city.value = e.target.value
                      // setEditData(temp)
                      setWebsite({ error: '', value: e.target.value })
                    }}
                    errorMessage={website.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='About Me:'
                    style={{ width: '100%' }}
                    type={'textarea'}
                    required={true}
                    value={aboutMe.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.city.value = e.target.value
                      // setEditData(temp)
                      setAboutMe({ error: '', value: e.target.value })
                    }}
                    errorMessage={aboutMe.error}
                  />
                </div>

              </div>
              <div className='modal_inner_content--right3'>
                <label>Upload Profile Picture</label>
                <div
                  className='upload_border'

                  {...getRootProps()}
                  style={{ marginTop: '-1rem' }}>
                  <input {...getInputProps()} />
                  <label>Click or Drag a image to this area to upload</label>
                  {uploadImagePreview == '' ? <svg className='modal_upload__icon-dark'>
                    <use xlinkHref='/sprite.svg#icon-cloud' />
                  </svg> : <img
                    height={120}
                    src={uploadImagePreview}
                    style={{ marginTop: '-5rem' }}></img>}
                </div>
                <div style={{ marginTop: '8rem' }}>
                  <table className='office_hours_css' style={{ height: '15rem !important', marginTop: '-8rem', width: '30rem', overflow: 'scroll' }}>
                    <tr>
                      <td colSpan="2" style={{ border: 'none', textAlign: 'center' }}>Office Hours</td>
                      <td style={{ border: 'none', textAlign: 'center' }}>
                        <svg className='modal_upload__icon-dark' style={{ height: '2rem' }} onClick={onHoursAdd}>
                          <use xlinkHref='/sprite.svg#icon-plus' /></svg></td>
                    </tr>
                    <tr>
                      <td style={{ border: 'none', textAlign: 'left', width: '30%' }} >Day</td>
                      <td style={{ border: 'none', textAlign: 'left', width: '50%' }} >Time</td>
                      <td style={{ border: 'none', textAlign: 'center', width: '20%' }} >Delete</td>
                    </tr>
                    {officeHoursList && officeHoursList.map((ofc, ofcIndex) => (<tr>
                      <td style={{ border: 'none', textAlign: 'center' }} >
                        <input style={{ width: '100%' }} type={'text'} value={ofc.column1} onChange={(e) => {
                          let temp = [...officeHoursList];
                          temp[ofcIndex].column1 = e.target.value;
                          setOfficeHoursList(temp)
                        }}></input>
                      </td>
                      <td style={{ border: 'none', textAlign: 'center' }} > <input style={{ width: '100%' }} type={'text'} value={ofc.column2} onChange={(e) => {
                        let temp = [...officeHoursList];
                        temp[ofcIndex].column2 = e.target.value;
                        setOfficeHoursList(temp)
                      }}></input></td>
                      <td style={{ border: 'none', textAlign: 'center' }} ><svg className='modal_upload__icon-dark' onClick={(e) => onOfcRemove(ofcIndex)}>
                        <use xlinkHref='/sprite.svg#icon-cross' /></svg></td>
                    </tr>))}

                    <tr>
                      <td colSpan="3" style={{ textAlign: 'center' }}>Also By Appointment</td>
                    </tr>
                  </table>
                </div>
                <div
                  className='modal_dark_buttons'
                  style={{ padding: '0.5rem' }}>
                  <button type='button' className='modal__button-reset-dark' onClick={resetAll}>Reset</button>
                  <button type='button' className='modal__button-dark' onClick={handleAgentSubmit}>{loading ? 'Saving..' : (editIndex > -1 ? 'Save' : 'Add')}</button>
                </div>
              </div>
            </div>
            <div className='modal_inner_content'>
              <div className='modal_form_container'>
                <table
                  className='modal__form-table'
                  cellspacing='0'
                  cellpadding='0'>
                  <thead className='modal__form-sticky'>
                    <tr className='modal__form-tr'>
                      <th>No.</th>
                      <th>Agent Name</th>
                      <th>Agent Email</th>
                      <th>Agent Phone</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentItems && currentItems &&
                      currentItems.map((item, i) => {
                        return (
                          <tr className='modal__form-tr2' key={i}>
                            <td>{i + 1}</td>
                            <td>{item.title}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                            <td className='modal__form-buttons'>
                              <button type='button' className='modal__button-edit' onClick={(e) => onEdit(item, i)}>
                                edit
                              </button>
                              <button type='button' className='modal__button-delete'
                                onClick={(e) => onAgentDelete(item, i)}
                              >
                                delete
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='ms-pagination2'>
              <h1 className='paginator_head'>
                {' '}
                Showing {itemOffset + 1} - {itemOffset + currentItems?.length}{' '}
                of {searchListArr.length} results
              </h1>

              <ReactPaginate
                nextClassName={''}
                breakLabel='...'
                nextLabel='>>'
                pageCount={pageCount}
                onPageChange={onPageChange}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                previousLabel='<<'
                // renderOnZeroPageCount={null}
                containerClassName={'paginationBttns'}
                previousLinkClassName={'previousBttn'}
                nextLinkClassName={'nextBttn'}
                disabledClassName={'paginationDisabled'}
                activeClassName={'paginationActive'}
              />
            </div>
          </div>

          {/* <div className='modal_dark_buttons' style={{ paddingTop: '0' }}>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={(e) => handleAgentSubmit()}>
                Submit
              </button>
            )}
          </div> */}
        </div>
      </form>
    </Modal>
  )
}

export default EditFindAgent
