import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Content from '../../../components/Content/Content'
import Button from '../../../components/Inputs/Button'
import { HashLink } from 'react-router-hash-link'

import './HomeScreen.css'
import PaymentModal from './PaymentModal'
import EditHomeModal from './edit/EditHome'
import EditProductModal from './edit/EditProduct'
import EditAboutModal from './edit/EditAbout'
import EditServicesModal from './edit/EditServices'
import EditContactModal from './edit/EditContact'
import {
  addContent,
  getPageContent,
  imageUpload,
} from '../../../redux/actions/contentAction'
import ReactHtmlParser from 'html-react-parser'
import { useSelector } from 'react-redux'
import { ADMIN_USERS } from '../../../redux/actions/ip'

const HomeScreen = () => {
  const azureLogin = useSelector((state) => state.azureLogin)
  const [showEdit, setShowEdit] = useState(false)
  const [checked, setChecked] = useState('checked1')
  const [paymentModal, setPaymentModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showProductModal, setProductModal] = useState(false)
  const [showAboutModal, setShowAboutModal] = useState(false)
  const [showServiceModal, setShowServiceModal] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)
  // const login = localStorage.getItem(az) ? JSON.parse(localStorage.getItem(az)) : null
  const [section1, setSection1] = useState({
    title: { value: 'Local Insurance', action: '', type: '' },
    image: { value: '/images/header-img.jpg', action: '', type: '' },
    subTitle: {
      value:
        'For Mountain West Farm Owners, Ranchers, Families and Business Owners.',
      action: '',
      type: '',
    },
    tagLine: {
      value: 'We take pride in our client relationships.',
      action: '',
      type: '',
    },
    button1: {
      value: 'FIND YOUR LOCAL AGENT',
      action: 'mwfbi/find-an-agent',
      type: '',
    },
    button2: {
      value: 'START A QUOTE',
      action: 'mwfbi/start-a-quote',
      type: '',
    },
  })
  const [section2, setSection2] = useState({
    title: { value: 'Choose a product', action: '', type: '' },
    subTitle: { value: 'thats right for you', action: '', type: '' },
    productList: [
      {
        title: 'Farm & Ranch',
        action: '/mwfbi/farm-&-ranch-insurance',
        iconName: '/images/product-1.png',
      },
      {
        title: 'Auto',
        action: '/mwfbi/auto-insurance',
        iconName: '/images/product-4.png',
      },
      {
        title: 'Home',
        action: '/mwfbi/home-owners-insurance',
        iconName: '/images/product-3.png',
      },
      {
        title: 'Business',
        action: '/mwfbi/business-insurance',
        iconName: '/images/product-2.png',
      },
    ],
  })
  const [section3, setSection3] = useState({
    image: { value: '/images/farm-insurance.png', action: '', type: '' },
    title: { value: 'About', action: '', type: '' },
    description: {
      value: `Whether you are a new home owner, or starting your business -
    you can rely on our experience to help you cover what matters
    most. <br /> Mountain West is a multi-line insurance company,
    serving the needs of individuals, families, and businesses
    throughout Wyoming and Montana for over 70 years. Our mission
    is to provide financial peace of mind of our customers with
    competitive, quality products and services.`,
      action: '',
      type: '',
    },
    button: { value: 'About Us', action: '/mwfbi/about-us', type: '' },
  })
  const [section4, setSection4] = useState({
    serviceImage: { value: '/images/fallen-tree2.jpg', action: '', type: '' },
    serviceTitle: { value: 'Claims Service', action: '', type: '' },
    serviceSubTitle: {
      value: `Taking care of our customers`,
      action: '',
      type: '',
    },
    serviceButton: { value: 'CLAIMS', action: '/mwfbi/about-us', type: '' },
    packageImage: { value: '/images/house-car.jpg', action: '', type: '' },
    packageTitle: { value: 'PACKAGE POLICIES', action: '', type: '' },
    packageSubTitle: {
      value: `Bundle & Save Auto + Home`,
      action: '',
      type: '',
    },
    packageButton: { value: 'DISCOUNTS', action: '/mwfbi/about-us', type: '' },
  })
  const [section5, setSection5] = useState({
    title: { value: 'Nobody wants to be stranded', action: '', type: '' },
    image: { value: '/images/car-sunrise.jpg', action: '', type: '' },
    subTitle: {
      value: 'Thats why we have Emergency Roadside Service',
      action: '',
      type: '',
    },
    tagLine: { value: 'Need ERS? 1-888-292-3308', action: '', type: '' },
    button1: {
      value: 'AUTO INSURANCE',
      action: 'mwfbi/find-an-agent',
      type: '',
    },
  })

  const handleHeaderSubmit = (file) => {
    if (file) {
      imageUpload(file).then((res) => {
        if (res.data.status) {
          let temp = { ...section1 }
          if (!temp.image) temp.image = { value: '' }
          temp.image.value = res.data.path
          setSection1(temp)
          addContent({
            pageName: 'MW_Home',
            section: 'Section_1',
            content: temp,
          }).then((result) => {
            console.log(result.data)
            if (result.data.status) {
              alert('Content Saved')
            }
          })
        }
      })
    } else {
      addContent({
        pageName: 'MW_Home',
        section: 'Section_1',
        content: section1,
      }).then((result) => {
        if (result.data.status) {
          alert('Content Saved')
        }
      })
    }
    setShowModal(false)
  }
  const handleSection2Submit = () => {
    let temp = {
      ...section2,
      productList: {
        value: JSON.stringify(section2.productList),
        type: 'json',
      },
    }
    addContent({
      pageName: 'MW_Home',
      section: 'Section_2',
      content: temp,
    }).then((result) => {
      if (result.data.status) {
        alert('Content Saved')
      }
    })
    setProductModal(false)
  }
  const handleSection3Submit = (file) => {
    if (file) {
      imageUpload(file).then((res) => {
        if (res.data.status) {
          let temp = { ...section3 }
          if (!temp.image) temp.image = { value: '' }
          temp.image.value = res.data.path
          setSection3(temp)
          addContent({
            pageName: 'MW_Home',
            section: 'Section_3',
            content: temp,
          }).then((result) => {
            console.log(result.data)
            if (result.data.status) {
              alert('Content Saved')
            }
          })
        }
      })
    } else {
      addContent({
        pageName: 'MW_Home',
        section: 'Section_3',
        content: section3,
      }).then((result) => {
        console.log(result.data)
        if (result.data.status) {
          alert('Content Saved')
        }
      })
    }
    setShowAboutModal(false)
  }
  const submit4Async = async (file, claim) => {
    let serviceFile = null,
      claimFile = null
    try {
      if (file) {
        let data = await imageUpload(file)
        if (data && data.data && data.data.status) serviceFile = data.data.path
      }
      if (claim) {
        let data = await imageUpload(claim)
        if (data && data.data && data.data.status) claimFile = data.data.path
      }
      let temp = { ...section4 }
      if (serviceFile) {
        temp.serviceImage.value = serviceFile
      }
      if (claimFile) {
        temp.packageImage.value = claimFile
      }
      setSection4(temp)
      addContent({
        pageName: 'MW_Home',
        section: 'Section_4',
        content: temp,
      }).then((result) => {
        setShowServiceModal(false)
        if (result.data.status) {
          alert('Content Saved')
        }
      })
    } catch (error) { }
  }
  const handleSection4Submit = (file, packageImage) => {
    submit4Async(file, packageImage).then((res) => { })

    // if (file) {
    //   imageUpload(file).then(res => {
    //     if (res.data.status) {
    //       console.log(res.data);
    //       let temp = { ...section4 }
    //       if (!temp.serviceImage)
    //         temp.serviceImage = { value: "" }
    //       temp.serviceImage.value = res.data.path;
    //       setSection4(temp)
    //       addContent({ pageName: "MW_Home", section: "Section_4", content: temp })
    //         .then(result => {
    //           console.log(result.data);
    //           if (result.data.status) {
    //             alert("Content Saved")
    //           }
    //         })
    //     }
    //   })
    // } else {
    //   addContent({ pageName: "MW_Home", section: "Section_4", content: section4 })
    //     .then(result => {
    //       console.log(result.data);
    //       if (result.data.status) {
    //         alert("Content Saved")
    //       }
    //     })
    // }

    // setShowServiceModal(false)
  }
  const handleSection5Submit = (file) => {
    if (file) {
      imageUpload(file).then((res) => {
        if (res.data.status) {
          console.log(res.data)
          let temp = { ...section4 }
          if (!temp.image) temp.image = { value: '' }
          temp.image.value = res.data.path
          setSection5(temp)
          addContent({
            pageName: 'MW_Home',
            section: 'Section_5',
            content: temp,
          }).then((result) => {
            console.log(result.data)
            if (result.data.status) {
              alert('Content Saved')
            }
          })
        }
      })
    } else {
      addContent({
        pageName: 'MW_Home',
        section: 'Section_5',
        content: section5,
      }).then((result) => {
        console.log(result.data)
        if (result.data.status) {
          alert('Content Saved')
        }
      })
    }

    setShowContactModal(false)
  }
  useEffect(() => {
    if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
      if (
        ADMIN_USERS.includes(azureLogin.userInfo.account.username)
      ) {
        setShowEdit(true)
      } else setShowEdit(false)
    } else setShowEdit(false)
  }, [azureLogin])
  useEffect(() => {
    getPageContent({ pageName: 'MW_Home' }).then((res) => {
      if (res.data.status) {
        if (res.data.result.Section_1) setSection1(res.data.result.Section_1)
        if (res.data.result.Section_2) {
          let s2 = res.data.result.Section_2
          s2.productList = JSON.parse(s2.productList.value)
          setSection2(s2)
        }
        if (res.data.result.Section_3) setSection3(res.data.result.Section_3)
        if (res.data.result.Section_4) setSection4(res.data.result.Section_4)
        if (res.data.result.Section_5) setSection5(res.data.result.Section_5)
      }
    })
  }, [])
  return (
    <Content headerFooterMenu='mountain-west'>
      <div
        className='mw_slider'
        style={{
          backgroundImage: `url(${section1.image ? section1.image.value : '/images/header-img.jpg'
            }) `,
        }}>
        <div className='mw_slider__left'>
          <h1 className='mw_slider__heading'>{section1.title.value}</h1>
          <h3 className='mw_slider__subheading'>{section1.subTitle.value}</h3>
        </div>
        <div className='mw_slider__right'>
          {/* <Link to='mwfbi/find-an-agent' className='mw_button'>
            {section1.button1.value}
          </Link> */}
          <Link to={section1.button1.action} className='mw_button'>
            {section1.button1.value}
          </Link>
        </div>
        {showEdit && (
          <div
            className='mwfbi_banner__edit'
            id='admin-edit'
            onClick={() => setShowModal(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}
      </div>
      <EditHomeModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleYes={handleHeaderSubmit}
        section1={section1}
        setSection1={setSection1}
      />
      <div className='quote'>
        <div className='flex'>
          <img
            src='/images/sponsers/sponsers.png'
            alt='Hand Shake'
            className='quote__image'
          />
          <h3 className='quote__text'>{section1.tagLine.value}</h3>
        </div>
        <div className='header_buttons'>
          {/* <Link to='/mwfbi/start-a-quote' className='mw_button'>
            Start a Quote
          </Link> */}
          <Link to={section1.button2.action} className='mw_button'>
            {section1.button2.value}
          </Link>

          {/* <button className="mw_button" onClick={() => setPaymentModal(true)}>
						Manage Payment Method
					</button> */}
        </div>
        {/* <PaymentModal setPaymentModal={setPaymentModal} paymentModal={paymentModal} /> */}
      </div>

      <div className='mw_products'>
        <div className='mw_products__left'>
          <h3 className='mw_products__heading'>
            {section2.title.value} <span> {section2.subTitle.value}</span>
          </h3>
          <br />
          {/* <div className="hide_button">
						<Button label="view all products" varient="red" icon="/sprite.svg#icon-search" />
					</div> */}
          {/* <Link to='/' className='mw_button'>
						View All Products
					</Link> */}
        </div>
        <div className='mw_products__right'>
          {section2.productList &&
            section2.productList.map((item) => (
              <Link to={item.action} className='mw_products__item'>
                <img
                  src={item.iconName}
                  alt={item.iconName}
                  className='mw_products__item--image'
                />
                <p className='mw_products__item--name'>{item.title}</p>
              </Link>
            ))}
          {/* <Link to='/mwfbi/auto-insurance' className='mw_products__item'>
            <img
              src='/images/product-4.png'
              alt='Product 2'
              className='mw_products__item--image'
            />
            <p className='mw_products__item--name'>Auto</p>
          </Link>
          <Link to='/mwfbi/home-owners-insurance' className='mw_products__item'>
            <img
              src='/images/product-3.png'
              alt='Product 3'
              className='mw_products__item--image'
            />
            <p className='mw_products__item--name'>Home</p>
          </Link>
          <Link to='/mwfbi/business-insurance' className='mw_products__item'>
            <img
              src='/images/product-2.png'
              alt='Product 4'
              className='mw_products__item--image'
            />
            <p className='mw_products__item--name'>Business</p>
          </Link> */}
        </div>
        {showEdit && (
          <div
            className='mwfbi_banner__edit2'
            onClick={() => setProductModal(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}

        <div className='hide_button1'>
          <Button
            label='view all products'
            varient='red'
            icon='/sprite.svg#icon-search'
          />
        </div>
      </div>

      <EditProductModal
        showModal={showProductModal}
        setShowModal={setProductModal}
        handleYes={handleSection2Submit}
        section2={section2}
        setSection2={setSection2}
      />

      <div className='mw_about_container'>
        <div className='mw_about'>
          <img
            src={section3.image.value}
            alt={section3.image.value}
            className='mw_about__image'
          />

          <div div className='tabset'>
            <input
              type='radio'
              name='tabset'
              id='tab1'
              aria-controls='tab-about'
              checked={checked === 'checked1'}
              onChange={() => setChecked('checked1')}
            />
            <label htmlFor='tab1'>{section3.title.value}</label>

            <div className='tab-panels'>
              <section id='tab-about' className='tab-panel'>
                {/* <div className="	">
								<img src="/images/fallen-tree2.jpg" alt="MWFBI" className="mw_about__image3" />
							</div> */}
                <p className='tab_about__data'>
                  {section3.description &&
                    ReactHtmlParser(section3.description.value)}
                </p>
                <div className='mw_button2'>
                  <Link to='/mwfbi/about-us' className='mw_button'>
                    {section3.button.value}
                  </Link>
                </div>
                {/* <div className="mw_button2" style={{ marginTop: '2rem' }}>
								<button className="mw_button" onClick={() => setPaymentModal(true)}>
									Manage Payment Method
								</button>
							</div>
							<PaymentModal setPaymentModal={setPaymentModal} paymentModal={paymentModal} />
							<div id="portalOneContainer" /> */}
                <div id='portalOneContainer' key='portalOneContainer' />
              </section>
              <section id='tab-blog' className='tab-panel'>
                <h3 className='tab_blog__data'>
                  Strengthen Your Mind - September 27th
                </h3>
                <h3 className='tab_blog__data'>
                  Traveling on Budget - October 13th
                </h3>
                <h3 className='tab_blog__data'>
                  Taking Steps to have a Better day - August 27th
                </h3>
              </section>
              {/* <section id='tab-more' className='tab-panel'>
							<h2>More Block</h2>
							<p className='tab_about__data'>
								Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique, quae excepturi? Voluptate
								architecto provident odit aliquid, earum amet ipsa tempore expedita debitis officiis repellendus,
								assumenda illum voluptatibus error! A ipsam voluptatum aut cupiditate sed, nisi commodi! Exercitationem,
								nisi necessitatibus. Suscipit, laborum voluptatum consequatur aliquam blanditiis modi voluptates numquam
								veniam porro. Aliquam id consectetur illum.
							</p>
						</section> */}
            </div>
          </div>
        </div>
        {showEdit && (
          <div
            className='mwfbi_banner__edit2'
            onClick={() => setShowAboutModal(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}
      </div>

      <EditAboutModal
        showModal={showAboutModal}
        setShowModal={setShowAboutModal}
        handleYes={handleSection3Submit}
        section3={section3}
        setSection3={setSection3}
      />

      <div className='mw_services'>
        <div className='mw_services__item'>
          <div className='mw_services__item--info'>
            <div>
              <div className='mw_services__item--name'>
                {section4.serviceTitle.value}
              </div>
              <p className='mw_services__item--data'>
                Taking care of our
                {section4.serviceSubTitle.value}
              </p>
            </div>
            <Link to='/mwfbi/how-to-claim-file' className='mw_button_small'>
              {section4.serviceButton.value}
            </Link>
          </div>
          <img
            src={section4.serviceImage.value}
            alt='Claim Service'
            className='mw_services__item--image'
          />
        </div>
        <div className='mw_services__item'>
          <div className='mw_services__item--info'>
            <div>
              <div className='mw_services__item--name'>
                {section4.packageTitle.value}
              </div>
              <p className='mw_services__item--data'>
                {section4.packageSubTitle.value}
              </p>
            </div>
            <HashLink
              smooth
              to='/mwfbi/home-owners-insurance#home_discounts'
              className='mw_button_small'>
              {section4.packageButton.value}
            </HashLink>
          </div>
          <img
            src={section4.packageImage.value}
            alt='Package Policies'
            className='mw_services__item--image'
          />
        </div>
        {showEdit && (
          <div
            className='mwfbi_banner__edit2'
            onClick={() => setShowServiceModal(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}
      </div>
      <EditServicesModal
        showModal={showServiceModal}
        setShowModal={setShowServiceModal}
        handleYes={handleSection4Submit}
        section4={section4}
        setSection4={setSection4}
      />

      <div
        className='contact_us'
        style={{
          backgroundImage: `url(${section5.image.value}) `,
        }}>
        <div className='contact_us__box'>
          <h4 className='contact_us__heading'>{section5.title.value}</h4>
          <p className='contact_us__subheading'>{section5.subTitle.value}</p>
          <p className='contact_us__tag'> {section5.tagLine.value}</p>
          {/* <Button label="view all products" varient="red" icon="/sprite.svg#icon-search" /> */}
          <div className='mw_button2'>
            <HashLink
              smooth
              to='/mwfbi/auto-insurance#roadside_service'
              className='mw_button'>
              {section5.button1.value}
            </HashLink>
          </div>
          {showEdit && (
            <div
              className='mwfbi_banner__edit2'
              onClick={() => setShowContactModal(true)}>
              <svg>
                <use xlinkHref='/sprite.svg#icon-edit' />
              </svg>
            </div>
          )}

          {/* <Link to="/" className="mw_button">
						Auto Insurance
					</Link> */}
        </div>
      </div>
      <EditContactModal
        showModal={showContactModal}
        setShowModal={setShowContactModal}
        handleYes={handleSection5Submit}
        section5={section5}
        setSection5={setSection5}
      />
      {/* <EditFooterModal
        showModal={showFooterModal}
        setShowModal={setShowFooterModal}
      /> */}
    </Content>
  )
}

export default HomeScreen
