import React from 'react';

const Slider = ({ section2 }) => {
	return (
		<div className="slider1">
			<ul className="slides">
				{section2?.profiles?.value && section2?.profiles?.value.reverse().map((item, index) => <>
					<input type="radio" name="radio-btn" id={`img-${index}`} checked />
					<li className="slide-container">
						<div className="slide">
							<div className="testemonials__data">
								<div className="testimonial">
									<img
										src={item.icon}
										alt="testimonial 1"
										className="testimonial__image"
									/>
									<p className="testimonial__text">
										{item.description}
									</p>
									<hr className="testimonial__hr" />
									<h6 className="testimonial__name heading_3">
										<b>{item.title}</b>
									</h6>
									<p className="testimonial__subtext heading_3">
										<b>{item.subTitle}</b>
									</p>
									<hr className="testimonial__hr1" />
								</div>
							</div>
						</div>
						{/* <div className="nav">
						<label htmlFor="img-6" className="prev">
							&#x2039;
						</label>
						<label htmlFor="img-2" className="next">
							&#x203a;
						</label>
					</div> */}
					</li></>)
				}



				<li className="nav-dots">
					{section2?.profiles?.value
						&& section2?.profiles?.value.map((item, index) => <label htmlFor={`img-${index}`} className="nav-dot" id={`imgd-${index}`} />)}

				</li>
			</ul>
		</div>
	);
};

export default Slider;
