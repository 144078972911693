import React from 'react';
import Banner from '../../../components/Banner/Banner';

import Content from '../../../components/Content/Content';

const AboutScreen = () => {
	return (
		<Content headerFooterMenu="mountain-west">
			<Banner
				backgroundImage="url(/images/farm-insurance.png)"
				title="Mountain West Farm Bureau Insurance."
				subTitle="A multiline insurance company, serving the needs of individuals, families and businesses throughout wyoming and Montana."
			/>
			<div className="how-we-work-top main-content-margin">
				<label className="dash_heading_1">LOCAL INSURANCE FOR LOCAL COMAPNIES</label>
			</div>
			<div className="how-we-work-desc main-content-margin">
				<div style={{ marginTop: '10px', display: 'flex' }}>
					<div className="clip_image">
						<img src="/images/storm-trailer.jpg" alt="fbf" className="fbf__image__bus" />
					</div>
					<div style={{ flex: 1, marginLeft: '2rem' }}>
						<div className="how-we-work-desc-heading heading_1">What we value</div>
						<div className="how-we-work-desc-heading3">Our Stewardship : </div>

						<label className="how-we-work-desc-desc heading_2">
							We take to heart our responsibility to provide protection of what our client's value.
						</label>
						<div className="how-we-work-desc-heading3">Our Relationships : </div>

						<label className="how-we-work-desc-desc heading_2">
							We Keep the promises we make. integrity professionalism, respect and personal accountability
							is ingrained in our company fabric.
						</label>
						<div className="how-we-work-desc-heading3">Our Action : </div>

						<label className="how-we-work-desc-desc heading_2">
							We take proide in our work. We challenge ourserlves to personal growth, to e proactive and
							continually improve on the delivery of our products and services.
						</label>
					</div>
				</div>
			</div>
		</Content>
	);
};

export default AboutScreen;
