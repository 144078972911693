import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

import EditForm from "./EditForm/EditForm";
import PointAddForm from "./PointAddForm/PointAddForm";
import styles from "./ThirdStepForm.module.css";

const ThirdStepForm = ({
  step,
  setStep,
  onSectionElementSave,
  onFinalSubmit,
  showModal,
  sectionArray,
  setSectionArray,
  currentSubSection,
  currentSection,
  setCurrentSection,
  setSectionStatus,
  step3Selection, setStep3Selection,
  step2Selection, setStep2Selection,
  step1Selection, setStep1Selection,
  sectionStatus }) => {
  const [title, setTitle] = useState({ value: '', error: '' });

  const getForm = useCallback(() => {
    switch (sectionStatus) {
      // case 'edit':
      //   return (
      //     <EditForm currentSubSection={currentSubSection} step={step} />
      //   )
      case 'add':
        return (
          <PointAddForm onPointSave={(obj) => {
            let temp = { ...step1Selection };
            temp.selectedItem.subSection.push(obj);
            setStep1Selection(temp);
            setStep(2)
            setSectionStatus('')
            onSectionElementSave(temp)
          }} step1Selection={step1Selection} step={step} sectionArray={sectionArray} />
        )
      default:
        return (
          <EditForm
            setStep1Selection={setStep1Selection}
            step1Selection={step1Selection}
            step2Selection={step2Selection}
            setStep2Selection={setStep2Selection}
            currentSubSection={step2Selection.selectedItem}
            step={step} />
        )
    }
  }, [currentSubSection, sectionArray, sectionStatus, step])

  return (
    <div className={styles.mainContainer}>
      {getForm()}
    </div>
  );
};

export default ThirdStepForm;
