import React, { useEffect, useState } from 'react';
import Banner from '../../../components/Banner/Banner';
import Content from '../../../components/Content/Content';
import Button from '../../../components/Inputs/Button';
import { Link } from 'react-router-dom';
import Slider from './slider';
import SimpleButton from '../../../components/Inputs/SimpleButton';

import './Careers.css';
import slider from './slider';
import { useSelector } from 'react-redux';
import { ADMIN_USERS } from '../../../redux/actions/ip';
import EditSection1 from './edit/EditSection1'
import EditSection2 from './edit/EditSection2'
import EditSection3 from './edit/EditSection3'
import EditSection4 from './edit/EditSection4'
import { addContent, getPageContent, imageUpload } from '../../../redux/actions/contentAction';

// const slider = () => {};
const PAGE_NAME = "Careers";
let apiCall = false
const Careers = (history) => {

	const azureLogin = useSelector((state) => state.azureLogin)
	const [showEdit, setShowEdit] = useState(false);
	const [showSection1, setShowSection1] = useState(false);
	const [showSection3, setShowSection3] = useState(false);
	const [showEditSection2, setShowEditSection2] = useState(false);
	const [showEditSection4, setShowEditSection4] = useState(false);
	const [bannerData, setBannerData] = useState({
		title: { value: 'Careers', error: '' },
		subTitle: {
			value: 'A team of peers on a bold mission.',
			error: '',
		},
		tagline: {
			value: '',
			error: '',
		},
		button1: { value: '', error: '' },
		button1Link: { value: '', error: '' },
		button1Status: { value: false, error: '' },
		button2: { value: '', error: '' },
		button2Link: { value: '', error: '' },
		button2Status: { value: false, error: '' },
		imageUrl: { value: '/images/careers-build.jpg', error: '' },
	})
	const [section1, setSection1] = useState({
		title: { value: "Working at Mountain West", action: "", type: "" },
		image: { value: '/images/Careers-icon.png', action: "", type: "" },
		buttonTitle: { value: 'VIEW JOB OPENINGS', action: "", type: "" },
		buttonLink: { value: 'https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=4d135567-cded-463f-ae33-7fcc88e62ce0&ccId=19000101_000001&type=MP&lang=en_US', action: "", type: "" },
		description1: { value: "Here at Mountain West, our employees are our greatest asset. Our fantastic team of employees is what allows us to continue to do what we love: making a difference for our insured, and our community. If you’re looking to join a team that values you at the highest level, consider the excellent opportunities available with Mountain West Farm Bureau!", action: "", type: "" },
		description2: { value: `Mountain West has over 170 employees at the home office in Laramie, and even more in the field, all of whom value honesty, integrity, teamwork, solution-driven work ethic, and personal accountability. Those traits are what set us apart in all sectors of our company: Accounting, Claims, IT, Analytics & Development, Sales, Marketing, Operations, Underwriting as well as our Claim Adjusters located through Wyoming and Montana. Sound like a good fit so far? Check our current job openings using the button below!`, action: "", type: "" },
	})

	const [section2, setSection2] = useState({
		header1: { value: "Why choose Mountain West?", action: "", type: "" },
		header2: { value: "What is it like to work here?", action: "", type: "" },
		subTitle: { value: "Building a workplace of support.", action: "", type: "" },
		description1: { value: "We recognize how important it is for your day-to-day job to be filled with challenging yet fulfilling tasks, but even more importantly, we recognize how significant it is to offer room for growth. We work with you to define development plans, performance expectations, and career paths for your future. Constant growth and expansion provide opportunities within all departments of our company.", action: "", type: "" },
		description2: { value: "One of our other big commitments is developing our community. We offer Volunteer Time Off to our employees which gives them the chance to give back and support the local public. Whether it be volunteering at a food bank, building a house for Habitat for Humanity, or anything in between, we’re right behind you.", action: "", type: "" },
		profiles: {
			value: [
				{
					title: 'Esther Suazo',
					subTitle: 'Underwriting Trainee',
					icon: './images/testimonial-image.png',
					description: `What I like about Mountain West is it is very family orientated and very accommodating to our family needs.`
				},
				{
					title: 'Amanda Olaveson',
					subTitle: 'Underwriter II',
					icon: './images/testimonial-image.png',
					description: `The culture of Mountain West is a fun, engaging, collaborative, involved, family-oriented organization which leads to elevating employee engagement and makes for a great working environment.`
				}
			], action: "", type: "array"
		},
	})
	const [section3, setSection3] = useState({
		serviceTitle: { value: "Ready to love your job?", action: "", type: "" },
		serviceImage: { value: '/images/ReadyToLoveJob-icon.png', action: "", type: "" },
		serviceButtonTitle: { value: 'VIEW JOB OPENINGS', action: "", type: "" },
		serviceButtonLink: { value: 'https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=4d135567-cded-463f-ae33-7fcc88e62ce0&ccId=19000101_000001&type=MP&lang=en_US', action: "", type: "" },
		serviceSubTitle: {
			value: `If you are excited to join our team, then click the
		button below to view our current job openings and
		apply online today!`, action: "", type: ""
		},
		packageEmail: { value: "humanresources@mwfbi.com", action: "", type: "" },
		packageMail: { value: "931 Boulder Dr, Laramie, WY 82070", action: "", type: "" },
		packageAttn: { value: "HR Department", action: "", type: "" },
		packageTitle: { value: "Submit an application", action: "", type: "" },
		packageImage: { value: '/images/SubmitApplication-icon.png', action: "", type: "" },
		packageFile: { value: '/images/PER38-Job-App-2019.pdf', action: "", type: "" },
		packageButtonTitle: { value: 'Download Application', action: "", type: "" },
		packageSubTitle: {
			value: `Print and send your completed application/resume to:`, action: "", type: ""
		},

	})
	const [section4, setSection4] = useState({
		header: { value: "Our Departments", action: "", type: "" },
		profiles: {
			value: [
				{
					title: 'Accounting/Finance',
					subTitle: 'Works with company accounting and financials',
					icon: '/images/Accounting&Finance-icon.png',
				},
				{
					title: 'Analytics/Development',
					subTitle: 'Works with data development and product pricing',
					icon: '/images/Accounting&Finance-icon.png',
				},
				{
					title: 'Accounting/Finance',
					subTitle: 'Works with company accounting and financials',
					icon: '/images/Accounting&Finance-icon.png',
				},
				{
					title: 'Accounting/Finance',
					subTitle: 'Works with company accounting and financials',
					icon: '/images/Accounting&Finance-icon.png',
				},
				{
					title: 'Accounting/Finance',
					subTitle: 'Works with company accounting and financials',
					icon: '/images/Accounting&Finance-icon.png',
				},
				{
					title: 'Accounting/Finance',
					subTitle: 'Works with company accounting and financials',
					icon: '/images/Accounting&Finance-icon.png',
				},
				{
					title: 'Accounting/Finance',
					subTitle: 'Works with company accounting and financials',
					icon: '/images/Accounting&Finance-icon.png',
				},
				{
					title: 'Accounting/Finance',
					subTitle: 'Works with company accounting and financials',
					icon: '/images/Accounting&Finance-icon.png',
				},
				{
					title: 'Accounting/Finance',
					subTitle: 'Works with company accounting and financials',
					icon: '/images/Accounting&Finance-icon.png',
				},

			], action: "", type: "array"
		},
	})
	useEffect(() => {
		if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
			if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
				setShowEdit(true)
			} else setShowEdit(false)
		} else setShowEdit(false)
	}, [azureLogin])
	useEffect(() => {
		getPageContent({ pageName: PAGE_NAME })
			.then(res => {
				if (res.data.status) {
					apiCall = true
					if (res.data.result.Section_2) {
						setSection2({ ...res.data.result.Section_2, profiles: { ...res.data.result.Section_2.profiles, value: JSON.parse(res.data.result.Section_2.profiles.value) } })
					}
					if (res.data.result.Section_4) {
						setSection4({ ...res.data.result.Section_4, profiles: { ...res.data.result.Section_4.profiles, value: JSON.parse(res.data.result.Section_4.profiles.value) } })
					}
					if (res.data.result.Section_1)
						setSection1(res.data.result.Section_1)
					if (res.data.result.Section_3)
						setSection3(res.data.result.Section_3)

					if (res.data.result.TopBanner) setBannerData({
						...res.data.result.TopBanner,
						button1Status: { value: res.data.result.TopBanner.button1Status.value === 'true' },
						button2Status: { value: res.data.result.TopBanner.button2Status.value === 'true' }
					})

				}
			})
	}, [])
	const handleSubmit = async (image) => {
		debugger
		let content = { ...section1 }
		if (image) {
			imageUpload(image).then(upload => {
				content.image.value = upload.data.path;
				console.log(upload);
				addContent({ pageName: PAGE_NAME, section: "Section_1", content: content })
					.then(result => {
						if (result.data.status) {
							alert("Content Saved")
						}
					})
			})

		} else {
			addContent({ pageName: PAGE_NAME, section: "Section_1", content: content })
				.then(result => {
					if (result.data.status) {
						alert("Content Saved")
					}
				})
				.catch(error => {
					console.error(error);
				})
		}
		setSection1(content)
	}
	const handleSubmit3 = () => {
		addContent({ pageName: PAGE_NAME, section: "Section_3", content: section3 })
			.then(result => {
				if (result.data.status) {
					alert("Content Saved")
				}
			})
	}
	const handleSubmit4 = () => {
		addContent({ pageName: PAGE_NAME, section: "Section_4", content: section3 })
			.then(result => {
				if (result.data.status) {
					alert("Content Saved")
				}
			})
	}
	const split = (inputArray) => {
		return inputArray.reduce((all, one, i) => {
			const ch = Math.floor(i / 3);
			all[ch] = [].concat((all[ch] || []), one);
			return all
		}, [])
	}
	return (
		<Content headerFooterMenu="mountain-west">

			<Banner
				pageName={PAGE_NAME}
				backgroundImage='linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),url(./images/country-home-fall.jpg)'
				title='Careers'
				subTitle='A team of peers on a bold mission'
				bannerData={bannerData}
				showEdit={showEdit}
				setBannerData={setBannerData}
			/>
			<div className="working_at__main-content-margin" style={{ width: '100%', position: 'relative' }}>
				<h1 className="working_at__heading__heading_1">{section1?.title?.value}</h1>
				<div className="working_at_home_outer">
					<div className="working_at_home">
						<p className="working_at__texts-heading_2">
							{section1?.description1?.value}<br />
							<br />{section1?.description2?.value}
						</p>
					</div>
					<div className="career_image_outer">
						<img src={section1?.image?.value} alt="fbf" className="career_image" />
					</div>
				</div>
				<div className="button_text">
					<Button
						label={section1?.buttonTitle?.value}
						icon="/sprite.svg#icon-arrow_right"
						onClick={() => window.open(section1?.buttonLink?.value)}
					/>
				</div>
				{showEdit && (
					<div
						className='mwfbi_banner__edit'
						style={{ top: '1rem' }}
						id='admin-edit'
						onClick={() => setShowSection1(true)}>
						<svg>
							<use xlinkHref='/sprite.svg#icon-edit' />
						</svg>
					</div>
				)}
				<EditSection1
					showModal={showSection1}
					setShowModal={setShowSection1}
					pageName={PAGE_NAME}
					section1={section1}
					setSection1={setSection1}
				/>
			</div>

			<div className="why_choose" style={{ width: '100%', position: 'relative' }}>
				<div className="why_choose__section">
					<h3 className="why_choose__heading-heading_1">{section2.header1.value}</h3>
					<h5 className="why_choose__subheading">{section2.subTitle.value}</h5>
					<p className="why_choose__text">
						{section2.description1.value}
						<br />
						<br />
						{section2.description2.value}
					</p>
				</div>
				<div className="testimonials">
					<div className="testi_heading">
						<h4 className="testimonials__heading">{section2.header2.value}</h4>
					</div>

					<div className="testemonials__data">
						<Slider section2={section2} />

					</div>
				</div>
				{showEdit && (
					<div
						className='mwfbi_banner__edit'
						style={{ top: '1rem' }}
						id='admin-edit'
						onClick={() => setShowEditSection2(true)}>
						<svg>
							<use xlinkHref='/sprite.svg#icon-edit' />
						</svg>
					</div>
				)}
				{apiCall && <EditSection2
					showModal={showEditSection2}
					setShowModal={setShowEditSection2}
					handleYes={handleSubmit}
					data={section2}
					setData={setSection2}
					pageName={PAGE_NAME}
				/>
				}
			</div>

			<div className="application" style={{ width: '100%', position: 'relative' }}>
				<div className="application__card">
					<div>
						<img
							src={section3?.serviceImage?.value}
							alt="application icon"
							className="application__card--image"
						/>
					</div>
					<div className="application_card_text">
						<h4 className="application__card--heading">{section3?.serviceTitle?.value}</h4>
						<p className="application__card--text" style={{ width: '35rem' }}>
							{section3?.serviceSubTitle?.value}
						</p>
						<div className="button_text1">
							<Button
								label={section3?.serviceButtonTitle?.value}
								icon="/sprite.svg#icon-search"
								onClick={() =>
									window.open(section3?.serviceButtonLink?.value)}
							/>
						</div>
					</div>
				</div>
				<div className="application__card">
					<div>
						<img
							src={section3?.packageImage?.value}
							alt="application icon"
							className="application__card--image"
						/>
					</div>
					<div className="application_card_text">
						<h4 className="application__card--heading">{section3?.packageTitle?.value}</h4>
						<p className="application__card--text">
							{section3?.packageSubTitle?.value} <br />
							<b>Email: </b>
							<Link to="" className="humanresource__link">
								{section3?.packageEmail?.value}
							</Link>
							OR<br />
							<b>Mail:</b>{section3?.packageMail?.value} <br />
							<b>Attn: </b>{section3?.packageAttn?.value}
						</p>
						<div className="button_text">
							<SimpleButton
								varient="red"
								onClick={() => {
									window.open(section3?.packageFile?.value);
								}}
							>
								{section3?.packageButtonTitle?.value}
							</SimpleButton>
						</div>
					</div>
				</div>
				{showEdit && (
					<div
						className='mwfbi_banner__edit'
						style={{ top: '1rem' }}
						id='admin-edit'
						onClick={() => setShowSection3(true)}>
						<svg>
							<use xlinkHref='/sprite.svg#icon-edit' />
						</svg>
					</div>
				)}
				<EditSection3
					showModal={showSection3}
					setShowModal={setShowSection3}
					handleYes={handleSubmit3}
					section3={section3}
					setSection3={setSection3}
				/>
			</div>
			<div className="department" style={{ width: '100%', position: 'relative' }}>
				<div className="depatment__section">
					<h1 className="department__heading1">{section4?.header?.value}</h1>
				</div>
				<div className="department__contents">
					{section4?.profiles?.value && split(section4?.profiles?.value).map(row => <div className="department__maingrid">
						{row.map(item => <div className="department__gridcard">
							<img
								className="department__gridcard__image"
								alt="finance icon"
								src={item.icon}
							/>
							<div className="depatment__labels">
								<label className="department__heading2">{item.title}</label>
								<label className="department__heading3">
									{item.subTitle}
								</label>
							</div>
						</div>)}
					</div>)}

				</div>
				{showEdit && (
					<div
						className='mwfbi_banner__edit'
						style={{ top: '1rem' }}
						id='admin-edit'
						onClick={() => setShowEditSection4(true)}>
						<svg>
							<use xlinkHref='/sprite.svg#icon-edit' />
						</svg>
					</div>
				)}
				<EditSection4
					showModal={showEditSection4}
					setShowModal={setShowEditSection4}
					handleYes={handleSubmit4}
					data={section4}
					setData={setSection4}
					pageName={PAGE_NAME}
				/>
			</div>

		</Content>
	);
};

export default Careers;
