import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import DropZone from 'react-dropzone'
import { Link } from 'react-router-dom'

const EditServices = ({ setShowModal, showModal, handleYes, section4, setSection4 }) => {
  const [loading, setLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(null)
  const [uploadImagePreview, setUploadImagePreview] = useState('')
  const [uploadImage, setUploadImage] = useState('')
  const [uploadImageClaim, setUploadImageClaim] = useState('')
  const [uploadImagePreview2, setUploadImagePreview2] = useState('')

  const [title, setTitle] = useState({ value: '', error: '' })
  const [subTitle, setSubTitle] = useState({ value: '', error: '' })
  const [buttonTitle, setButtonTitle] = useState({ value: '', error: '' })

  const [servicesList, setServicesList] = useState([
    {
      title: 'Claims Service',
      subTitle: `Taking care of our customers`,
      buttonTitle: 'claims',
    },
  ])
  const encodeImageFileAsURL = (files, isService) => {
    var file = files[0]
    var reader = new FileReader();
    if (isService)
      setUploadImage(file)
    else
      setUploadImageClaim(file)
    reader.onloadend = function () {
      if (reader.result) {
        if (isService)
          setUploadImagePreview(reader.result)
        else
          setUploadImagePreview2(reader.result)
      }
    }
    reader.readAsDataURL(file)
  }

  const resetHandler = () => {
    setTitle({ value: '', error: '' })
    setSubTitle({ value: '', error: '' })
    setButtonTitle({ value: '', error: '' })
    setIsUpdate(false)
    setCurrentIndex(null)
    setUploadImage(null)
    setUploadImageClaim(null)
    setUploadImagePreview2(null)
    setUploadImagePreview(null)
  }

  const onAddhandler = () => {
    let validated = true

    if (title.value === '' && title.value.trim() === '') {
      setTitle({
        ...title,
        error: 'Please enter tab title',
      })
      validated = false
    }
    if (subTitle.value === '' && subTitle.value.trim() === '') {
      setSubTitle({
        ...subTitle,
        error: 'Please enter subTitle',
      })
      validated = false
    }
    if (buttonTitle.value === '' && buttonTitle.value.trim() === '') {
      setButtonTitle({
        ...buttonTitle,
        error: 'Please enter button title',
      })
      validated = false
    }

    if (validated) {
      let array = servicesList
      array.push({
        title: title.value,
        subTitle: subTitle.value,
        buttonTitle: buttonTitle.value,
      })

      setServicesList(array)
      resetHandler()
    }
  }

  const editHandler = (data, index) => {
    setTitle({ value: data.title, error: '' })
    setSubTitle({ value: data.subTitle, error: '' })
    setButtonTitle({ value: data.buttonTitle, error: '' })
    setIsUpdate(true)
    setCurrentIndex(index)
  }

  const deleteHandler = (data) => {
    const filtered = servicesList.filter((item, i) => {
      return item.title !== data.title
    })

    setServicesList(filtered)
    resetHandler()
  }

  const saveHandler = () => {
    let validated = true

    if (title.value === '' && title.value.trim() === '') {
      setTitle({
        ...title,
        error: 'Please enter tab title',
      })
      validated = false
    }
    if (subTitle.value === '' && subTitle.value.trim() === '') {
      setSubTitle({
        ...subTitle,
        error: 'Please enter subTitle',
      })
      validated = false
    }
    if (buttonTitle.value === '' && buttonTitle.value.trim() === '') {
      setButtonTitle({
        ...buttonTitle,
        error: 'Please enter button title',
      })
      validated = false
    }

    if (validated) {
      let array = servicesList
      let item = array[currentIndex]

      array[currentIndex] = {
        title: title.value,
        subTitle: subTitle.value,
        buttonTitle: buttonTitle.value,
      }

      setServicesList(array)
      resetHandler()
    }
  }
  const submit = (e) => {
    e.preventDefault();

    if (section4.serviceTitle.value == '') {
      let temp = { ...section4 };
      temp.serviceTitle.error = 'Please enter service title!'
      setSection4(temp)
      return
    } else {
      let temp = { ...section4 };
      temp.serviceTitle.error = ''
      setSection4(temp)
    }

    if (section4.serviceSubTitle.value == '') {
      let temp = { ...section4 };
      temp.serviceSubTitle.error = 'Please enter sub service title!'
      setSection4(temp)
      return
    } else {
      let temp = { ...section4 };
      temp.serviceSubTitle.error = ''
      setSection4(temp)
    }
    if (section4.serviceButton.value == '') {
      let temp = { ...section4 };
      temp.serviceButton.error = 'Please enter service button title!'
      setSection4(temp)
      return
    } else {
      let temp = { ...section4 };
      temp.serviceButton.error = ''
      setSection4(temp)
    }
    if (section4.serviceButton.value == '') {
      let temp = { ...section4 };
      temp.serviceButton.error = 'Please enter service button title!'
      setSection4(temp)
      return
    } else {
      let temp = { ...section4 };
      temp.serviceButton.error = ''
      setSection4(temp)
    }
    if (section4.packageSubTitle.value == '') {
      let temp = { ...section4 };
      temp.packageSubTitle.error = 'Please enter package title!'
      setSection4(temp)
      return
    } else {
      let temp = { ...section4 };
      temp.packageSubTitle.error = ''
      setSection4(temp)
    }
    if (section4.packageSubTitle.value == '') {
      let temp = { ...section4 };
      temp.packageSubTitle.error = 'Please enter package sub title!'
      setSection4(temp)
      return
    } else {
      let temp = { ...section4 };
      temp.packageSubTitle.error = ''
      setSection4(temp)
    }
    if (section4.packageButton.value == '') {
      let temp = { ...section4 };
      temp.packageButton.error = 'Please enter package button title!'
      setSection4(temp)
      return
    } else {
      let temp = { ...section4 };
      temp.packageButton.error = ''
      setSection4(temp)
    }

    handleYes(uploadImage, uploadImageClaim)
  }
  return (
    <Modal show={showModal}>
      <form >
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Services</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => {
                resetHandler()
                setShowModal(false)
              }}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section4.serviceTitle.value}
                    onChange={(e) => {
                      let temp = { ...section4 };
                      temp.serviceTitle.value = e.target.value;
                      setSection4(temp)
                      setTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={section4.serviceTitle.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Sub Title:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section4.serviceSubTitle.value}
                    onChange={(e) => {
                      let temp = { ...section4 };
                      temp.serviceSubTitle.value = e.target.value;
                      setSection4(temp)
                      setSubTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={section4.serviceSubTitle.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Button Title:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section4.serviceButton.value}
                    onChange={(e) => {
                      let temp = { ...section4 };
                      temp.serviceButton.value = e.target.value;
                      setSection4(temp)
                      setButtonTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={section4.serviceButton.error}
                  />
                </div>
              </div>
              <div className='modal_inner_content--right3'>
                <label>Service Image</label>
                <DropZone onDrop={(acceptedFiles) => {
                  encodeImageFileAsURL(acceptedFiles, true)
                }} name="heroImage" multiple={false}>
                  {({ getRootProps, getInputProps }) => (<div className='upload_border' {...getRootProps()}>
                    <input id='service' {...getInputProps()} />
                    <label>Click or Drag a image to this area to upload</label>
                    <svg className='modal_upload__icon-dark'>
                      <use xlinkHref='/sprite.svg#icon-cloud' />
                    </svg>
                  </div>)}
                </DropZone>
                <div className='upload_preview_modal'>
                  <img height={100} src={uploadImagePreview}></img>
                </div>
              </div>
            </div>

          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Package Title:'
                    style={{ width: '80%' }}
                    value={section4.packageTitle.value}
                    required={true}
                    onChange={(e) => {
                      let temp = { ...section4 };
                      temp.packageTitle.value = e.target.value;
                      setSection4(temp)
                      setTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={section4.packageTitle.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Package Sub Title:'
                    style={{ width: '80%' }}
                    value={section4.packageSubTitle.value}
                    required={true}
                    onChange={(e) => {
                      let temp = { ...section4 };
                      temp.packageSubTitle.value = e.target.value;
                      setSection4(temp)
                      setSubTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={section4.packageSubTitle.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Package Button Title:'
                    style={{ width: '80%' }}
                    value={section4.packageButton.value}
                    required={true}
                    onChange={(e) => {
                      let temp = { ...section4 };
                      temp.packageButton.value = e.target.value;
                      setSection4(temp)
                      setButtonTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={section4.packageButton.error}
                  />
                </div>
              </div>
              <div className='modal_inner_content--right3'>
                <label>Package Image</label>
                <DropZone onDrop={(acceptedFiles) => {
                  encodeImageFileAsURL(acceptedFiles, false)
                }} name="ClaimImage" multiple={false}>
                  {({ getRootProps, getInputProps }) => (<div className='upload_border' {...getRootProps()}>
                    <input {...getInputProps()} />
                    <label>Click or Drag a image to this area to upload</label>
                    <svg className='modal_upload__icon-dark'>
                      <use xlinkHref='/sprite.svg#icon-cloud' />
                    </svg>
                  </div>)}
                </DropZone>
                <div className='upload_preview_modal' style={{ marginBottom: '2rem' }}>
                  <img height={100} src={uploadImagePreview2}></img>
                </div>
              </div>
            </div>

          </div>
          <div className='modal_dark_buttons'>
            <button
              className='modal__button-reset-dark'
              onClick={() => {
                resetHandler()
                setShowModal(false)
              }}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={submit}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditServices
