import React, { useEffect, useState } from 'react'
import Banner from '../../../components/Banner/Banner'
import { Link } from 'react-router-dom'
// import { azureLogin } from '../../../redux/actions/userActions'

import Content from '../../../components/Content/Content'
import './AutoInsurance.css'
import {
  addContent,
  getPageContent,
} from '../../../redux/actions/contentAction'
import EditInsuranceHeader from './edit/EditInsuranceHeader'
import EditInsurancePoints from './edit/EditInsurancePoints'
import EditInsuranceEmergency from './edit/EditInsuranceEmergency'
import EditInsuranceDiscount from './edit/EditInsuranceDiscount'
import EditInsurancePremiere from './edit/EditInsurancePremiere'
import EditInsuranceDiscountType from './edit/EditInsuranceDiscountType'
import EditInsurancePremiereType from './edit/EditInsurancePremiereType'
import EditInsuranceSpecial from './edit/EditInsuranceSpecial'
import EditInsuranceSpecialInfo from './edit/EditInsuranceSpecialInfo'
import EditInsuranceSpecialType from './edit/EditInsuranceSpecialType'
import { ADMIN_USERS } from '../../../redux/actions/ip'
import { useDispatch, useSelector } from 'react-redux'
import { validateJsonString } from '../../../redux/constants/utils'

const AutoInsurance = () => {
  const azureLogin = useSelector((state) => state.azureLogin)

  const [checked, setChecked] = useState('checked1')
  const [showEdit, setShowEdit] = useState(false)
  const [showInsuranceHeader, setShowInsuranceHeader] = useState(false)
  const [showInsurancePoints, setShowInsurancePoints] = useState(false)
  const [showInsuranceEmergency, setShowInsuranceEmergency] = useState(false)
  const [showInsuranceDiscount, setShowInsuranceDiscount] = useState(false)
  const [showInsurancePremiere, setShowInsurancPremiere] = useState(false)
  const [showDiscountTypeModal, setShowDiscountTypeModal] = useState(false)
  const [showInsurancePremiereType, setShowInsurancPremiereType] =
    useState(false)
  const [showInsuranceSpecial, setShowInsurancSpecial] = useState(false)
  const [showInsuranceSpecialInfo, setShowInsurancSpecialInfo] = useState(false)
  const [showInsuranceSpecialType, setShowInsurancSpecialType] = useState(false)

  const [discountListArr, setDiscountListArr] = useState([
    {
      title: 'Safe Driver',
      description:
        'Safe drivers who have been accident-free for 3 years or 6 years may qualify for this discount.',
      src: './images/Graphics-Safe-Driver.png',
    },
    {
      title: 'Good Student',
      description:
        'If you have a student driver in your house with a 3.0 GPA or better you may qualify for this discount.',
      src: './images/Graphics-Good-Student.png',
    },
    {
      title: 'Low Mileage',
      description:
        'A discount if you drive any of your vehicles less than 7,500 miles per year.',
      src: './images/Graphics-Low-Mileage.png',
    },
    {
      title: 'Multi–Car',
      description:
        'If you have multiple cars insured under one policy, there may be a discount.',
      src: './images/Graphics-Multi-Car.png',
    },
    {
      title: 'Patriot',
      description:
        'If you are a veteran and/or if you took military-sponsored defensive driving course you maybe eligible.',
      src: './images/Graphics-Patriot.png',
    },
    {
      title: 'Sr. Citizen',
      description:
        'If you are over the age of 55 and have completed one of the qualifying defensive driving courses, you could be eligible for a discount.',
      src: './images/Graphics-Sr-Citizen.png',
    },
    {
      title: 'Loyalty',
      description:
        'If you have been with your current insurance company for 3, 6, 10 years or longer, we want a client like you!',
      src: './images/Graphics-Loyalty.png',
    },
    {
      title: 'Package Policy',
      description:
        'If you choose to insure your home and autos with Mountain West, there could be a discount.',
      src: './images/Graphics-Package-Policy.png',
    },
  ])

  const [section1, setSection1] = useState({
    title: { value: 'Auto Insurance Coverage', action: '', type: '' },
    description: {
      value: `Our car insurance has coverage for comprehensive, collision, and liability exposures. In addition, various deductible options and discounts allow you to customize your policy to meet your needs.`,
    },
  })
  const [section2, setSection2] = useState([
    {
      title: {
        value: 'Liability Insurance',
        action: '',
        type: '',
      },
      description: {
        value:
          'Provides bodily injury and property damage to others when you are legally liable for an accident.',
      },
      example: {
        value:
          'You rear-end another car, causing damage to the vehicle and injuring and driver. We’ll pay for, up to your policy’s limits, the other driver’s car repairs and injuries. There’s no deductible for liability.',
      },
    },
    {
      title: {
        value: 'Comprehensive & Collision',
        action: '',
        type: '',
      },
      description: {
        value:
          'Comprehensive pays to repair or replace your car if itU+0027s damaged from an incident not caused by collision.',
      },
      example: {
        value: '',
      },
    },
    {
      title: {
        value: 'Medical Payments',
        action: '',
        type: '',
      },
      description: {
        value:
          'Covers reasonable medical payments or funeral expenses incurred as a result of an accident for each occupant of an insured vehicle.',
      },
      example: {
        value:
          'Your newly-licensed 16-year-old daughter drives her sister to school, and they’re in an accident. Your younger daughter is injured and has medical bills. Medical payments will cover the bill, up to the limits of your policy. No deductible applies.',
      },
    },
    {
      title: {
        value: 'Uninsured & Underinsured Driver',
        action: '',
        type: '',
      },
      description: {
        value:
          'Provides bodily injury coverage when you are injured as a result of an uninsured motorist’s negligence, or when the other vehicle does not have high enough limits to cover the injuries caused by their negligence.',
      },
      example: {
        value:
          'An uninsured driver runs a red light, hits your vehicle and you need to be transported to the hospital. Your uninsured driver coverage can pay your medical expenses and wage losses.',
      },
    },
  ])
  const [section3, setSection3] = useState({
    title: { value: 'Emergency Roadside Service', action: '', type: '' },
    description: {
      value:
        'Looking for peace of mind every mile? With a coverage limit of up to $250, you will have access to award-winning roadside assistance, 24x7x364, from one of the largest networks nationwide!',
    },
    subTitle1: {
      value: 'Emergency Services Include:',
    },
    imageUrl: { value: './images/AgeroClientInsert.png' },
    subTitle2: {
      value: 'For all your Emergency Roadside Service needs, call:',
    },
    contact: {
      value: '1-888-292-3308',
    },
  })

  const [section4, setSection4] = useState({
    title: { value: 'Auto insurance Discounts', action: '', type: '' },
    description: {
      value: `Are you looking for quality car insurance that you can afford without sacrifi cing coverage? Mountain West is proud of the high-quality products and services we offer. The following discounts reflect our commitment to offer these products at an affordable rate without sacrifi cing coverage.`,
    },
  })

  const [section5, setSection5] = useState({
    discountList: [
      {
        title: 'Safe Driver',
        description:
          'Safe drivers who have been accident-free for 3 years or 6 years may qualify for this discount.',
        src: './images/Graphics-Safe-Driver.png',
      },
      {
        title: 'Good Student',
        description:
          'If you have a student driver in your house with a 3.0 GPA or better you may qualify for this discount.',
        src: './images/Graphics-Good-Student.png',
      },
      {
        title: 'Low Mileage',
        description:
          'A discount if you drive any of your vehicles less than 7,500 miles per year.',
        src: './images/Graphics-Low-Mileage.png',
      },
      {
        title: 'Multi–Car',
        description:
          'If you have multiple cars insured under one policy, there may be a discount.',
        src: './images/Graphics-Multi-Car.png',
      },
      {
        title: 'Patriot',
        description:
          'If you are a veteran and/or if you took military-sponsored defensive driving course you maybe eligible.',
        src: './images/Graphics-Patriot.png',
      },
      {
        title: 'Sr. Citizen',
        description:
          'If you are over the age of 55 and have completed one of the qualifying defensive driving courses, you could be eligible for a discount.',
        src: './images/Graphics-Sr-Citizen.png',
      },
      {
        title: 'Loyalty',
        description:
          'If you have been with your current insurance company for 3, 6, 10 years or longer, we want a client like you!',
        src: './images/Graphics-Loyalty.png',
      },
      {
        title: 'Package Policy',
        description:
          'If you choose to insure your home and autos with Mountain West, there could be a discount.',
        src: './images/Graphics-Package-Policy.png',
      },
    ],
  })

  const [section6, setSection6] = useState({
    title: { value: 'Premier Auto Insurance', action: '', type: '' },
    subTitle1: { value: 'Take your auto coverage to the next level!' },
    description: {
      value: `Are you looking for quality car insurance that you can afford without sacrifi cing coverage? Mountain West is proud of the high-quality products and services we offer. The following discounts reflect our commitment to offer these products at an affordable rate without sacrifi cing coverage.`,
    },
  })

  const [section7, setSection7] = useState([
    {
      title: { value: 'Rental Car Expenses', action: '', type: '' },
      description: {
        value: `Up to $1,000 for rental expenses when your auto is involved in a covered comprehensive, collision or theft loss.`,
      },
    },
    {
      title: { value: 'Glass Breakage', action: '', type: '' },
      description: {
        value: `When your auto sustains glass breakage to the windshield, side
        glass or sunroof, your deductible for replacement of the glass is
        only $100. *exclusions apply`,
      },
    },
    {
      title: { value: 'Personal Property', action: '', type: '' },
      description: {
        value: `Up to $500 for damage to your personal property of others because
        of a covered comprehensive or collision loss.`,
      },
    },
    {
      title: { value: 'Pet Protection', action: '', type: '' },
      description: {
        value: `Up to $1,000 per occurrence for veterinarian bills or burial costs
        for your pet as a result of a covered comprehensive or collision
        loss. *exclusion apply`,
      },
    },
    {
      title: { value: 'Meals and Lodging', action: '', type: '' },
      description: {
        value: `Up to $500 for meals and lodging if you are traveling and your
        auto is involved in a covered comprehensive or collision loss. The
        loss or accident must occur over 100 miles from where your auto is
        principally garaged.`,
      },
    },
  ])

  const [section8, setSection8] = useState({
    title: { value: 'Special Equipment', action: '', type: '' },
    subTitle1: { value: 'Don’t leave your vehicle only half covered.' },
    description: {
      value: `If you have invested time and money into your autos, you many not be adequately covered. Your policy provides $3,000 for Special Equipment on your auto option to increase the amount to fit your needs for the special equipment you may have.`,
    },
  })

  const [section9, setSection9] = useState({
    title: { value: 'What is special equipment:', action: '', type: '' },
    description: {
      value: `Special equipment includes but is not limited to:`,
    },
  })

  const [section10, setSection10] = useState([
    {
      title: { value: 'Toppers', action: '', type: '' },
    },
    {
      title: { value: 'Grill Guards', action: '', type: '' },
    },
    {
      title: { value: 'Electronic Equipment', action: '', type: '' },
    },
    {
      title: { value: 'Lift Kits', action: '', type: '' },
    },
    {
      title: { value: 'Custom Wheels', action: '', type: '' },
    },
    {
      title: { value: 'Custom Paint', action: '', type: '' },
    },
    {
      title: { value: 'Lights', action: '', type: '' },
    },
    {
      title: { value: 'Handicap Equipment', action: '', type: '' },
    },
  ])

  const [section11, setSection11] = useState({
    title: {
      value: 'Special Equipment means any equipment or devices that alter the:',
      action: '',
      type: '',
    },
    subTitle1: {
      value: 'Appearance',
    },
    subTitle2: {
      value: 'Performance',
    },
    subTitle3: {
      value: 'Function of your insured auto or trailer',
    },
  })

  const [section12, setSection12] = useState({
    title: {
      value: 'It does not mean any:',
      action: '',
      type: '',
    },
    subTitle1: {
      value: 'Original manufacturer equipment, furnishings, or parts.',
    },
    subTitle2: {
      value:
        'Any replacement of original manufacturer equipment, furnishings, or parts with other items of like kind and quality.',
    },
  })
  const [bannerData, setBannerData] = useState({
    title: { value: 'Auto Insurance', error: '' },
    subTitle: { value: 'Providing peace of mind behind the wheel.', error: '' },
    tagline: {
      value:
        'Start a car insurance quote and begin working with your local agent.',
      error: '',
    },
    button1: { value: 'START A QUOTE', error: '' },
    button1Link: { value: '/mwfbi/start-a-quote', error: '' },
    button1Status: { value: true, error: '' },
    button2: { value: 'FIND AN AGENT', error: '' },
    button2Link: { value: '/mwfbi/find-an-agent', error: '' },
    button2Status: { value: true, error: '' },
    imageUrl: { value: '/images/truck.jpg', error: '' },
  })
  const handleInsuranceHeaderSubmit = () => {
    addContent({
      pageName: 'Auto_Insurance',
      section: 'Section_1',
      content: section1,
    }).then((result) => {
      console.log(result.data)
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }

  const handleInsurancePointsSubmit = (section2Content) => {
    addContent({
      pageName: 'Auto_Insurance',
      section: 'Section_2',
      content: {
        list: { value: validateJsonString(JSON.stringify(section2Content)) },
      },
    }).then((result) => {
      console.log(result.data)
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }

  const handleInsuranceEmergencySubmit = (content) => {
    addContent({
      pageName: 'Auto_Insurance',
      section: 'Section_3',
      content: content,
    }).then((result) => {
      console.log(result.data)
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }

  const handleInsuranceDiscountSubmit = (content) => {
    addContent({
      pageName: 'Auto_Insurance',
      section: 'Section_4',
      content: section4,
    }).then((result) => {
      console.log(result.data)
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }

  const handleDiscountType = (content) => {
    addContent({
      pageName: 'Auto_Insurance',
      section: 'Section_5',
      content: { list: { value: JSON.stringify(content) } },
    }).then((result) => {
      console.log(result.data)

      if (result.data.status) {
        getPageContent({ pageName: 'Auto_Insurance' }).then((res) => {
          // debugger
          if (res.data.status) {
            if (res.data.result.Section_5) {
              setSection5({
                discountList: JSON.parse(res.data.result.Section_5.list.value),
              })
              setDiscountListArr(
                JSON.parse(res.data.result.Section_5.list.value)
              )
            }
          }
        })
        alert('Content Saved')
      }
    })
  }

  const handleInsurancePremiereSubmit = (content) => {
    addContent({
      pageName: 'Auto_Insurance',
      section: 'Section_6',
      content: section6,
    }).then((result) => {
      console.log(result.data)
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }

  const handleInsurancePremiereTypeSubmit = (content) => {
    console.log('content', content)
    addContent({
      pageName: 'Auto_Insurance',
      section: 'Section_7',
      content: {
        list: {
          value: JSON.stringify(content),
        },
      },
    }).then((result) => {
      console.log(result.data)
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }

  const handleInsuranceSpecialSubmit = (content) => {
    addContent({
      pageName: 'Auto_Insurance',
      section: 'Section_8',
      content: section8,
    }).then((result) => {
      console.log(result.data)
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }

  const handleInsuranceSpecialInfoSubmit = () => {
    addContent({
      pageName: 'Auto_Insurance',
      section: 'Section_9',
      content: section9,
    }).then((result) => {
      console.log(result.data)
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }

  const handleInsuranceSpecialTypeSubmit = (content) => {
    addContent({
      pageName: 'Auto_Insurance',
      section: 'Section_10',
      content: {
        list: {
          value: JSON.stringify(content),
        },
      },
    }).then((result) => {
      console.log(result.data)
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }

  const handleInsuranceSpecialType2Submit = (content) => {
    addContent({
      pageName: 'Auto_Insurance',
      section: 'Section_11',
      content: section11,
    }).then((result) => {
      console.log(result.data)
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }
  const handleInsuranceSpecialType3Submit = (content) => {
    addContent({
      pageName: 'Auto_Insurance',
      section: 'Section_12',
      content: section12,
    }).then((result) => {
      console.log(result.data)
      if (result.data.status) {
        // alert('Content Saved')
      }
    })
  }

  useEffect(() => {
    if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
      if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
        setShowEdit(true)
      } else setShowEdit(false)
    } else setShowEdit(false)
  }, [azureLogin])

  useEffect(() => {
    getPageContent({ pageName: 'Auto_Insurance' }).then((res) => {
      // debugger
      if (res.data.status) {
        if (res.data.result.Section_1) setSection1(res.data.result.Section_1)
        if (res.data.result.Section_2)
          setSection2(JSON.parse(res.data.result.Section_2.list.value))
        if (res.data.result.Section_3) setSection3(res.data.result.Section_3)
        if (res.data.result.Section_4) setSection4(res.data.result.Section_4)
        if (res.data.result.Section_5) {
          setSection5({
            discountList: JSON.parse(res.data.result.Section_5.list.value),
          })
          setDiscountListArr(JSON.parse(res.data.result.Section_5.list.value))
        }

        if (res.data.result.Section_6) setSection6(res.data.result.Section_6)
        if (res.data.result.Section_7) {
          setSection7(JSON.parse(res.data.result.Section_7.list.value))
        }
        if (res.data.result.Section_8) setSection8(res.data.result.Section_8)
        if (res.data.result.Section_9) setSection9(res.data.result.Section_9)
        // console.log(JSON.parse(res.data.result.Section_10))

        if (res?.data?.result?.Section_10) {
          setSection10(JSON.parse(res.data.result.Section_10.list.value))
        }

        if (res.data.result.Section_11) setSection11(res.data.result.Section_11)
        if (res.data.result.Section_12) setSection12(res.data.result.Section_12)
        if (res.data.result.TopBanner)
          setBannerData({
            ...res.data.result.TopBanner,
            button1Status: {
              value: res.data.result.TopBanner.button1Status.value === 'true',
            },
            button2Status: {
              value: res.data.result.TopBanner.button2Status.value === 'true',
            },
          })
      }
    })
  }, [])

  const splitArray = (array, perChunk = 3) => {
    if (Array.isArray(array) && array.length) {
      return array.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk)

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      }, [])
    }
    return []
  }

  return (
    <Content headerFooterMenu='mountain-west'>
      <Banner
        backgroundImage='linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),url(/images/truck.jpg)'
        title='Auto Insurance'
        subTitle='Providing peace of mind behind the wheel.'
        bannerData={bannerData}
        showEdit={showEdit}
        setBannerData={setBannerData}
        pageName={'Auto_Insurance'}
      />
      <div className='quote2'>
        <div className='flex2'>
          <h3 className='quote__text2'>
            {bannerData && bannerData.tagline
              ? bannerData.tagline.value
              : 'Start a car insurance quote and begin working with your local agent.'}
          </h3>
        </div>
        <div className='header_buttons'>
          {bannerData &&
            bannerData.button1 &&
            bannerData.button1Status.value && (
              <Link to={bannerData.button1Link.value} className='mw_button'>
                {bannerData.button1.value}
              </Link>
            )}
          {bannerData &&
            bannerData.button2 &&
            bannerData.button2Status.value && (
              <Link to={bannerData.button2Link.value} className='mw_button'>
                {bannerData.button2.value}
              </Link>
            )}
        </div>
      </div>
      <div
        className='auto_insurance'
        style={{ width: '100%', position: 'relative' }}>
        <h1 className='phase2_heading' id='auto_coverage'>
          {section1?.title?.value ? section1.title.value : ''}
          {/* Auto Insurance Coverage */}
        </h1>
        <p className='phase2_subheading'>
          {section1?.description?.value ? section1.description.value : ''}
          {/* Our car insurance has coverage for comprehensive, collision, and
          liability exposures. In addition, various deductible options and
          discounts allow you to customize your policy to meet your needs. */}
        </p>
        {showEdit && (
          <div
            className='mwfbi_banner__edit'
            style={{ top: '1rem' }}
            id='admin-edit'
            onClick={() => setShowInsuranceHeader(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}
      </div>
      <div
        div
        className='auto_insurance tabset'
        style={{ width: '100%', position: 'relative' }}>
        {section2.map((item, index) => {
          return (
            <>
              <input
                type='radio'
                name='tabset'
                id={`tab${index + 1}`}
                aria-controls={`tab-${item.title.value}`}
                checked={checked === `checked${index + 1}`}
                onChange={() => setChecked(`checked${index + 1}`)}
              />
              <label
                htmlFor={`tab${index + 1}`}
                style={{
                  height: '4rem',
                  width: '20rem',
                  display: 'inline-flex',
                  lineHeight: '1.5rem',
                  fontSize: '1.5rem',
                  position: 'relative',
                  textAlign: 'center',
                  paddingBottom: '0',
                  paddingTop: '0',
                }}>
                <div
                  style={{
                    position: 'absolute',
                    width: '20rem',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '1.4rem',
                  }}>
                  {item.title.value}
                </div>
              </label>
            </>
          )
        })}

        <div className='tab-panels'>
          {section2.map((item, index) => {
            return (
              <section
                id={`tab-${item.title.value}`}
                className='tab-panel'
                key={index}>
                <p className='phase2_subheading'>{item.description.value}</p>
                <br />
                {item.example.value ? (
                  <p className='phase2_subheading'>
                    <div className='tab_comprehensive'>
                      <p className='phase2_heading1'>Example:</p>
                      <p className='phase2_subheading'>{item.example.value}</p>
                    </div>
                  </p>
                ) : null}
              </section>
            )
          })}
        </div>
        {showEdit && (
          <div
            className='mwfbi_banner__edit'
            style={{ top: '1rem' }}
            id='admin-edit'
            onClick={() => setShowInsurancePoints(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}
      </div>
      <div
        className='auto_insurance_roadside'
        id='roadside_service'
        style={{ width: '100%', position: 'relative' }}>
        <div className=''>
          <h1 className='phase2_heading'>
            {section3?.title?.value ? section3?.title?.value : ''}
          </h1>
          <p className='phase2_subheading'>
            {section3?.description?.value ? section3.description.value : ''}
          </p>
        </div>
        <div className='auto_insurance_services'>
          <h1 className='phase2_heading1'>
            {section3?.subTitle1?.value ? section3.subTitle1.value : ''}
          </h1>
          <img
            src={
              section3?.imageUrl?.value
                ? section3.imageUrl.value
                : './images/AgeroClientInsert.png'
            }
            alt='agero client'
            className='auto_insuracne_image'
          />
        </div>
        <div className='auto_inurance_emergency'>
          <h1 className='phase2_heading'>
            {section3?.subTitle2?.value ? section3.subTitle2.value : ''}
            <p className='contact_no'>
              {section3?.contact?.value ? section3.contact.value : ''}
            </p>
          </h1>
        </div>
        {showEdit && (
          <div
            className='mwfbi_banner__edit'
            style={{ top: '1rem' }}
            id='admin-edit'
            onClick={() => setShowInsuranceEmergency(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}
      </div>
      <div
        className='auto_insurance_discounts'
        id='auto_insurance_discounts'
        style={{ width: '100%', position: 'relative' }}>
        <h1 className=' phase2_heading'>{section4?.title?.value ?? ''}</h1>
        <p className='phase2_subheading'>
          {section4?.description?.value ?? ''}
        </p>
        {showEdit && (
          <div
            className='mwfbi_banner__edit'
            style={{ top: '1rem' }}
            id='admin-edit'
            onClick={() => setShowInsuranceDiscount(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}

        <div className='auto_insurance_discounts-types'>
          {section5 && section5.discountList.length
            ? splitArray(section5.discountList, 3).map((elements, i) => (
              <div
                className='auto_insurance_discounts-grid'
                key={'parent' + i}>
                {elements.length
                  ? elements.map((item, i) => (
                    <div className='discounts-types' key={i}>
                      <img
                        src={item.src}
                        alt={item.title}
                        className='discounts_images_nails'
                      />
                      <div className='discount_image_text'>
                        <h1 className='phase2_heading1'>{item.title}</h1>
                        <p className='phase2_subheading'>
                          {item.description}
                        </p>
                      </div>
                      {i <= 1 ? <div className='line' /> : <div />}
                    </div>
                  ))
                  : null}
              </div>
            ))
            : null}
        </div>
        {showEdit && (
          <div
            className='mwfbi_banner__edit'
            style={{ top: '13rem' }}
            id='admin-edit'
            onClick={() => setShowDiscountTypeModal(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}
      </div>
      <div
        className='auto_insurance_premier'
        id='premier_auto'
        style={{ width: '100%', position: 'relative' }}>
        <h1 className='phase2_heading'>
          {section6.title.value ? section6.title.value : ''}
        </h1>
        <h2 className='phase2_heading1'>
          {section6.subTitle1.value ? section6.subTitle1.value : ''}
        </h2>
        <p className='phase2_subheading'>
          {section6.description.value ? section6.description.value : ''}
        </p>
        {showEdit && (
          <div
            className='mwfbi_banner__edit'
            style={{ top: '1rem' }}
            id='admin-edit'
            onClick={() => setShowInsurancPremiere(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}
        {showEdit && (
          <div
            className='mwfbi_banner__edit'
            style={{ top: '18rem' }}
            id='admin-edit'
            onClick={() => setShowInsurancPremiereType(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}
        <div className='premier_auto'>
          {section7?.length > 0 &&
            section7.map((item, index) => {
              return (
                <>
                  <div className='premier_auto_coverage' key={index}>
                    <h1 className='phase2_heading3'>
                      {item?.title?.value ? item.title.value : ''}
                    </h1>
                    <p className='phase2_subheading1'>
                      {item?.description?.value ? item.description.value : ''}
                    </p>
                  </div>
                  <hr />
                </>
              )
            })}
        </div>
      </div>
      *
      <div
        className='auto_insurance_equipment'
        id='special_equipment'
        style={{ width: '100%', position: 'relative' }}>
        <div className='auto_insurance_equipment--equipment'>
          <h1 className='phase2_heading'>{section8.title.value}</h1>
          <p className='phase2_heading1'>
            {section8.subTitle1.value}
            {/* Don’t leave your vehicle only half covered. */}
          </p>
          <p className='phase2_subheading'>
            {section8.description.value}
            {/* If you have invested time and money into your autos, you many not be
            adequately covered. Your policy provides $3,000 for Special
            Equipment on your auto option to increase the amount to fit your
            needs for the special equipment you may have. */}
          </p>
          {showEdit && (
            <div
              className='mwfbi_banner__edit'
              style={{ top: '1rem' }}
              id='admin-edit'
              onClick={() => setShowInsurancSpecial(true)}>
              <svg>
                <use xlinkHref='/sprite.svg#icon-edit' />
              </svg>
            </div>
          )}
        </div>
        {showEdit && (
          <div
            className='mwfbi_banner__edit'
            style={{ top: '16rem' }}
            id='admin-edit'
            onClick={() => setShowInsurancSpecialInfo(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}
        {showEdit && (
          <div
            className='mwfbi_banner__edit'
            style={{ top: '20rem' }}
            id='admin-edit'
            onClick={() => setShowInsurancSpecialType(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}

        <div
          className='speical_equipment_outer'
          id='special_equipment'
          style={{ width: '100%', position: 'relative' }}>
          <div className='special_equipment'>
            <h1 className='phase2_heading'>
              {section9?.title?.value ? section9.title.value : ''}
            </h1>
            <h2 className='phase2_heading1'>
              {section9.description.value ? section9.description.value : ''}
            </h2>
          </div>

          <div className='special_equipment_list'>
            <div
              className='special_equipment_headers'
              style={{ position: 'relative' }}>
              {section10.length > 0 &&
                section10.map((item, index) => {
                  return (
                    <h1 className='phase2_subheading' key={index}>
                      &#10004; {item?.title?.value}
                    </h1>
                  )
                })}
              {/* <h1 className='phase2_subheading'>&#10004; Grill Guards</h1>
              <h1 className='phase2_subheading'>
                &#10004; Electronic Equipment
              </h1>
              <h1 className='phase2_subheading'>&#10004; Lift Kits</h1>
              <h1 className='phase2_subheading'>&#10004; Custom Wheels</h1>
              <h1 className='phase2_subheading'>&#10004; Custom Paint</h1>
              <h1 className='phase2_subheading'>&#10004; Lights</h1>
              <h1 className='phase2_subheading'>&#10004; Handicap Equipment</h1> */}
            </div>
            <div className='special_equipment_uses'>
              <p className='phase2_subheading'>{section11.title.value}</p>
              <list className='phase2_subheading'>
                <ul>
                  <li>{section11.subTitle1.value}</li>
                  <li>{section11.subTitle2.value}</li>
                  <li>{section11.subTitle3.value}</li>
                </ul>
              </list>
              <br />
              <p className='phase2_subheading'>{section12.title.value}</p>
              <list className='phase2_subheading'>
                <ul>
                  <li>{section12.subTitle1.value}</li>
                  <li>{section12.subTitle2.value}</li>
                </ul>
              </list>
            </div>
          </div>
        </div>
      </div>
      <EditInsuranceHeader
        showModal={showInsuranceHeader}
        setShowModal={setShowInsuranceHeader}
        handleYes={handleInsuranceHeaderSubmit}
        section1={section1}
        setSection1={setSection1}
      />
      <EditInsurancePoints
        showModal={showInsurancePoints}
        setShowModal={setShowInsurancePoints}
        handleYes={handleInsurancePointsSubmit}
        section2={section2}
        setSection2={setSection2}
      />
      <EditInsuranceEmergency
        showModal={showInsuranceEmergency}
        setShowModal={setShowInsuranceEmergency}
        handleYes={handleInsuranceEmergencySubmit}
        section3={section3}
        setSection3={setSection3}
      />
      <EditInsuranceDiscount
        showModal={showInsuranceDiscount}
        setShowModal={setShowInsuranceDiscount}
        handleYes={handleInsuranceDiscountSubmit}
        section4={section4}
        setSection4={setSection4}
      />
      <EditInsuranceDiscountType
        handleYes={handleDiscountType}
        showModal={showDiscountTypeModal}
        setShowModal={setShowDiscountTypeModal}
        section5={section5}
        discountListArr={discountListArr}
        setDiscountListArr={setDiscountListArr}
        setSection5={setSection5}
      />
      <EditInsurancePremiere
        showModal={showInsurancePremiere}
        setShowModal={setShowInsurancPremiere}
        handleYes={handleInsurancePremiereSubmit}
        section6={section6}
        setSection6={setSection6}
      />
      <EditInsurancePremiereType
        showModal={showInsurancePremiereType}
        setShowModal={setShowInsurancPremiereType}
        handleYes={handleInsurancePremiereTypeSubmit}
        section7={section7}
        setSection7={setSection7}
      />
      <EditInsuranceSpecial
        showModal={showInsuranceSpecial}
        setShowModal={setShowInsurancSpecial}
        handleYes={handleInsuranceSpecialSubmit}
        section8={section8}
        setSection8={setSection8}
      />
      <EditInsuranceSpecialInfo
        showModal={showInsuranceSpecialInfo}
        setShowModal={setShowInsurancSpecialInfo}
        handleYes={handleInsuranceSpecialInfoSubmit}
        section9={section9}
        setSection9={setSection9}
      />
      <EditInsuranceSpecialType
        showModal={showInsuranceSpecialType}
        setShowModal={setShowInsurancSpecialType}
        handleYes={handleInsuranceSpecialTypeSubmit}
        section10={section10}
        setSection10={setSection10}
        handleYes2={handleInsuranceSpecialType2Submit}
        section11={section11}
        setSection11={setSection11}
        handleYes3={handleInsuranceSpecialType3Submit}
        section12={section12}
        setSection12={setSection12}
      />
    </Content>
  )
}
export default AutoInsurance
