import React from 'react'
import FormInputComponent from '../../../components/FormInputComponent/FormInputComponent'
import Button from '../../../components/Inputs/Button'
import style from './login.module.css'

const Login = () => {
  return (
    <div
      className={style.container}
      style={{
        backgroundImage: 'url(/images/header-img.jpg) ',
      }}>
      <div className={style.centerContainer}>
        <div className={style.topContainer}>
          <div className={style.leftContainer}>
            <div className={style.input1}>
              <FormInputComponent
                heading='Username/Email'
                placeholder='Enter your username or email address'
                style={{ width: '100%' }}
                required={true}
                // value={userName.value}
                onChange={(e) => {}}
              />
            </div>
            <div className={style.input2}>
              <FormInputComponent
                heading='Password'
                placeholder='•••••••••••••••••••'
                type='password'
                style={{ width: '100%' }}
                required={true}
                // value={password.value}
                onChange={(e) => {}}
              />
            </div>
            <div className={style.input2}>
              <div className={style.buttons}>
                <Button
                  label='login'
                  varient='red'
                  icon='/sprite.svg#icon-login'
                  style={{
                    height: '5rem',
                  }}
                  onClick={() => {}}
                />
                <Button
                  label='sign up'
                  varient='red'
                  icon='/sprite.svg#icon-login'
                  style={{
                    height: '5rem',
                  }}
                  onClick={() => {}}
                />
              </div>
            </div>
          </div>
          <div className={style.line}></div>

          <div className={style.rightContainer}>
            <div>
              <div className={style.header}>Portal Payment</div>
              <div className={style.desc}>
                Make a portal payment without logging into your account
              </div>
              <div className={style.input1}>
                <Button
                  label='Portal Payment'
                  varient='red'
                  icon='/sprite.svg#icon-login'
                  style={{
                    width: '100%',
                  }}
                  onClick={() => {}}
                />
              </div>
            </div>
            <div
              style={{
                marginTop: '2rem',
              }}>
              <div className={style.header}>Quick Pay</div>
              <div className={style.desc}>
                Make a quick payment without logging into your account
              </div>
              <div className={style.input1}>
                <Button
                  label='Quick Pay'
                  varient='red'
                  icon='/sprite.svg#icon-login'
                  style={{
                    width: '100%',
                  }}
                  onClick={() => {}}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={style.bottomContainer}>
          <div className={style.header}>
            If you need immediate assistance, please contact:
          </div>
          <div className={style.header3}>Customer Service Support</div>
          <div className={style.header2}>
            Phone: <span className={style.phone}>202-555-0157</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
