import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import ReactPaginate from 'react-paginate'
import { deleteContentItem, imageUpload, addContentItem, addContent } from '../../../../redux/actions/contentAction'

const itemsPerPage = 10
let timeout = null;
const EditFindAgent = ({ setShowModal, showModal, handleYes, data, setData, pageName }) => {
  const [loading, setLoading] = useState(false)
  const [uploadImagePreview, setUploadImagePreview] = useState('')
  const [uploadImage, setUploadImage] = useState(null)


  const [headerText, setHeaderText] = useState({ value: data?.header?.value, error: '' })
  const [stepList, setStepList] = useState(data.profiles.value)

  const [editIndex, setEditIndex] = useState(-1)
  const [editData, setEditData] = useState({
    title: { value: "", error: "" },
    subtitle: { value: "", error: "" },
    icon: { value: "", error: "" }
  })

  useEffect(() => {
    if (data?.profiles.value)
      setStepList(data.profiles.value)
  }, [data.profiles])

  useEffect(() => {
    setHeaderText({ value: data?.header?.value, error: '' })
  }, [data.header])

  const handleSave = async () => {
    if (editData.title.value === '') {
      setEditData({ ...editData, title: { ...editData.title, error: 'Please enter title!' } });
      return
    }
    if (editData.subtitle.value === '') {
      setEditData({ ...editData, subtitle: { ...editData.subtitle, error: 'Please enter subtitle!' } });
      return
    }
    if (editData.title.value === '' && !uploadImage) {
      setEditData({ ...editData, title: { ...editData.title, error: 'Please enter title!' } });
      return
    }
    let temp = [...stepList];
    let path = ''
    if (uploadImage) {
      const filePath = await imageUpload(uploadImage)
      path = filePath?.data?.path
    } else {
      path = editData.icon.value
    }
    if (editIndex > -1) {
      temp[editIndex] = {
        title: editData.title.value,
        subTitle: editData.subtitle.value,
        icon: path,
      }
    } else {
      let step = {
        title: editData.title.value,
        subTitle: editData.subtitle.value,
        icon: path
      }
      temp.push(step)

    }

    clearErrors()
    let content = {
      ...data,
      profiles: { ...data.profiles, value: JSON.stringify(temp) }
    }
    setLoading(true);
    addContent({ pageName: pageName, section: "Section_4", content })
      .then(result => {
        setLoading(false);
        if (result.data.status) {
          clear()
          alert("Saved!");
          setData({ ...data, profiles: { ...data.profiles, value: temp } })
          setStepList(temp)
        }
      }).catch(err => setLoading(false))



  }
  const handleHeaderSave = () => {
    if (headerText.value == '') {
      setHeaderText({ ...headerText, error: 'Please enter text!' });
      return
    }

    let content = {
      header: { ...data.header1, value: headerText.value },
      profiles: { ...data.profiles, value: JSON.stringify(data.profiles.value) }
    }
    addContent({ pageName: pageName, section: "Section_4", content })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          alert("Header Text Saved!")
        }
      })

  }

  const onEdit = (item, index) => {
    // e.preventDefault();
    setUploadImagePreview("");
    setUploadImage(null)
    setEditData({
      title: { value: item.title, error: "" },
      subtitle: { value: item.subTitle, error: "" },
      icon: { value: item.icon, error: "" }
    })
    setEditIndex(index)
    // setHeaderText({ ...headerText, value: item.title })
  }
  const clear = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      title: { value: "", error: "" },
      subtitle: { value: "", error: "" },
      icon: { value: "", error: "" }
    })
    setEditIndex(-1)
    setUploadImage(null)
    // setShowModal(false)
    // handleAgentSubmit()
  }


  const resetAll = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      title: { value: "", error: "" },
      subtitle: { value: "", error: "" },
      icon: { value: "", error: "" }
    })
    setUploadImage(null)
    setEditIndex(-1)
    // setHeaderText({ ...headerText, value: "", error: "" })
  }
  const clearErrors = () => {
    setHeaderText({ ...headerText, error: "" })
    setEditData({ ...editData, title: { ...editData.title, error: '' } });
    setEditData({ ...editData, subtitle: { ...editData.subtitle, error: '' } });
    setEditData({ ...editData, icon: { ...editData.icon, error: '' } });
  }

  const onDrop = useCallback((acceptedFiles) => {
    encodeImageFileAsURL(acceptedFiles)
  }, [])
  const encodeImageFileAsURL = (files) => {
    var file = files[0]
    setUploadImage(file)
    var reader = new FileReader()
    reader.onloadend = function () {
      if (reader.result) {
        setUploadImagePreview(reader.result)
      }
    }
    reader.readAsDataURL(file)
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop,
  })

  const onStepDelete = (item, index) => {
    if (window.confirm('Are you sure you want to delete?')) {
      let temp = [...stepList];
      temp.splice(index, 1);
      setStepList(temp);
      let content = {
        title: { ...data.title, value: headerText.value },
        profiles: { ...data.profiles, value: JSON.stringify(temp) }
      }
      addContent({ pageName: pageName, section: "Section_4", content })
        .then(result => {
          if (result.data.status) {
            alert("Deleted!");
            setData({ ...data, profiles: { ...data.profiles, value: temp } })
          }
        })
    }
  }
  const reposition = (index, item, direction) => {
    const tempArr = stepList.filter(
      (element, position) => index !== position
    );
    if (direction === "up" && index > 0) {
      tempArr.splice(index - 1, 0, item);
      clearTimeout(timeout);
    } else if (direction === "down" && index < stepList.length) {
      tempArr.splice(index + 1, 0, item);
      clearTimeout(timeout);
    } else return;
    setStepList(tempArr);
    timeout = setTimeout(() => {
      let content = {
        ...data,
        profiles: { ...data.profiles, value: JSON.stringify(tempArr) }
      }
      setData({ ...data, profiles: { ...data.profiles, value: tempArr } })
      addContent({ pageName: pageName, section: "Section_4", content })
        .then(result => {
          if (result.data.status) {
            alert("Header Saved!")
          }
        })
    }, 2000);
  }
  return (
    <Modal show={showModal}>
      <form onSubmit={handleYes}>
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Section 4</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => setShowModal(false)}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Header Text'
                    style={{ width: '70%' }}
                    value={headerText.value}
                    onChange={(e) => {
                      setHeaderText({ error: '', value: e.target.value })
                    }}
                    errorMessage={headerText.error}
                  />
                  <button type='button' style={{
                    alignSelf: 'center', height: '3rem', width: '8rem', justifyContent: 'center',
                    paddingLeft: '1rem', paddingRight: '1rem', marginTop: '2.5rem', cursor: 'pointer',
                    color: 'white', backgroundColor: 'red', border: 'none', borderRadius: '4px'
                  }} onClick={handleHeaderSave}>Save</button>
                </div>


                <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} />
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: '1' }}>
                    <div className='inline-form-view'>
                      <FormInputComponent
                        heading='Title:'
                        style={{ width: '100%' }}
                        value={editData.title.value}
                        onChange={(e) => {
                          let temp = { ...editData }
                          temp.title.value = e.target.value
                          setEditData(temp)
                        }}
                        errorMessage={editData.title.error}
                      />
                    </div>
                    <div className='inline-form-view'>
                      <FormInputComponent
                        heading='Sub Title:'
                        style={{ width: '100%' }}
                        type={'text'}
                        value={editData.subtitle.value}
                        onChange={(e) => {
                          let temp = { ...editData }
                          temp.subtitle.value = e.target.value
                          setEditData(temp)
                        }}
                        errorMessage={editData.subtitle.error}
                      />
                    </div>

                  </div>
                  <div style={{ justifyContent: 'inherit' }} className='modal_inner_content--right3'>
                    {/* <label>Icon Preview</label>
                    <div
                      className='upload_border'
                      style={{ marginTop: '-1rem', width: '15rem', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {editData.icon.value ? <img alt='Icon' height={80} width={80} src={editData.icon.value} /> : null}
                    </div> */}
                    <div>
                      <div className='upload_border' {...getRootProps()}>
                        <input {...getInputProps()} />
                        <label>Click or Drag a image to this area to upload</label>
                        <svg className='modal_upload__icon-dark'>
                          <use xlinkHref='/sprite.svg#icon-cloud' />
                        </svg>
                      </div>
                      <div
                        className='upload_border'
                        style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#ededed" }}>
                        {(uploadImagePreview || editData.icon.value) ? <img alt='Icon' height={80} width={80} src={uploadImagePreview ? uploadImagePreview : editData.icon.value} /> : null}
                      </div>
                    </div>
                    <div
                      className='modal_dark_buttons'
                      style={{ padding: '0rem', paddingTop: '10rem' }}>
                      <button type='button' className='modal__button-reset-dark' onClick={resetAll}>Reset</button>
                      <button type='button' className='modal__button-dark' onClick={handleSave}>{loading ? 'Saving..' : (editIndex > -1 ? 'Save' : 'Add')}</button>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div className='modal_inner_content'>
              <div className='modal_form_container'>
                <table
                  className='modal__form-table'
                  cellspacing='0'
                  cellpadding='0'>
                  <thead className='modal__form-sticky'>
                    <tr className='modal__form-tr'>
                      <th>No.</th>
                      <th>Title</th>
                      <th>Sub Title</th>
                      <th>Order</th>
                      <th style={{ textAlign: 'right', paddingRight: '5rem' }}>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {stepList && stepList.length &&
                      stepList.map((item, i) => {
                        return (
                          <tr className='modal__form-tr2' key={i}>
                            <td>{i + 1}</td>
                            <td>{item.title}</td>
                            <td>{item.subTitle}</td>
                            <td>
                              <td>
                                <button type="button" onClick={(e) => reposition(i, item, 'up')}>
                                  <svg className="modal_heading__icon-dark">
                                    <use xlinkHref="/sprite.svg#icon-up" />
                                  </svg>
                                </button>
                                &nbsp;
                                <button type="button" onClick={(e) => reposition(i, item, 'down')}>
                                  <svg className="modal_heading__icon-dark">
                                    <use xlinkHref="/sprite.svg#icon-down" />
                                  </svg>
                                </button>
                              </td>
                            </td>
                            <td className='modal__form-buttons' style={{ textAlign: 'center' }}>
                              <button type='button' className='modal__button-edit' onClick={(e) => onEdit(item, i)}>
                                edit
                              </button>
                              <button type='button' className='modal__button-delete'
                                onClick={(e) => onStepDelete(item, i)}
                              >
                                delete
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>

          </div>

          {/* <div className='modal_dark_buttons' style={{ paddingTop: '0' }}>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={(e) => handleSave()}>
                Submit
              </button>
            )}
          </div> */}
        </div>
      </form>
    </Modal >
  )
}

export default EditFindAgent
