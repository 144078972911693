import React, { useState, useEffect } from 'react';
import Modal from '../../../components/Modal';
import { validateEmail, validatePhone } from '../utils/validators';
import FormInputComponent from '../../../components/FormInputComponent/FormInputComponent';
import { searchListArr } from './data';

import './ContactMe.css';
import { contactMeAction } from '../../../redux/actions/mailActions';
import { useDispatch, useSelector } from 'react-redux';
import { SEND_MAIL_RESET } from '../../../redux/constants/mailConstants';
import { inputPhoneMasking } from '../utils/phonemask';

export const ContactModal = ({ contactModal, handleClose, agentId }) => {
	const dispatch = useDispatch();

	const { agentList } = useSelector(state => state.agentList)
	const [agentData, setAgentData] = useState({});
	const [firstName, setFirstName] = useState('');
	const [firstNameError, setFirstNameError] = useState('');
	const [lastName, setLastName] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');
	const [phone, setPhone] = useState('');
	const [phoneError, setPhoneError] = useState('');
	const [comments, setComments] = useState('');
	const contactMe = useSelector((state) => state.contactMe);

	useEffect(
		() => {
			if (agentList && agentList.status && agentList.result.length > 0) {
				let obj = agentList.result.find((o) => o.email === agentId);
				setAgentData(obj);
			}
		},
		[agentList, agentId]
	);
	useEffect(
		() => {
			if (agentList && agentList.status && agentList.result.length > 0) {
				let obj = agentList.result.find((o) => o.email === agentId);
				setAgentData(obj);
			}
		},
		[]
	);


	useEffect(
		() => {
			if (contactMe && contactMe.userInfo && contactMe.userInfo.status) {
				alert(contactMe.userInfo.message);
				clearData();
				dispatch({ type: SEND_MAIL_RESET })
			} else if (contactMe && contactMe.userInfo && !contactMe.userInfo.status && contactMe.userInfo.message) {
				alert(contactMe.userInfo.message);
				dispatch({ type: SEND_MAIL_RESET })
			}
		},
		[contactMe]
	);

	const clearData = async (e) => {
		setFirstName('');
		setFirstNameError('');
		setLastName('');
		setLastNameError('');
		setEmail('');
		setEmailError('');
		setPhone('');
		setPhoneError('');
		setComments('');
		handleClose();
	}

	const onFormSubmit = async (e) => {
		e.preventDefault();
		if (firstName === '' && firstName.trim() === '') {
			setFirstNameError('Please enter first name');
		} else if (lastName === '' && lastName.trim() === '') {
			setLastNameError('Please enter last name');
		} else if (email === '' && email.trim() === '') {
			setEmailError('Please enter email address');
		} else if (!validateEmail(email)) {
			setEmailError('Please enter valid email');
		} else if (phone === '' && phone.trim() === '') {
			setPhoneError('Please enter phone');
		} else if (!validatePhone(phone)) {
			setPhoneError('Phone number must be 10 digits');
		} else {
			let formData = {
				to: agentData.email,
				name: agentData.title,
				from_email: email,
				subject: 'Contact Me',
				body: {
					firstName: firstName,
					lastName: lastName,
					email: email,
					phone: phone,
					comments: comments
				}
			};
			dispatch(contactMeAction(formData));
		}
	};

	return (
		<Modal show={contactModal}>
			<form onSubmit={onFormSubmit}>
				<div className="modal-container">
					<div className="modal_container_heading">
						<h1 className="modal_heading_text">Contact {agentData.title} </h1>
						<button className="modal__heading--button" onClick={clearData}>
							<svg className="modal_heading__icon">
								<use xlinkHref="/sprite.svg#icon-close" />
							</svg>
						</button>
					</div>
					<h2 className="modalContainer_subheading">
						Please use the form below to send me a message with your questions or concerns. <br />I look
						forward to hearing from you!
					</h2>
					<div className="pre_heading1">
						<FormInputComponent
							heading="Name"
							subheading="First"
							style={{ width: '45%' }}
							required={true}
							value={firstName}
							onChange={(e) => {
								setFirstName(e.target.value);
								setFirstNameError('');
							}}
							errorMessage={firstNameError}
						/>
						<FormInputComponent
							subheading="Last"
							style={{ width: '44%' }}
							value={lastName}
							onChange={(e) => {
								setLastName(e.target.value);
								setLastNameError('');
							}}
							errorMessage={lastNameError}
						/>
					</div>
					<div className="pre_heading1">
						<FormInputComponent
							heading="Email"
							style={{ width: '45%' }}
							required={true}
							value={email}
							onChange={(e) => {
								setEmail(e.target.value);
								setEmailError('');
							}}
							errorMessage={emailError}
						/>
						<FormInputComponent
							heading="Phone"
							style={{ width: '45%' }}
							required={true}
							value={phone}
							onChange={(e) => {
								setPhone(inputPhoneMasking(e.target.value));
								setPhoneError('');
							}}
							errorMessage={phoneError}
						/>
					</div>
					<div className="label_heading_quote1">
						<label className="label_heading_quote"> Questions/Comments</label>
						<div className="form_textbox1">
							<textarea
								className="form_textarea"
								rows="10"
								cols="30"
								value={comments}
								onChange={(e) => {
									setComments(e.target.value);
								}}
							/>
						</div>
					</div>
					<div>
						<div className="submit_button">
							<button className="main__form_button1" type="submit" onClick={onFormSubmit}>
								Submit
							</button>
						</div>
					</div>
				</div>
			</form>
		</Modal>
	);
};
export default ContactModal;
