import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
let fileIndex = -1;
const EditSection3 = ({ setShowModal, showModal, handleYes, section3, setSection3, loading }) => {

  const [uploadImagePreview, setUploadImagePreview] = useState(null)
  const [uploadImage, setUploadImage] = useState(null)
  const [uploadImagePreview1, setUploadImagePreview1] = useState(null)
  const [uploadImage1, setUploadImage1] = useState(null)

  const onDrop = (files) => {
    // Do something with the files
    var file = files[0];
    if (file) {
      switch (fileIndex) {
        case 1:
          setUploadImage(file)
          break;
        case 2:
          setUploadImage1(file)
          break;
        default:

      }
      encodeImageFileAsURL(file)
    }
  }
  const encodeImageFileAsURL = (file,) => {
    var reader = new FileReader()
    reader.onloadend = function () {
      if (reader.result) {
        if (fileIndex === 1)
          setUploadImagePreview(reader.result)
        else
          setUploadImagePreview1(reader.result)
      }
    }
    reader.readAsDataURL(file)
  }
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop,
  })

  useEffect(() => {
    if (section3.image1.value)
      setUploadImagePreview(section3.image.value)
  }, [])
  const submit = () => {
    let temp = { ...section3 };
    if (section3.title.value === '') {
      temp.title.error = 'Please enter title!'
      setSection3(temp)
      return
    } else {
      temp.title.error = ''
      setSection3(temp)
    }
    if (section3.subTitle.value === '') {
      temp.subTitle.error = 'Please enter label!'
      setSection3(temp)
      return
    } else {
      temp.subTitle.error = ''
      setSection3(temp)
    }
    if (section3.description.value === '') {
      temp.description.error = 'Please enter description!'
      setSection3(temp)
      return
    } else {
      temp.description.error = ''
      setSection3(temp)
    }
    if (section3.image1Link.value === '') {
      temp.image1Link.error = 'Please enter image1 link!'
      setSection3(temp)
      return
    } else {
      temp.image1Link.error = ''
      setSection3(temp)
    }
    if (section3.image2Link.value === '') {
      temp.image2Link.error = 'Please enter image1 link!'
      setSection3(temp)
      return
    } else {
      temp.image2Link.error = ''
      setSection3(temp)
    }
    handleYes(temp, uploadImage, uploadImage1)
  }
  return (
    <Modal show={showModal}>
      <form >
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Info</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => setShowModal(false)}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '90%' }}
                    required={true}
                    value={section3.title.value}
                    onChange={(e) => {
                      let temp = { ...section3 }
                      temp.title.value = e.target.value
                      setSection3(temp)
                    }}
                    errorMessage={section3.title.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Sub Title:'
                    style={{ width: '90%' }}
                    type={'textarea'}
                    required={true}
                    height={'8rem'}
                    value={section3.subTitle.value}
                    onChange={(e) => {
                      let temp = { ...section3 }
                      temp.subTitle.value = e.target.value
                      setSection3(temp)
                    }}
                    errorMessage={section3.subTitle.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Description:'
                    style={{ width: '90%' }}
                    type={'textarea'}
                    required={true}
                    height={'8rem'}
                    value={section3.description.value}
                    onChange={(e) => {
                      let temp = { ...section3 }
                      temp.description.value = e.target.value
                      setSection3(temp)
                    }}
                    errorMessage={section3.description.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Image1 Link:'
                    style={{ width: '90%' }}
                    required={true}
                    value={section3.image1Link.value}
                    onChange={(e) => {
                      let temp = { ...section3 }
                      temp.image1Link.value = e.target.value
                      setSection3(temp)
                    }}
                    errorMessage={section3.image1Link.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Image2 Link:'
                    style={{ width: '90%' }}
                    required={true}
                    value={section3.image2Link.value}
                    onChange={(e) => {
                      let temp = { ...section3 }
                      temp.image2Link.value = e.target.value
                      setSection3(temp)
                    }}
                    errorMessage={section3.image2Link.error}
                  />
                </div>

              </div>
              <div className='modal_inner_content--right'>
                <div>
                  <label>Please choose image to upload</label>
                  <div className='upload_border' {...getRootProps({
                    onClick: (e) => {
                      fileIndex = 1;
                    }
                  })}>
                    <input {...getInputProps()} />
                    <label>Click or Drag a image to this area to upload</label>
                    <svg className='modal_upload__icon-dark'>
                      <use xlinkHref='/sprite.svg#icon-cloud' />
                    </svg>
                  </div>
                  <div className='upload_preview_modal'>
                    <img width={100} height={50} alt={"background"} src={uploadImagePreview ? uploadImagePreview : section3.image1.value}></img>
                  </div>
                </div>
                <div>
                  <label>Please choose image to upload</label>
                  <div key={"uploadKey2"} className='upload_border' {...getRootProps({
                    onClick: (e) => {
                      fileIndex = 2;
                    }
                  })}>
                    <input key={"uploadKey1"} {...getInputProps()} />
                    <label>Click or Drag a image to this area to upload</label>
                    <svg className='modal_upload__icon-dark'>
                      <use xlinkHref='/sprite.svg#icon-cloud' />
                    </svg>
                  </div>
                  <div className='upload_preview_modal'>
                    <img width={100} height={50} alt={"background"} src={uploadImagePreview1 ? uploadImagePreview1 : section3.image2.value}></img>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='modal_dark_buttons'>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={submit}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditSection3

