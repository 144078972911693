import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

if (process.env.REACT_APP_NODE_ENV && process.env.REACT_APP_NODE_ENV === 'production') {
	// restrict logs on production
	console.log = () => { }
	console.error = () => { }
	console.info = () => { }
	console.debug = () => { }
	console.warn = () => { }
}

ReactDOM.render(
	<Provider store={store} >
		<MsalProvider instance={msalInstance}>
			<App />
		</MsalProvider>
	</Provider>,
	document.getElementById('root')
);

reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();