import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getPageContent } from '../../redux/actions/contentAction'
import EditFooterModal from '../../screens/MWFBI/HomeScreen/edit/EditFooter'
import { ADMIN_USERS } from '../../redux/actions/ip'
const FooterNavigation = () => {

  const azureLogin = useSelector(state => state.azureLogin)
  const [showEdit, setShowEdit] = useState(false)
  const [showFooterModal, setShowFooterModal] = useState(false);
  const [footerData, setSetFooterData] = useState({
    "tools": {
      "title": { "value": "Tools", "link": "", "error": "" },
      "payBill": { "value": "Pay Bill", "link": "https://ipn.paymentus.com/rotp/mwfb", "error": "" }, "startQuote": { "value": "Start A Quote", "link": "/mwfbi/start-a-quote", "error": "" }, "findAnAgent": { "value": "Find an Agent", "link": "/mwfbi/find-an-agent", "error": "" }, "contactUs": { "value": "Contact Us", "link": "/mwfbi/contact", "error": "" }
    },
    "insurance": {
      "title": { "value": "Insurance Offerings", "error": "" },
      "autoInsurance": { "value": "Auto Insurance", "link": "/mwfbi/auto-insurance", "error": "" },
      "homeInsurance": { "value": "Home Insurance", "link": "/mwfbi/home-owners-insurance", "error": "" },
      "farmInsurance": { "value": "Farm/Ranch Insurance", "link": "/mwfbi/farm-&-ranch-insurance", "error": "" },
      "businessInsurance": { "value": "Business Insurance", "link": "/mwfbi/business-insurance", "error": "" }
    },
    "other": {
      "title": { "value": "Other Offerings", "error": "" },
      "lifeInsurance": { "value": "Life Insurance", "link": "/mwfbi/life-insurance", "error": "" }, "annuities": { "value": "Annuities", "link": "/mwfbi/annuities", "error": "" }, "financialServices": { "value": "Financial Services", "link": "/mwfbi/other-offerings", "error": "" }
    },
    "resources": {
      "title": { "value": "Resources", "error": "" },
      "learningCenter": { "value": "Learning Center", "link": "/mwfbi/about/learning-center", "error": "" }, "FAQs": { "value": "FAQs", "link": "/mwfbi/faq", "error": "" }, "Career": { "value": "Career", "link": "/mwfbi/about/careers", "error": "" }, "companyLogin": { "value": "Company Login", "link": "https://myportal.mwfbi.com/web/public/home", "error": "" }
    },
    "contact": {
      "doYouHaveQuestions": { "value": "Do you have questions?", "link": "", "error": "" },
      "contact": { "value": "Contact us", "link": "", "error": "" }, "phone": { "value": "1-877-742-9991", "link": "", "error": "" }
    }
  });

  useEffect(() => {
    getPageContent({ pageName: "MW_Home" })
      .then(res => {
        if (res.data.status) {
          if (res.data.result.Section_5_Navigation && res.data.result.Section_5_Navigation.navList) {
            setSetFooterData(JSON.parse(res.data.result.Section_5_Navigation.navList.value))
            // console.log(JSON.stringify(JSON.parse(res.data.result.Section_5_Navigation.navList.value)));
          }
        }
      })
  }, [])
  useEffect(() => {
    if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
      if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
        setShowEdit(true)
      } else setShowEdit(false)
    } else setShowEdit(false)
  }, [azureLogin])
  useEffect(() => {
    if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
      if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
        setShowEdit(true)
      } else setShowEdit(false)
    } else setShowEdit(false)
  }, [])

  return (
    <div className='footer__navigation ' style={{ width: '100%', position: 'relative' }}>
      <div className='footer__navigation--links'>
        <p className='footer__navigation--heading'>{footerData.tools.title.value}</p>
        <a
          href={footerData.tools.payBill.link}
          target={'_blank'}
          className='footer__navigation--link'>
          <svg className='footer__navigation--linkIcon'>
            <use xlinkHref='/sprite.svg#icon-arrow_right' />
          </svg>
          {footerData.tools.payBill.value}
        </a>
        <Link to={footerData.tools.startQuote.link} className='footer__navigation--link'>
          <svg className='footer__navigation--linkIcon'>
            <use xlinkHref='/sprite.svg#icon-arrow_right' />
          </svg>
          {footerData.tools.startQuote.value}
        </Link>
        <Link to={footerData.tools.findAnAgent.link} className='footer__navigation--link'>
          <svg className='footer__navigation--linkIcon'>
            <use xlinkHref='/sprite.svg#icon-arrow_right' />
          </svg>
          {footerData.tools.findAnAgent.value}
        </Link>
        <Link to={footerData.tools.contactUs.link} className='footer__navigation--link'>
          <svg className='footer__navigation--linkIcon'>
            <use xlinkHref='/sprite.svg#icon-arrow_right' />
          </svg>
          {footerData.tools.contactUs.value}
        </Link>
      </div>
      <div className='footer__navigation--links'>
        <p className='footer__navigation--heading'>{footerData.insurance.title.value}</p>
        <Link to={footerData.insurance.autoInsurance.link} className='footer__navigation--link'>
          <svg className='footer__navigation--linkIcon'>
            <use xlinkHref='/sprite.svg#icon-arrow_right' />
          </svg>
          {footerData.insurance.autoInsurance.value}
        </Link>
        <Link
          to={footerData.insurance.homeInsurance.link}
          className='footer__navigation--link'>
          <svg className='footer__navigation--linkIcon'>
            <use xlinkHref='/sprite.svg#icon-arrow_right' />
          </svg>
          {footerData.insurance.homeInsurance.value}
        </Link>
        <Link
          to={footerData.insurance.farmInsurance.link}
          className='footer__navigation--link'>
          <svg className='footer__navigation--linkIcon'>
            <use xlinkHref='/sprite.svg#icon-arrow_right' />
          </svg>
          {footerData.insurance.farmInsurance.value}
        </Link>
        <Link
          to={footerData.insurance.businessInsurance.link}
          className='footer__navigation--link'>
          <svg className='footer__navigation--linkIcon'>
            <use xlinkHref='/sprite.svg#icon-arrow_right' />
          </svg>
          {footerData.insurance.businessInsurance.value}
        </Link>
      </div>
      <div className='footer__navigation--links'>
        <p className='footer__navigation--heading'>{footerData.other.title.value}</p>
        <Link to={footerData.other.lifeInsurance.link} className='footer__navigation--link'>
          <svg className='footer__navigation--linkIcon'>
            <use xlinkHref='/sprite.svg#icon-arrow_right' />
          </svg>
          {footerData.other.lifeInsurance.value}
        </Link>
        <Link to={footerData.other.annuities.link} className='footer__navigation--link'>
          <svg className='footer__navigation--linkIcon'>
            <use xlinkHref='/sprite.svg#icon-arrow_right' />
          </svg>
          {footerData.other.annuities.value}
        </Link>
        <Link to={footerData.other.financialServices.link} className='footer__navigation--link'>
          <svg className='footer__navigation--linkIcon'>
            <use xlinkHref='/sprite.svg#icon-arrow_right' />
          </svg>
          {footerData.other.financialServices.value}
        </Link>
      </div>
      <div className='footer__navigation--links'>
        <p className='footer__navigation--heading'>{footerData.resources.title.value}</p>
        <Link
          to={footerData.resources.learningCenter.link}
          className='footer__navigation--link'>
          <svg className='footer__navigation--linkIcon'>
            <use xlinkHref='/sprite.svg#icon-arrow_right' />
          </svg>
          {footerData.resources.learningCenter.value}
        </Link>
        <Link to={footerData.resources.FAQs.link} className='footer__navigation--link'>
          <svg className='footer__navigation--linkIcon'>
            <use xlinkHref='/sprite.svg#icon-arrow_right' />
          </svg>
          {footerData.resources.FAQs.value}
        </Link>
        <Link to={footerData.resources.Career.link} className='footer__navigation--link'>
          <svg className='footer__navigation--linkIcon'>
            <use xlinkHref='/sprite.svg#icon-arrow_right' />
          </svg>
          {footerData.resources.Career.value}
        </Link>
        <a
          href={footerData.resources.companyLogin.link}
          target='_blank'
          className='footer__navigation--link'>
          <svg className='footer__navigation--linkIcon'>
            <use xlinkHref='/sprite.svg#icon-arrow_right' />
          </svg>
          {footerData.resources.companyLogin.value}
        </a>
      </div>

      <div className='footer__navigation--links'>
        <div className='footer__navigation--linkIconCallContainer'>
          <svg className='footer__navigation--linkIconCall'>
            <use xlinkHref='/sprite.svg#icon-phone' />
          </svg>
          <p className='footer__navigation--heading'>___________</p>
        </div>
        <Link
          to={footerData.contact.doYouHaveQuestions.link}
          className='footer__navigation--link'
          style={{ fontWeight: '600' }}>
          {footerData.contact.doYouHaveQuestions.value}
        </Link>
        <Link
          to={footerData.contact.contact.link}
          className='footer__navigation--link'
          style={{ color: 'red' }}>
          {footerData.contact.contact.value}
        </Link>
        <Link to={footerData.contact.phone.link} className='footer__navigation--link'>
          {footerData.contact.phone.value}
        </Link>
      </div>

      <EditFooterModal
        showModal={showFooterModal}
        setShowModal={setShowFooterModal}
        footerData={footerData}
        setSetFooterData={setSetFooterData}
      />

      {showEdit && <div
        className='mwfbi_banner__edit2'
        style={{ top: '1rem' }}
        onClick={() => setShowFooterModal(true)}>
        <svg>
          <use xlinkHref='/sprite.svg#icon-edit' />
        </svg>
      </div>}
    </div>
  )
}

export default FooterNavigation
