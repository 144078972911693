import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'

const EditSearch = ({
  setShowModal,
  showModal,
  handleYes,
  section4,
  setSection4,
}) => {
  const [loading, setLoading] = useState(false)

  const [title, setTitle] = useState({ value: '', error: '' })
  const [description, setDescription] = useState({ value: '', error: '' })
  const [question1, setQuestion1] = useState({ value: '', error: '' })
  const [question1Answer, setQuestion1Answer] = useState({
    value: '',
    error: '',
  })
  const [question2, setQuestion2] = useState({ value: '', error: '' })
  const [question2Answer, setQuestion2Answer] = useState({
    value: '',
    error: '',
  })
  const [estimateLabel, setEstimateLabel] = useState({ value: '', error: '' })
  const [estimateLink, setEstimateLink] = useState({ value: '', error: '' })

  useEffect(() => {}, [])
  const submit = (e) => {
    e.preventDefault()
    if (section4.title.value == '') {
      let temp = { ...section4 }
      temp.title.error = 'Please enter title.'
      setSection4(temp)
      return
    } else {
      let temp = { ...section4 }
      temp.title.error = ''
      setSection4(temp)
    }
    if (section4.description.value == '') {
      let temp = { ...section4 }
      temp.description.error = 'Please enter description.'
      setSection4(temp)
      return
    } else {
      let temp = { ...section4 }
      temp.description.error = ''
      setSection4(temp)
    }

    if (section4.question1.value == '') {
      let temp = { ...section4 }
      temp.question1.error = 'Please enter Question 1.'
      setSection4(temp)
      return
    } else {
      let temp = { ...section4 }
      temp.question1.error = ''
      setSection4(temp)
    }
    if (section4.question1Answer.value == '') {
      let temp = { ...section4 }
      temp.question1Answer.error = 'Please enter Answer 1.'
      setSection4(temp)
      return
    } else {
      let temp = { ...section4 }
      temp.question1Answer.error = ''
      setSection4(temp)
    }

    if (section4.question2.value == '') {
      let temp = { ...section4 }
      temp.question2.error = 'Please enter Question 2.'
      setSection4(temp)
      return
    } else {
      let temp = { ...section4 }
      temp.question2.error = ''
      setSection4(temp)
    }
    if (section4.question2Answer.value == '') {
      let temp = { ...section4 }
      temp.question2Answer.error = 'Please enter Answer 2.'
      setSection4(temp)
      return
    } else {
      let temp = { ...section4 }
      temp.question2Answer.error = ''
      setSection4(temp)
    }

    if (section4.estimateLabel.value == '') {
      let temp = { ...section4 }
      temp.estimateLabel.error = 'Please enter Estimate Label.'
      setSection4(temp)
      return
    } else {
      let temp = { ...section4 }
      temp.estimateLabel.error = ''
      setSection4(temp)
    }
    if (section4.estimateLink.value == '') {
      let temp = { ...section4 }
      temp.estimateLink.error = 'Please enter Estimate Link.'
      setSection4(temp)
      return
    } else {
      let temp = { ...section4 }
      temp.estimateLink.error = ''
      setSection4(temp)
    }
    handleYes()
    setShowModal(false)
  }
  return (
    <Modal show={showModal}>
      <form>
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Getting Started</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => setShowModal(false)}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '48%' }}
                    required={true}
                    value={section4.title.value}
                    onChange={(e) => {
                      let temp = { ...section4 }
                      temp.title.value = e.target.value
                      setSection4(temp)
                      setTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={section4.title.error}
                  />
                  <FormInputComponent
                    heading='Description:'
                    type={'textarea'}
                    style={{ width: '48%' }}
                    required={true}
                    value={section4.description.value}
                    onChange={(e) => {
                      let temp = { ...section4 }
                      temp.description.value = e.target.value
                      setSection4(temp)
                      setDescription({ error: '', value: e.target.value })
                    }}
                    errorMessage={section4.title.error}
                  />
                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Question 1:'
                    style={{ width: '48%' }}
                    required={true}
                    value={section4.question1.value}
                    onChange={(e) => {
                      let temp = { ...section4 }
                      temp.question1.value = e.target.value
                      setSection4(temp)
                      setQuestion1({ error: '', value: e.target.value })
                    }}
                    errorMessage={section4.question1.error}
                  />
                  <FormInputComponent
                    heading='Answer:'
                    style={{ width: '48%' }}
                    required={true}
                    value={section4.question1Answer.value}
                    onChange={(e) => {
                      let temp = { ...section4 }
                      temp.question1Answer.value = e.target.value
                      setSection4(temp)
                      setQuestion1Answer({ error: '', value: e.target.value })
                    }}
                    errorMessage={section4.question1Answer.error}
                  />
                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Question 2:'
                    style={{ width: '48%' }}
                    required={true}
                    value={section4.question2.value}
                    onChange={(e) => {
                      let temp = { ...section4 }
                      temp.question2.value = e.target.value
                      setSection4(temp)
                      setQuestion2({ error: '', value: e.target.value })
                    }}
                    errorMessage={section4.question2.error}
                  />
                  <FormInputComponent
                    heading='Answer 2:'
                    style={{ width: '48%' }}
                    required={true}
                    value={section4.question2Answer.value}
                    onChange={(e) => {
                      let temp = { ...section4 }
                      temp.question2Answer.value = e.target.value
                      setSection4(temp)
                      setQuestion2Answer({ error: '', value: e.target.value })
                    }}
                    errorMessage={section4.question2Answer.error}
                  />
                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Estimate Label:'
                    style={{ width: '48%' }}
                    required={true}
                    value={section4.estimateLabel.value}
                    onChange={(e) => {
                      let temp = { ...section4 }
                      temp.estimateLabel.value = e.target.value
                      setSection4(temp)
                      setEstimateLabel({ error: '', value: e.target.value })
                    }}
                    errorMessage={section4.estimateLabel.error}
                  />
                  <FormInputComponent
                    heading='Estimate Link:'
                    style={{ width: '48%' }}
                    required={true}
                    value={section4.estimateLink.value}
                    onChange={(e) => {
                      let temp = { ...section4 }
                      temp.estimateLink.value = e.target.value
                      setSection4(temp)
                      setEstimateLink({ error: '', value: e.target.value })
                    }}
                    errorMessage={section4.estimateLink.error}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='modal_dark_buttons'>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={submit}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditSearch
