import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom'
import axios from 'axios';
import Modal from '../../../components/Modal';
import { PAYMENT_BASE_URL, PAYMENT_US_URL } from '../../../redux/actions/ip';

const PaymentModal = ({ setPaymentModal, paymentModal, onQuickPayment, paymentMethod,
	setAccountDetails, onAccountDetails, setAlertMessage, setPaymentMessageModal }) => {
	const [accountNumber, setAccountNumber] = useState({ value: '', error: '' });
	const [accountDetail, setAccountDetail] = useState({
		billingAddressStreet: '',
		minAmountDue: '',
		policyHolderName: '',
		referenceNumber: '',
		billingZip: ''
	});
	const [name, setName] = useState({ value: '', error: '' });
	const [loading, setLoading] = useState(false);

	useEffect(
		() => {
			if (accountDetail.policyHolderName !== '' && accountDetail.referenceNumber !== '') {

			}
		},
		[accountDetail]
	);

	const handlePayment = async () => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		if (accountNumber.value === '' || accountNumber.value.trim() === '') {
			setAccountNumber({ ...accountNumber, error: 'Please enter your account number' });
			// 	 else if (name.value === '' || name.value.trim() === '') {
			// 	setName({ ...name, error: 'Please enter your name' });
			// }
		} else {
			if (paymentMethod && paymentMethod == 'quick') {
				setLoading(true);
				onQuickPayment(accountNumber.value)
					.then(res => {
						setLoading(false);
						setAccountNumber({ value: '', error: '' })
						if (res.status) {
							setPaymentModal(false)
						} else {
							if (res.showMessage) {
								setAccountNumber({ value: accountNumber.value, error: res.message })
							} else {
								setPaymentModal(false);
								const win = window.open(PAYMENT_US_URL, '_blank');
								if (win != null) {
									win.focus();
								}
							}
						}
					})
			} else {
				try {
					setLoading(true);
					const accountDetail = await axios.get(`${PAYMENT_BASE_URL}/auth/account-details/${accountNumber.value}`, config);
					if (accountDetail && accountDetail.data.status) {
						if (accountDetail.data.result.state === 'CO') {
							setAlertMessage('Account Not Found!');
							setPaymentMessageModal(true);
							setPaymentModal(false)
						} else {
							setAccountDetail(accountDetail.data.result);
							setAccountDetails(accountDetail.data.result);
							onAccountDetails(accountDetail.data.result)
						}
					}
					setLoading(false);
					setAccountNumber({ value: '', error: '' })
				} catch (error) {
					setName({ ...name, error: 'Something went wrong' });
					setLoading(false);
				}
			}
		}
	};


	return (
		<Modal show={paymentModal}>
			<div className='modal__container'>
				<div className='modal_heading__container'>
					<h1 className='modal_heading__text'>Account Details</h1>
					<button className='modal__heading--button' onClick={() => setPaymentModal(false)}>
						<svg className='modal_heading__icon'>
							<use xlinkHref='/sprite.svg#icon-close' />
						</svg>
					</button>
				</div>
				<div className='modal__form'>
					<label htmlFor='accno' className='modal__form--label'>
						Account Number
					</label>
					<input
						type='text'
						id='accno'
						value={accountNumber.value}
						onChange={(e) => setAccountNumber({ value: e.target.value })}
						placeholder='Your account number'
						className='modal__form--input'
					/>
					{accountNumber.error && <p className='modal__form--error'>{accountNumber.error}</p>}
				</div>
				{/* <div className='modal__form'>
					<label htmlFor='accno' className='modal__form--label'>
						Name
					</label>
					<input
						type='text'
						id='accno'
						value={name.value}
						onChange={(e) => setName({ value: e.target.value })}
						placeholder='Your name'
						className='modal__form--input'
					/>
					{name.error && <p className='modal__form--error'>{name.error}</p>}
				</div> */}
				{loading ? (
					<button className='modal__button'>Please wait...</button>
				) : (
					<button className='modal__button' onClick={handlePayment}>
						{paymentMethod && paymentMethod == 'quick' ? 'Proceed to Pay' : 'Get Account'}
					</button>
				)}
			</div>
		</Modal>
	);
};

export default PaymentModal;
