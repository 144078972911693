import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

import FormInputComponent from "../../../../../FormInputComponent/FormInputComponent";
import styles from "./FirstStepForm.module.css";
import { imageUpload } from "../../../../../../redux/actions/contentAction";

const FirstStepForm = ({ showModal, sectionArray, setSectionArray, currentSection, setCurrentSection, onSectionSave, step1Selection, setStep1Selection, step1ErrorMessage, setStep1ErrorMessage }) => {
  const [uploadImagePreview, setUploadImagePreview] = useState("");
  const [title, setTitle] = useState({ value: "", error: "" });
  const [link, setLink] = useState({ value: "", error: "" });
  const [uploadImage, setUploadImage] = useState(null);
  const [editSection, setEditSection] = useState();
  const [editIndex, setEditIndex] = useState(-1);
  const [selectedSection, setSelectedSection] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    encodeImageFileAsURL(acceptedFiles);
  }, []);

  const encodeImageFileAsURL = (files) => {
    var file = files[0];
    setUploadImage(file);
    var reader = new FileReader();
    reader.onloadend = function () {
      if (reader.result) {
        setUploadImagePreview(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg,image/png",
    onDrop,
  });


  useEffect(() => {
    if (step1Selection && step1Selection.selectedItem) {
      setEditIndex(step1Selection.index)
      setTitle({ ...title, value: step1Selection.selectedItem.title })
      setLink({ ...title, value: step1Selection.selectedItem?.link })
      setUploadImagePreview(step1Selection.selectedItem?.imageUrl)
      setEditSection(true)
    } else {
      setEditIndex(-1)
      setTitle({ ...title, value: '' })
      setEditSection(true)
      setUploadImagePreview('')
    }
  }, [step1Selection])

  const onSectionChange = (e) => {
    e.preventDefault();
    if (e.target.value !== '') {
      let selectedItem = sectionArray[parseInt(e.target.value)];
      setEditIndex(parseInt(e.target.value))
      setSelectedSection(selectedItem)
      setStep1Selection({ selectedItem, index: parseInt(e.target.value) })
      setTitle({ ...title, value: selectedItem.title })
      if (selectedItem.title === '') setEditSection(false)
      else setEditSection(true)
      setCurrentSection({ ...currentSection, title: selectedItem.title })
      setUploadImagePreview(selectedItem?.imageUrl)
      setStep1ErrorMessage('')
    }
  }


  const onSave = useCallback(async () => {
    if (editIndex === -1) return
    // if (title.value === '') {
    //   setTitle({ ...title, error: 'Please enter the title' })
    // }
    let item = { ...selectedSection, title: title.value, link: link.value }
    if (uploadImage) {
      const uploadedFile = await imageUpload(uploadImage);
      item.imageUrl = uploadedFile.data.path;
    }
    setSelectedSection(item);
    let tem = [...sectionArray]
    tem[editIndex] = item
    setSectionArray(tem)
    let temporaryTemp = { ...step1Selection }
    temporaryTemp.selectedItem.title = title.value;
    temporaryTemp.selectedItem.link = link.value;
    temporaryTemp.selectedItem.imageUrl = item.imageUrl;
    onSectionSave(temporaryTemp)
    console.log(temporaryTemp, 'temporaryTemp')
  }, [editIndex, onSectionSave, sectionArray, selectedSection, setSectionArray, title, uploadImage, step1Selection, link])

  return (
    <div className={styles.mainContainer}>
      <div className="leftConatiner">
        <div className="select_state">
          <label style={{ fontSize: "2rem" }} className="label_heading_quote">
            Select Section <span className="red_star">&#42;</span>
          </label>
          <br />
          <select
            id="selectstate"
            name="selectstate"
            className="landing_selectbox select__state__textbox"
            onChange={onSectionChange}
            value={step1Selection && step1Selection.index}
            style={{ marginBottom: '1rem' }}
          >
            <option value="">Select</option>
            {sectionArray.map((item, index) =>
              item.title.length > 0 ?
                <option
                  key={index}
                  value={index}
                  id="">
                  {item.title}
                </option> : null)
            }
          </select>{" "}
          <p style={{ marginBottom: '0.8rem' }} className="form_input__error">{step1ErrorMessage}</p>
        </div>
      </div>
      {step1Selection && step1Selection.index > -1 ?
        <div className="rightContainer">
          <FormInputComponent
            name="Section"
            headingStyle={styles.inputHeading}
            height={"5rem"}
            style={{ width: "30rem" }}
            heading={"Title"}
            value={title.value}
            // errorMessage={title.error && title.error}
            onChange={(e) => {
              setTitle({ error: '', value: e.target.value })
            }}
          />
          <FormInputComponent
            name="Section Link"
            headingStyle={styles.inputHeading}
            height={"5rem"}
            style={{ width: "30rem", marginTop: '1rem' }}
            heading={"Section Link"}
            value={link.value}
            // errorMessage={link.error && link.error}
            onChange={(e) => {
              setLink({ error: '', value: e.target.value })
            }}
          />
          <div className="modal_inner_content--right3">
            <label
              style={{
                fontSize: "1.4rem",
                marginTop: "1rem",
                color: "#808080",
              }}
            >
              Upload the image
            </label>
            <div
              className="upload_border"
              {...getRootProps()}
              style={{ marginTop: "-1rem", width: "185px" }}
            >
              <input {...getInputProps()} />
              {uploadImagePreview === "" ? (
                <>
                  <svg className="modal_upload__icon-dark">
                    <use xlinkHref="/sprite.svg#icon-cloud" />
                  </svg>
                  <label>Please choose image to upload</label>
                </>
              ) : (
                <img
                  alt="upload icon"
                  height={60}
                  width={60}
                  src={uploadImagePreview}
                  style={{ marginTop: "0rem", alignSelf: "center" }}
                />
              )}
            </div>
            <button
              style={{
                alignSelf: 'end',
                marginTop: '18px',
                marginRight: '-7px',
              }}
              type="button"
              className="modal__button-reset-dark"
              onClick={onSave}
            >
              Save
            </button>
          </div>
        </div>
        : undefined}
    </div>
  );
};

export default FirstStepForm;
