import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import { addContent, imageUpload } from '../../../../redux/actions/contentAction'

const EditInfo = ({ setShowModal, showModal, pageName, section1, setSection1 }) => {
  const [loading, setLoading] = useState(false)

  const [uploadImagePreview, setUploadImagePreview] = useState('')
  const [uploadImage, setUploadImage] = useState(null)

  const [title, setTitle] = useState({ value: '', error: '' })
  const [buttonTitle, setButtonTitle] = useState({ value: '', error: '' })
  const [buttonLink, setButtonLink] = useState({ value: '', error: '' })
  const [description1, setDescription1] = useState({ value: '', error: '' })
  const [description2, setDescription2] = useState({ value: '', error: '' })

  const onDrop = useCallback((acceptedFiles) => {
    encodeImageFileAsURL(acceptedFiles)
  }, [])
  const encodeImageFileAsURL = (files) => {
    var file = files[0]
    setUploadImage(file)
    var reader = new FileReader()
    reader.onloadend = function () {
      if (reader.result) {
        setUploadImagePreview(reader.result)
      }
    }
    reader.readAsDataURL(file)
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop,
  })

  const submit = (e) => {
    e.preventDefault()
    let temp = { ...section1 };
    if (section1.title.value == '') {
      temp.title.error = 'Please enter title!'
      setSection1(temp)
      return
    } else {
      temp.title.error = ''
      setSection1(temp)
    }
    if (section1.description1.value == '') {
      temp.description1.error = 'Please enter description1!'
      setSection1(temp)
      return
    } else {
      temp.description1.error = ''
      setSection1(temp)
    }
    if (section1.description2.value == '') {
      temp.description2.error = 'Please enter description2'
      setSection1(temp)
      return
    } else {
      temp.description2.error = ''
      setSection1(temp)
    }
    if (section1.buttonTitle.value == '') {
      temp.buttonTitle.error = 'Please enter button title.'
      setSection1(temp)
      return
    } else {
      temp.buttonTitle.error = ''
      setSection1(temp)
    }
    if (section1.buttonLink.value == '') {
      temp.buttonLink.error = 'Please enter button link.'
      setSection1(temp)
      return
    } else {
      temp.buttonLink.error = ''
      setSection1(temp)
    }
    debugger
    if (uploadImage) {
      imageUpload(uploadImage).then(upload => {
        temp.image.value = upload.data.path;
        console.log(upload);
        addContent({ pageName: pageName, section: "Section_1", content: temp })
          .then(result => {
            if (result.data.status) {
              alert("Content Saved")
            }
          })
      })

    } else {
      addContent({ pageName: pageName, section: "Section_1", content: temp })
        .then(result => {
          if (result.data.status) {
            alert("Content Saved")
          }
        })
        .catch(error => {
          console.error(error);
        })
    }
    setSection1(temp)
  }
  return (
    <Modal show={showModal}>
      <form >
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Section 1</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => setShowModal(false)}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section1?.title.value}
                    onChange={(e) => {
                      let temp = { ...section1 }
                      temp.title.value = e.target.value
                      setSection1(temp)
                      setTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={section1?.title.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Sub Title:'
                    style={{ width: '80%' }}
                    type={'textarea'}
                    required={true}
                    value={section1?.description1.value}
                    onChange={(e) => {
                      let temp = { ...section1 }
                      temp.description1.value = e.target.value
                      setSection1(temp)
                      setDescription1({ error: '', value: e.target.value })
                    }}
                    errorMessage={section1?.description1.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Description:'
                    style={{ width: '80%' }}
                    type={'textarea'}
                    required={true}
                    value={section1?.description2.value}
                    onChange={(e) => {
                      let temp = { ...section1 }
                      temp.description2.value = e.target.value
                      setSection1(temp)
                      setDescription2({ error: '', value: e.target.value })
                    }}
                    errorMessage={section1?.description2.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Button Title:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section1?.buttonTitle.value}
                    onChange={(e) => {
                      let temp = { ...section1 }
                      temp.buttonTitle.value = e.target.value
                      setSection1(temp)
                      setButtonTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={section1?.buttonTitle.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Button Link:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section1?.buttonLink.value}
                    onChange={(e) => {
                      let temp = { ...section1 }
                      temp.buttonLink.value = e.target.value
                      setSection1(temp)
                      setButtonLink({ error: '', value: e.target.value })
                    }}
                    errorMessage={section1?.buttonLink.error}
                  />
                </div>

              </div>
              <div className='modal_inner_content--right'>
                <label>About background Image</label>
                <div className='upload_border' {...getRootProps()}>
                  <input {...getInputProps()} />
                  <label>Click or Drag a image to this area to upload</label>
                  <svg className='modal_upload__icon-dark'>
                    <use xlinkHref='/sprite.svg#icon-cloud' />
                  </svg>
                </div>
                <div className='upload_preview_modal'>
                  <img style={{ backgroundColor: "#f2f2f2" }} height={140} src={uploadImagePreview ? uploadImagePreview : section1?.image.value}></img>
                </div>
              </div>
            </div>
          </div>

          <div className='modal_dark_buttons'>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={submit}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditInfo
